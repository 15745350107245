import React, { useState } from 'react'
import { Box, Flex, Grid, Image, Text, Tooltip, useToast } from '@chakra-ui/react'
import moment from 'moment'
import { buildIpfsGateway, getInitials, trimAddress } from '../../../../utils/parser'
import { Link } from 'react-router-dom'
import { IProfileBatch } from '../../../../provider/Profile/profileProvider'
import { UserPopup } from '../../../shared/popups/UserPopup'
import Delayed from '../../../shared/actions/Delayed'
import { copyToClipboard } from '../../../../utils/os'
import isEqual from 'lodash/isEqual'
import { ShortText } from '../../../shared/typography/ShortText'
import { RolesWithIndicator } from '../../../shared/roles/RolesWithIndicator'

interface IProps {
  data: IProfileBatch
}

export const LastProfile = ({ data }: IProps) => {
  const [showUserPopup, setShowUserPopup] = useState<`0x${string}`[] | undefined>(undefined)

  const toast = useToast()

  const handleOnHover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(address)
    }, 100)
  }

  const handleOnUnhover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(undefined)
    }, 100)
  }

  const handleCopyLink = async (address: address) => {
    await copyToClipboard(`${document.location.host}/profile/${address}`)
    toast({ title: `Copied!` })
  }

  return (
    <Flex
      justifyContent="flex-start"
      flexDirection="column"
      gridGap="24px"
      w="100%"
    >
      {data.map((datum, index) => {
        if (index < 5) {
          return (
            <Flex
              key={`${datum.address_?.[0]}_${index}`}
              // gridGap="8px"
              flexDirection="row"
              onMouseEnter={() => handleOnHover(datum.address_)}
              onMouseLeave={() => handleOnUnhover(datum.address_)}
              position="relative"
              w="100%"
            >
              <Link to={`/profile/${datum.address_?.[0]}`}>
                <Box
                  display={{ md: 'none', base: 'flex' }}
                  w="40px"
                  h="40px"
                  flexShrink={0}
                  minW="40px"
                  borderRadius="50%"
                  bgColor="bluePlaceholder"
                  alignItems="center"
                  justifyContent="center"
                  backgroundImage={buildIpfsGateway(datum.avatar)}
                  backgroundPosition="center center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                >
                  {!datum.avatar && <Text color="_textQuaternary">{getInitials(datum.name)}</Text>}
                </Box>
              </Link>
              <Flex
                marginLeft={{ md: '0px', base: '8px' }}
                flexDirection={{ md: 'row', base: 'column' }}
                width={{ md: '100%', base: 'calc(100% - 44px)' }}
              >
                <Link to={`/profile/${datum.address_?.[0]}`}>
                  <Flex
                    gap="8px"
                    width="220px"
                  >
                    <Box
                      display={{ md: 'flex', base: 'none' }}
                      w="40px"
                      h="40px"
                      flexShrink={0}
                      minW="40px"
                      borderRadius="50%"
                      bgColor="bluePlaceholder"
                      alignItems="center"
                      justifyContent="center"
                      backgroundImage={buildIpfsGateway(datum.avatar)}
                      backgroundPosition="center center"
                      backgroundRepeat="no-repeat"
                      backgroundSize="cover"
                    >
                      {(!datum.avatar || datum.avatar.length < 35) && (
                        <Text
                          color="_textQuaternary"
                          textTransform="uppercase"
                        >
                          {getInitials(datum.name)}
                        </Text>
                      )}
                    </Box>
                    <Flex flexDirection="column">
                      <Flex>
                        <ShortText>
                          <Text
                            color={datum.name ? 'textPrimary' : 'textTertiary'}
                            fontSize="14px"
                          >
                            {datum.name || 'Bez nazwy'}
                          </Text>
                        </ShortText>
                        <Box
                          bgColor="textSecondary"
                          borderRadius="50%"
                          m="0px 8px"
                          marginTop="9px"
                          w="3px"
                          h="3px"
                          display={{ md: 'none', base: 'block' }}
                        />
                        <Text
                          whiteSpace="nowrap"
                          fontSize="10px"
                          color="textSecondary"
                          pt="3px"
                          display={{ md: 'none', base: 'inline' }}
                        >
                          {moment(datum._created_at).fromNow()}
                        </Text>
                      </Flex>
                      <Text
                        display={{ md: 'none', base: 'inline-block' }}
                        color="textSecondary"
                        fontSize="10px"
                        w={{ base: '100%', md: '90%' }}
                        py="4px"
                      >
                        {datum.description}
                      </Text>
                      <Flex
                        gridGap="4px"
                        color="textSecondary"
                        alignItems="center"
                        fontSize="12px"
                        display={{ md: 'flex', base: 'none' }}
                      >
                        {datum.nickname && (
                          <>
                            <ShortText>{datum.nickname}</ShortText>
                            <Box
                              bgColor="textSecondary"
                              borderRadius="50%"
                              w="2px"
                              h="2px"
                            />
                          </>
                        )}
                        <Text
                          whiteSpace="nowrap"
                          display={{ base: 'none', md: 'inline' }}
                        >
                          {moment(datum._created_at).fromNow()}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Link>

                <Grid
                  templateColumns="auto 1fr"
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                >
                  <Flex
                    sx={{
                      pl: {
                        base: 'none',
                        md: '16px',
                      },
                      gridGap: '4px',
                      flexWrap: 'nowrap',
                      overflow: 'auto',
                    }}
                  >
                    <RolesWithIndicator
                      roles={datum.roles}
                      compact
                    />
                    {isEqual(showUserPopup, datum.address_) && (
                      <Delayed>
                        <UserPopup
                          address={datum.address_?.[0]}
                          /* @ts-ignore */
                          customData={datum.artificial && datum}
                        />
                      </Delayed>
                    )}
                  </Flex>

                  <Flex
                    pl={{ base: 'none', md: '16px' }}
                    pt={{ base: '8px', md: '0px' }}
                    ml="auto"
                  >
                    <Tooltip
                      hasArrow
                      label="Copy profile link"
                    >
                      <Box
                        cursor="pointer"
                        onClick={() => handleCopyLink(datum.address_?.[0]!)}
                        borderRadius="8px"
                        h="36px"
                        border="1px solid"
                        borderColor="borderPrimary"
                        display="flex"
                        gridGap="8px"
                        p="6px 8px"
                        justifyContent="center"
                        alignItems="center"
                        width="150px"
                      >
                        <Text
                          whiteSpace="nowrap"
                          fontSize="14px"
                          color="_accentPrimary"
                        >
                          {trimAddress(datum.address_?.[0])}
                        </Text>
                        <Box
                          display="flex"
                          borderRadius="4px"
                          h="20px"
                          w="20px"
                          bgColor="borderPrimary"
                          p="3px"
                        >
                          <Image src="/assets/icons/link-icon.svg" />
                        </Box>
                      </Box>
                    </Tooltip>
                  </Flex>
                </Grid>
              </Flex>
            </Flex>
          )
        }
      })}
    </Flex>
  )
}

export default LastProfile
