import { Box, Flex, Image, Input, InputGroup, InputRightElement, Select as ChakraSelect, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { loaded } from '../../../../../../utils/process'
import SpinnerText from '../../../../tags/SpinnerText'
import { fetchBalance } from '@wagmi/core'
import { Token } from './QuestModal'
import { Address } from '../../../../../../contracts/address'

export const Reward = ({
  address,
  amount,
  setTokens,
  index,
  tokens,
  isClosable = true,
}: {
  address: string
  amount: number
  setTokens: any
  index: number
  tokens: Token[]
  isClosable?: boolean
}) => {
  const [addressBalance, setAddressBalance] = useState('')
  const [addressSymbol, setAddressSymbol] = useState('')
  const [imageUrl, setImageUrl] = useState('/assets/elements/eth.svg')
  const [isBalanceLoading, setIsBalanceLoading] = useState(true)
  const account = useAccount()

  const getTokenBalance = async () => {
    await loaded(async () => {
      const balance = await fetchBalance({
        address: account.address as `0x${string}`,
        token: address as `0x${string}`,
      })
      setAddressBalance(balance?.formatted)
      setAddressSymbol(balance?.symbol)
    }, setIsBalanceLoading)
  }

  useEffect(() => {
    if (address.length === 42 && address.slice(0, 2) == '0x') {
      getTokenBalance()
    } else if (addressBalance.length > 0) {
      setAddressBalance('')
      setAddressSymbol('')
    }
  }, [address])

  useEffect(() => {
    switch (addressSymbol.toLowerCase()) {
      case 'busd':
        setImageUrl('/assets/elements/busd.svg')
        break
      case 'usdt':
        setImageUrl('/assets/elements/usdt.svg')
        break
      case 'usdc':
        setImageUrl('/assets/elements/usdc.svg')
        break
      case 'cdo':
        setImageUrl('/assets/icons/degen-logo.svg')
        break
      case 'custom':
        setImageUrl('/assets/elements/eth.svg')
        break
    }
  }, [])

  const handleChangeToken = (name: string) => {
    switch (name.toLowerCase()) {
      case 'busd':
        setImageUrl('/assets/elements/busd.svg')
        setTokens(prev => [
          ...prev.slice(0, index),
          { address: Address.BUSD, amount: tokens[index]?.amount },
          ...prev.slice(index + 1),
        ])
        break
      case 'usdt':
        setImageUrl('/assets/elements/usdt.svg')
        setTokens(prev => [
          ...prev.slice(0, index),
          { address: Address.USDT, amount: tokens[index]?.amount },
          ...prev.slice(index + 1),
        ])
        break
      case 'usdc':
        setImageUrl('/assets/elements/usdc.svg')
        setTokens(prev => [
          ...prev.slice(0, index),
          { address: Address.USDC, amount: tokens[index]?.amount },
          ...prev.slice(index + 1),
        ])
        break
      case 'cdo':
        setImageUrl('/assets/icons/degen-logo.svg')
        setTokens(prev => [
          ...prev.slice(0, index),
          { address: Address.W_CDO, amount: tokens[index]?.amount },
          ...prev.slice(index + 1),
        ])
        break
      default:
        setImageUrl('/assets/elements/eth.svg')
        setTokens(prev => [...prev.slice(0, index), { address: '', amount: 0 }, ...prev.slice(index + 1)])
    }
  }

  return (
    <Flex
      gap="4px"
      alignItems="center"
      justifyContent="space-between"
      color="textQuaternary"
      position="relative"
    >
      <Input
        textIndent="32px"
        borderRadius="8px"
        border="1px solid"
        borderColor="_borderPrimary"
        px="12px"
        py="10px"
        fontSize="14px"
        placeholder="address"
        width="200%"
        value={address}
        isDisabled={addressSymbol.toLowerCase() !== 'custom' && !!addressSymbol}
        onChange={(e: any) => {
          setTokens(prev => [
            ...prev.slice(0, index),
            { address: e.target.value, amount: tokens[index]?.amount },
            ...prev.slice(index + 1),
          ])
        }}
      />
      <Flex sx={{ minW: '32px', position: 'absolute', justifyContent: 'center', alignItems: 'center', px: '12px' }}>
        <Image
          src={addressSymbol === 'custom' ? '/assets/elements/eth.svg' : imageUrl}
          boxSize="22px"
        />
      </Flex>
      <Box sx={{ minW: '32px', position: 'absolute', zIndex: '1' }}>
        <ChakraSelect
          fontSize="14px"
          _focus={{ outline: 'none' }}
          sx={{
            outline: 'none !important',
            padding: '0',
            w: '46px',
            '+.chakra-select__icon-wrapper': {
              display: 'none',
            },
            '&:first-of-type': {
              fontSize: 0,
            },
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
          onChange={e => handleChangeToken(e.target.value as string)}
        >
          <option value="custom">Custom</option>
          <option value="BUSD">BUSD</option>
          <option value="USDT">USDT</option>
          <option value="USDC">USDC</option>
          <option value="CDO">DHC</option>
        </ChakraSelect>
      </Box>
      <InputGroup width="70%">
        <Input
          isInvalid={amount > Number(addressBalance)}
          borderRadius="8px"
          border="1px solid"
          borderColor="_borderPrimary"
          px="12px"
          py="10px"
          display="flex"
          alignItems="center"
          fontSize="14px"
          justifyContent="space-between"
          placeholder="amount"
          type="number"
          value={amount}
          onChange={(e: any) => {
            setTokens(prev => [
              ...prev.slice(0, index),
              { address: tokens[index]?.address, amount: e.target.value },
              ...prev.slice(index + 1),
            ])
          }}
        />
        <InputRightElement>
          <Text
            color="_accentPrimary"
            fontSize="12px"
            cursor="pointer"
            onClick={() => {
              setTokens(prev => [
                ...prev.slice(0, index),
                { address: tokens[index]?.address, amount: addressBalance },
                ...prev.slice(index + 1),
              ])
            }}
          >
            Max
          </Text>
        </InputRightElement>
      </InputGroup>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="10px"
        minW="16px"
        ml="6px"
        width="70%"
      >
        {!!addressBalance && (
          <Box>
            <Text
              color="#6F6A4F"
              fontSize="10px"
            >
              Balance
            </Text>
            <Flex
              color="textQuaternary"
              fontSize="12px"
            >
              {isBalanceLoading ? (
                <SpinnerText />
              ) : (
                <Text
                  sx={{
                    maxWidth: '8ch',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {Number(Number(addressBalance).toFixed(4)) * 1}
                </Text>
              )}
              &nbsp;
              {addressSymbol}
            </Flex>
          </Box>
        )}
        {isClosable && (
          <Image
            src="/assets/icons/close-dark.svg"
            width="16px"
            height="16px"
            cursor="pointer"
            onClick={() => {
              setTokens(prev => [...prev.slice(0, index), ...prev.slice(index + 1)])
            }}
          />
        )}
      </Box>
    </Flex>
  )
}
