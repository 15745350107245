import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorMode,
  UseDisclosureReturn,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { useGroupProvider } from '../../../../provider/Group/groupProvider'
import { copyToClipboard } from '../../../../utils/os'
import QRCodeStyling from 'qr-code-styling'
import { Colors } from '../../../../theme/colors'

export const CodeView = ({
  disclosure,
  parentDisclosure,
}: {
  disclosure: UseDisclosureReturn
  parentDisclosure: UseDisclosureReturn
}) => {
  const group = useGroupProvider()
  const toast = useToast()
  const { colorMode } = useColorMode()

  const ref = useRef<HTMLDivElement>(null)

  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    type: 'svg',
    data: group.shortLink
      ? `${window.location.origin}/l/${group.shortLink}`
      : `${window.location.origin}/group/${group.address}`,
    margin: 0,
    qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'L' },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: 'classy', color: Colors.textQuaternary[colorMode === 'dark' ? '_dark' : 'default'] },
    backgroundOptions: { color: Colors.backgroundMain[colorMode === 'dark' ? '_dark' : 'default'] },
    cornersSquareOptions: { color: Colors.textQuaternary[colorMode === 'dark' ? '_dark' : 'default'] },
    cornersDotOptions: { color: Colors.bluePrimary[colorMode === 'dark' ? '_dark' : 'default'] },
  })

  useEffect(() => {
    setTimeout(() => {
      qrCode.append(ref!.current as any)
    }, 100)
  }, [disclosure.isOpen])

  const handleCopyCode = async () => {
    await copyToClipboard(`${window.location.origin}/group/${group.address}`)
    toast({ status: 'info', title: 'Copied' })
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={() => {
        parentDisclosure.onOpen()
        disclosure.onClose()
      }}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader
          color="_accentSecondary"
          fontSize="18px"
          fontWeight="600"
        >
          Group QR code
        </ModalHeader>
        <Divider />
        <ModalCloseButton mt="8px" />
        <ModalBody
          display="flex"
          flexDir="column"
          alignItems="center"
          p="24px"
          gap="24px"
        >
          <Box ref={ref}></Box>
        </ModalBody>
        <Divider />
        <ModalFooter
          display="flex"
          alignItems="center"
          gap="8px"
        >
          <Button
            onClick={handleCopyCode}
            h="32px"
            fontSize="12px"
          >
            Copy code
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
