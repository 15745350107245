import { Input, Image, Flex } from '@chakra-ui/react'
import { createRef, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useChatProvider } from '../../../../provider/Chat/chatProvider'
import { useNavigate } from 'react-router'
import { base } from 'viem/chains'

export const Search = () => {
  const [search_, setSearch] = useState('')
  const search = useDebounce(search_, 500)

  const inputReference = createRef<HTMLInputElement>()
  const navigate = useNavigate()

  /**
   * TODO:
   * 1. Make thread
   * 2. Open that thread just made
   */
  const handleSearch = useCallback(() => {
    /* TODO: think this logic through - should this redirect be done here or in (through) the provider? */
    navigate(`/chat${search_ ? `/${search_}` : ''}`)
  }, [search_])

  const handleFocusInput = () => {
    inputReference.current?.focus()
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        handleSearch()
      }}
    >
      <Flex
        sx={{
          borderRadius: '16px',
          border: '1px solid',
          borderColor: '_borderPrimary',
          px: '16px',
          py: '7px',
        }}
        onClick={handleFocusInput}
        tabIndex={0}
        cursor="text"
      >
        <Image
          src="/assets/icons/zoom.svg"
          sx={{
            w: '14px',
          }}
        />
        <Input
          ref={inputReference}
          _placeholder={{ color: 'textSecondary' }}
          value={search_}
          onChange={event => setSearch(event.target.value)}
          placeholder="Szukaj…"
          _focus={{ outline: 'none !important', boxShadow: 'none !important', border: 'none !important' }}
          sx={{
            fontSize: '14px',
            border: 'none',
          }}
        />
      </Flex>
    </form>
  )
}
