import { Box, Flex, Image, Text } from '@chakra-ui/react'

export const RoleBadge = ({ value, removeEl }: { value: string; removeEl: () => void }) => {
  return (
    <Flex
      bgColor="_backgroundTertiary"
      p="4px 8px"
      borderRadius="4px"
      align="center"
      gap="8px"
      fontSize="12px"
      color="textQuaternary"
      zIndex="2"
    >
      <Text>{value}</Text>
      <Box onClick={removeEl}>
        <Image
          src="/assets/icons/close-dark.svg"
          cursor="pointer"
        />
      </Box>
    </Flex>
  )
}
