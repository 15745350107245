import React, { useRef } from 'react'
import { Flex, Image, Input } from '@chakra-ui/react'
import TopPeople from './TopPeople/topPeople'
import { Advert } from './Advert'

export const RightPanel = () => {
  return (
    <Flex
      flexDirection="column"
      gridGap="16px"
      position="sticky"
      top="0"
      sx={{
        flexGrow: 1
      }}
    >
      <Advert />
      <TopPeople />
      <Flex w={{ base: '100%', lg: '300px' }}></Flex>
    </Flex>
  )
}

export default RightPanel
