import { Box, Button, Flex, Image, Text, keyframes, useColorMode } from '@chakra-ui/react'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'
import useAsyncEffect from '../../../../hooks/effects/async'
import { IGroup } from '../../../../hooks/useGroup'
import { Views } from '../../../../pages/Home'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { useChatProvider } from '../../../../provider/Chat/chatProvider'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { useSearchProvider } from '../../../../provider/Search'
import { getColor } from '../../../../theme/global'
import { AddressZero } from '../../../../utils/ethereum'
import { fetchApi } from '../../../../utils/fetcher'
import { loaded } from '../../../../utils/process'

interface ILeftPanel {
  setView?: Dispatch<SetStateAction<Views>>
}

export const liveChatAnimation = keyframes`
  0% {
    box-shadow: ${getColor('#5cf5cb')} 0px 0px 0px 0px;
  }
  70% {
    box-shadow: rgba(52, 199, 123, 0) 0px 0px 0px 7px;
  }
  100% {
    box-shadow: rgba(52, 199, 123, 0) 0px 0px 0px 0px;
  }
`

export const LeftPanel: FC<ILeftPanel> = ({ setView }) => {
  const user = useAccountProfileProvider()
  const wallet = useEthereumProvider()
  const { openThread } = useChatProvider()
  const search = useSearchProvider()

  const { pathname } = window.location

  const [groups, setGroups] = useState<IGroup[]>([])
  const [areGroupsLoading, setAreGroupsLoading] = useState(false)

  // TODO: Keep this data in a provider not to refresh this every page change.
  useAsyncEffect(
    async () =>
      await loaded(async () => {
        if (wallet.account) {
          // TODO: This should be returned with profile data (via AccountProfileProvider)
          const data = await fetchApi(`profile/${wallet.account}/groups`)
          setGroups(data)
        }
      }, setAreGroupsLoading),
    [wallet.account]
  )

  const { colorMode } = useColorMode()

  return (
    <Flex
      direction="column"
      display={{ base: 'none', lg: 'flex' }}
      gridGap="12px"
      minW="225px"
    >
      {/** todo refactor - this should be mapped */}
      <Link to="/">
        <Flex
          gridGap="16px"
          alignItems="center"
          borderRadius="8px"
          transition="all .15s ease-in-out"
          _hover={{ background: 'borderPrimary' }}
        >
          <Box
            display="flex"
            w="32px"
            h="32px"
            borderRadius="8px"
            border="1px solid"
            borderColor={pathname != '/' ? 'borderPrimary' : 'transparent'}
            justifyContent="center"
            alignItems="center"
            bgColor={pathname == '/' ? 'textQuaternary' : 'backgroundMain'}
          >
            <Image
              src={
                pathname == '/'
                  ? colorMode === 'light'
                    ? '/assets/icons/home-icon.svg'
                    : '/assets/icons/home-icon-dark.svg'
                  : '/assets/icons/home-icon-dark.svg'
              }
            />
          </Box>
          <Text fontWeight={pathname == '/' ? 500 : 400}>Główna</Text>
        </Flex>
      </Link>
      <Button
        onClick={search.openModal}
        variant="unstyled"
        sx={{
          fontSize: '16px',
          _active: { color: '_accentPrimary' },
          _focusVisible: { color: '_accentPrimary' },
        }}
      >
        <Flex
          gridGap="16px"
          alignItems="center"
          borderRadius="8px"
          transition="all .15s ease-in-out"
          _hover={{ background: 'borderPrimary' }}
        >
          <Box
            display="flex"
            w="32px"
            h="32px"
            border="1px solid"
            borderColor="borderPrimary"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            bg="backgroundMain"
          >
            <Image
              src="/assets/icons/zoom.svg"
              transform="scale(.9)"
            />
          </Box>
          <Text>Szukaj</Text>
        </Flex>
      </Button>
      {wallet.isAuthed && (
        <Link to="/chat">
          <Flex
            gridGap="16px"
            alignItems="center"
            borderRadius="8px"
            transition="all .15s ease-in-out"
            _hover={{ background: 'borderPrimary' }}
          >
            <Box
              display="flex"
              w="32px"
              h="32px"
              border="1px solid"
              borderColor="borderPrimary"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              bg="backgroundMain"
              bgColor={pathname.startsWith('/chat') ? 'textQuaternary' : 'backgroundMain'}
              position="relative"
              {...(true && {
                _before: {
                  content: '""',
                  boxSize: '13px',
                  position: 'absolute',
                  borderRadius: 'full',
                  bg: '_foregroundPrimary',
                  top: '-6px',
                  right: '-6px',
                },
                _after: {
                  content: '""',
                  position: 'absolute',
                  boxSize: '13px',
                  borderRadius: 'full',
                  bg: '_successPrimary',
                  top: '-6px',
                  right: '-6px',
                  boxShadow: `0px 0px 6px 5px ${getColor('_successPrimary', 0.2)},
                0px 0px 32px 5px ${getColor('_successPrimary')},
                0px 0px 32px 5px ${getColor('_successPrimary')}`,
                  animation: `${liveChatAnimation} ease-in-out 2.66s infinite`,
                },
              })}
            >
              <Image
                src={
                  !pathname.startsWith('/chat')
                    ? colorMode === 'light'
                      ? '/assets/icons/envelope.svg'
                      : '/assets/icons/envelope-light.svg'
                    : '/assets/icons/envelope.svg'
                }
              />
            </Box>
            <Text fontWeight={pathname.startsWith('/chat') ? 500 : 400}>Degen PUMP</Text>
          </Flex>
        </Link>
      )}
      <Link to={`/profile/${wallet.account ?? AddressZero}/`}>
        <Flex
          gridGap="16px"
          alignItems="center"
          borderRadius="8px"
          transition="all .15s ease-in-out"
          _hover={{ background: 'borderPrimary' }}
        >
          <Box
            display="flex"
            w="32px"
            h="32px"
            border="1px solid"
            borderColor="borderPrimary"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            bg="backgroundMain"
          >
            <Image src="/assets/icons/sidenav-user-icon.svg" />
          </Box>
          <Text>Profil</Text>
        </Flex>
      </Link>
      <Link to={`/dao`}>
        <Flex
          gridGap="16px"
          alignItems="center"
          borderRadius="8px"
          transition="all .15s ease-in-out"
          _hover={{ background: 'borderPrimary' }}
        >
          <Box
            display="flex"
            w="32px"
            h="32px"
            border="1px solid"
            borderColor="borderPrimary"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            bg="backgroundMain"
          >
            <Image src="/assets/icons/home-icon.svg" />
          </Box>
          <Text>DAO</Text>
        </Flex>
      </Link>
    </Flex>
  )
}

export default LeftPanel
