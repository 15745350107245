import { isJwtExpired } from '../../utils/jwt'
import { loaded } from '../../utils/process'
import { fetchApi } from '../../utils/fetcher'
import { getDegenJwtAuthKey, getDegenJwtRefreshKey, getDegenPlatformJwtAuthKey, parseNonce } from '../../utils/auth'
import { EError } from '../../hooks/utils/useEthereum'
import { useSignMessageSafe } from '../../hooks/utils/useSignMessageSafe'
import { useDisconnect } from 'wagmi'

export interface IProps {
  logOut: (account: address) => Promise<void>
  logInDegenPlatform: (account: address) => Promise<address | void>
  // TODO: Is logged in (isConnected && isAuthed); when appAccount is made a provider (in a way that is similar to ethereumProvider).
}

// TODO: Make this a provider (and factor out data from ethereum provider into it).
export const useAppAccount = (): IProps => {
  const safeSigner = useSignMessageSafe()
  const disconnector = useDisconnect()

  const logInDegenPlatform = async (account: address) => {
    // TODO: Re-check
    // TODO: check for this upon app launch; if it expired – log out and log in, if it did not exist – log out and do nothing.
    if (!isJwtExpired(localStorage.getItem(getDegenPlatformJwtAuthKey(account)) || '')) {
      return account
    }

    const nonce = await loaded(
      async () =>
        await fetchApi('auth/nonce', {
          address: account,
        })
    )

    if (!nonce) {
      throw EError.NO_SERVER
    }

    const signature = await safeSigner.signMessageSafe(parseNonce(nonce))

    const jwt = await loaded(
      async () =>
        await fetchApi('auth/login', {
          address: account,
          signature,
        })
    )

    if (!jwt) {
      throw EError.GENERIC
    }

    localStorage.setItem(getDegenPlatformJwtAuthKey(account), jwt ?? '')

    // TODO: deprecate (nothing else can be returned here, so dont return nothigng at all)
    return account
  }

  const logOut = async (account: address) => {
    localStorage.removeItem(getDegenPlatformJwtAuthKey(account))
    localStorage.removeItem(getDegenJwtAuthKey(account))
    localStorage.removeItem(getDegenJwtRefreshKey(account))

    await disconnector.disconnectAsync()
  }

  return { logOut, logInDegenPlatform }
}
