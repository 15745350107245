import { Button, Flex, Link, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

interface IRoleProps {
  inDemand?: boolean
  color: string
  fontColor: string
  name: string
  members: number
}

export const Role: FC<IRoleProps> = ({ inDemand, color, fontColor, name, members }) => {
  return (
    <Flex
      p="16px"
      w="100%"
      justify="space-between"
      align="center"
    >
      <Flex
        align="center"
        gap="16px"
      >
        <Flex
          bgColor={color}
          color={fontColor}
          fontSize="12px"
          boxSize="40px"
          borderRadius="8px"
          align="center"
          justify="center"
        >
          O
        </Flex>
        <Flex
          flexDir="column"
          gap="2px"
        >
          <Text
            fontSize="14px"
            color="textQueternary"
          >
            {name}
          </Text>
          <Text
            fontSize="12px"
            color="textSecondary"
          >
            {members} Members
          </Text>
        </Flex>
      </Flex>
      {inDemand && (
        <Flex
          align="center"
          gap="12px 16px"
        >
          <Link
            fontSize="14px"
            color="_accentPrimary"
          >
            Join AS
          </Link>
          <Button>Invite AS</Button>
        </Flex>
      )}
    </Flex>
  )
}
