import axios, { AxiosRequestConfig } from 'axios'

const api = process.env.REACT_APP_API

const fetcher = (url: string, data?: object, options?: AxiosRequestConfig<object> | undefined) =>
  (!data ? axios.get(`${api}/${url}`, options) : axios.post(`${api}/${url}`, data, options)).then(res => res.data)

const fetcherGet = (url: string, options?: AxiosRequestConfig<object> | undefined) =>
  axios.get(url, options).then(res => res.data)

const fetchApi = (route: string, data?: object | Array<any>, options?: AxiosRequestConfig<object> | undefined) =>
  fetcher(route, data, options)

export { fetcher, fetcherGet, fetchApi }
