import { Thread, User } from './types'

export enum EChatType {
  General,
  Token,
}

export interface IState {
  filter: EChatType
  isLoading: boolean
  threads: Thread[]
  /* TODO: This should be address, not uuid (address of token on Ethereum; if the token is not on Ethereum, use any other address as the ID. This could be multiple addresses in the future too.). */
  selectedThreadId: string | null | address
  selectedOverlayThreadIds: string[]
  messages: any[]
  isConnected: boolean
  isOverlayChatOpened: boolean
  users: Record<string, User>
  isDetailsOpened: boolean
  currentUser: any
  isAtBottom: boolean
  setIsAtBottom: (isAtBottom: boolean) => void
  handleScrollToBottom: () => void
  registerScrollToBottom: (callback: () => void) => void
}

export const emptyState: IState = {
  filter: EChatType.General,
  isLoading: true,
  threads: [],
  isOverlayChatOpened: false,
  selectedOverlayThreadIds: [],
  selectedThreadId: null,
  users: {},
  messages: [],
  isConnected: false,
  isDetailsOpened: false,
  currentUser: null,
  isAtBottom: true,
  setIsAtBottom: () => null,
  handleScrollToBottom: () => null,
  registerScrollToBottom: () => null,
}

export const Reducer = (state: IState, updatedProperties: Partial<IState>) => ({
  ...state,
  ...updatedProperties,
})
