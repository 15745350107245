import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { loaded } from '../utils/process'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'
import { IIdHelper } from '../provider/Profile/profileProvider'
import { useContractsProvider } from '../provider/Contracts/contractsProvider'
import { useAccountProfileProvider } from '../provider/AccountProfile/context'
import { useNavigate } from 'react-router-dom'
import useAsyncEffect from './effects/async'
import { ContractAlert } from '../components/global/wrapper/alerts/ContractAlert'
import { trimAddress } from '../utils/parser'
import { getNetwork } from '@wagmi/core'
import { ENetwork } from '../enum/network.enum'

interface IProps extends IIdHelper {}

/**
 * @param id address friended
 * @constructor
 */
export const useFriend = ({ address }: IProps) => {
  const [isAccountFriended, setIsAccountFriended] = useState<boolean>()
  const [isProcessing, setIsProcessing] = useState(false)
  const [txHash, setTxHash] = useState<txHash>()

  const wallet = useEthereumProvider()
  const user = useAccountProfileProvider()
  const contracts = useContractsProvider()
  const toast = useToast({})
  const history = useNavigate()
  const { chain } = getNetwork()

  const isWrongNetwork = ENetwork.FALLBACK !== chain?.id
  const isSelf = wallet.account?.toLowerCase() === address?.toLowerCase() && !!wallet.account

  const isDisabled = isSelf || !wallet.isLoggedIn || isWrongNetwork

  const handleFriend = async () =>
    loaded(async () => {
      if (isSelf) {
        return
      }

      if (!wallet.account) {
        await wallet.connect()
      }
      const tx = await (isAccountFriended
        ? contracts.friend?.write.unfriend([address?.toLowerCase()])
        : contracts.friend?.write.friend([address?.toLowerCase()]))
      setTxHash(tx)
    }, setIsProcessing)

  /** State init */
  useEffect(() => setIsAccountFriended(user.friends?.includes(address!)), [user.followed, address])

  /** Loading state sync */
  useAsyncEffect(async () => {
    if (!txHash) {
      return
    }

    setIsAccountFriended(prevState => !prevState)
    toast({
      render: () => (
        <ContractAlert
          content={trimAddress(address)!}
          class_="DegenFriend"
          action={{ call: () => history(`/tx/${txHash}`), description: 'See transaction' }}
        />
      ),
    })

    // await loaded(
    //   async () => {

    //     /** Handle success
    //      *  - flip the state
    //      *  - show a toast
    //      */

    //   },
    //   setIsProcessing,
    //   () => toast({ title: `[DegenFriend]: Please, check your wallet connection`, status: 'error' })
    // )
  }, [txHash])

  return { friend: handleFriend, isLoading: isProcessing, isFriended: isAccountFriended, isDisabled }
}
