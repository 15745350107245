import { sample } from 'lodash'
import { useMemo } from 'react'
import { buildIpfsGateway } from '../../../../../../utils/parser'
import { Box, Image, Text } from '@chakra-ui/react'

type GroupIconProps = {
  name: string | undefined
  image: string | undefined
}

export const GroupIcon = ({ name, image }: GroupIconProps) => {
  const randomColor = sample([
    { bg: '#F8E3DA', font: '#85462B' },
    { bg: '#FBDDF3', font: '#8E3374' },
    { bg: '#D3F5F7', font: '#0F5156' },
    { bg: 'bluePlaceholder', font: '_textQuaternary' },
    { bg: '#E7E4F9', font: '#524988' },
    { bg: '#DCF2EA', font: '#317159' },
  ])
  const imageFallback = useMemo(
    () => (
      <Box
        sx={{
          boxSize: '40px',
        }}
        borderRadius="8px"
        background={randomColor.bg}
        display="grid"
        placeItems="center"
      >
        <Text
          color={randomColor.font}
          display="block"
        >
          {name ? name.slice(0, 2).toUpperCase() : 'GR'}
        </Text>
      </Box>
    ),
    [image]
  )

  return image ? (
    <Image
      src={buildIpfsGateway(image)}
      sx={{
        boxSize: '40px',
      }}
      borderRadius="8px"
      objectFit="cover"
      fallback={imageFallback}
    />
  ) : (
    imageFallback
  )
}
