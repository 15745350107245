import { emptyProfile, IIdHelper, IProfile } from '../provider/Profile/profileProvider'
import { Dispatch, SetStateAction, useState } from 'react'
import useAsyncEffect from './effects/async'
import { loaded } from '../utils/process'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'
import { fetchProfile } from '../utils/degen'

export interface IUseProfile extends IProfile {
  isLoading: boolean
  setProfile: Dispatch<SetStateAction<IProfile>>
}

interface IProps extends IIdHelper {}

export const useProfile = ({ address }: IProps): IUseProfile => {
  const [data, setData] = useState<IProfile>(emptyProfile)
  const [isLoading, setIsLoading] = useState(true)

  const wallet = useEthereumProvider()

  /* TODO: Periodically update profile state (e.g. when the blockedUsers
      changes; when the user profile details change because of a blockchain
      event; when their onboarding status changes) */

  useAsyncEffect(async () => {
    if (!address) {
      setData(emptyProfile)

      return
    }

    void loaded(async () => {
      setData({
        ...emptyProfile,
        address: address.toLowerCase() as address,
        address_: [address.toLowerCase() as address],
      })

      const data = await fetchProfile(address, wallet)

      setData(data)
    }, setIsLoading)
  }, [address])

  return { ...data, isLoading, setProfile: setData }
}
