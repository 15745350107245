import {
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useToast,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { GroupCreatorStep, useGroupCreatorProvider } from '../../../../provider/GroupCreator/groupCreatorProvider'
import { loaded } from '../../../../utils/process'
import { useContractsProvider } from '../../../../provider/Contracts/contractsProvider'
import { ContractAlert } from '../../../global/wrapper/alerts/ContractAlert'
import { redirect } from '../../../../utils/redirector'
import { isProduction } from '../../../../utils/isProduction'

export const CreateTokenModal: FC = () => {
  const [isAccepted, setIsAccepted] = useState(false)
  const [isDeploying, setIsDeploying] = useState(false)

  const groupCreator = useGroupCreatorProvider()
  const contracts = useContractsProvider()
  const toast = useToast()

  const handleImportToken = () => groupCreator.setStep(GroupCreatorStep.ImportToken)

  /**
   * TODO:
   *  update states via returned states: {deployer: deploy, state}
   *  const result = await groupCreator.deployToken()
   */
  const handleDeploy = async () =>
    await loaded(async () => {
      const hash = await contracts.groupsDeployer?.write.deploy([
        groupCreator.data.metadata?.name || '',
        groupCreator.data.symbol || '',
      ])

      groupCreator.onClose()
      toast({
        render: () => (
          <ContractAlert
            content={`Token ${groupCreator.data.symbol} deployed`}
            class_="DegenGroups"
            action={{
              call: () => redirect(`https://${isProduction() ? '' : 'testnet.'}bscscan.com/tx/${hash}`),
              description: 'See transaction',
            }}
          />
        ),
      })

      // fetch the deployed token address here – this would be an opposite of pooling.

      // _log('fetching user groups')
      // // const deployments = await contracts.groupsDeployer?.deployments(['0x83f668DD7137aE3ABE8f5d5f5bd6534Be9Bf657d', 0])
      // const deployments = (await readContract({
      //   abi: Abi.ERC20,
      //   address: Address.GROUPS_DEPLOYER,
      //   functionName: 'deployments',
      //   args: ['0x83f668DD7137aE3ABE8f5d5f5bd6534Be9Bf657d', 0],
      // })) as string
      // _log('having', deployments)
    }, setIsDeploying)

  return (
    <Modal
      onClose={groupCreator.onClose}
      isOpen={groupCreator.isOpen}
      size="xl"
      isCentered
    >
      <ModalContent>
        <ModalHeader
          fontSize="18px"
          borderBottom="1px solid borderPrimary"
        >
          Create token
        </ModalHeader>
        <ModalBody
          p="0"
          m="0"
        >
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="flex-start"
            gap="24px"
            p="24px"
          >
            <Text
              color="_accentSecondary"
              fontSize="14px"
            >
              Create your group’s token for community influence and economy. Set it up with a name or import an existing
              token,{' '}
              <Text
                as="span"
                color="_accentPrimary"
              >
                learn more.
              </Text>
            </Text>
            <Flex
              direction="column"
              gap="8px"
              width="100%"
            >
              <Text
                color="_accentSecondary"
                fontWeight="500"
                fontSize="14px"
              >
                Symbol
              </Text>
              <Input
                placeholder="Your token symbol"
                value={groupCreator.data.symbol || ''}
                onChange={event => groupCreator.dispatch({ symbol: event.target.value })}
              />
            </Flex>
            <Flex
              tabIndex={0}
              gap="8px"
              sx={{ cursor: 'pointer', userSelect: 'none' }}
              onClick={() => setIsAccepted(prev => !prev)}
            >
              <Checkbox
                iconColor="textQuaternary"
                isChecked={isAccepted}
                pointerEvents="none"
                tabIndex={-1}
              />
              <Text
                fontSize="14px"
                color="textSecondary"
              >
                I've read and accept the{' '}
                <Text
                  color="_accentPrimary"
                  as="span"
                >
                  Terms of conditions
                </Text>{' '}
                and{' '}
                <Text
                  color="_accentPrimary"
                  as="span"
                >
                  Privacy policy
                </Text>
              </Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderTop="1px solid borderPrimary"
        >
          <Text
            fontWeight="500"
            fontSize="14px"
            color="_accentPrimary"
            cursor="pointer"
            onClick={handleImportToken}
          >
            Import token
          </Text>
          <Flex gap="8px">
            <Button onClick={() => groupCreator.setStep(GroupCreatorStep.Welcome)}>Back</Button>
            <Button
              variant="dark"
              isDisabled={!isAccepted}
              /* FIXME: Bonding curve [done] -> deploy token -> create group from it */
              onClick={handleDeploy}
              isLoading={isDeploying}
            >
              Create
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
