import { Dispatch, SetStateAction } from 'react'
import { zeroAddress } from 'viem'

export interface IUseGroup extends IGroup {
  isLoading: boolean
  setGroup: Dispatch<SetStateAction<IGroup>>
}

interface IProps {
  address: address
}

export interface IGroup {
  _created_at?: Date
  address: address

  /* shortened group-profile URI, e.g.:
    `group/0x64544969ed7ebf5f083679233325356ebe738930` */
  shortLink?: string

  symbol?: string
  owner?: address

  metadata: Partial<{
    name: string
    description: string
    image: string
    background: string
  }>

  members?: {
    address: address
    roles: string[]
  }[]

  details?: {
    /** approximate value, no need for BigInt */
    tvl: number
    /** approximate value, no need for BigInt */
    marketCap: number
    volume: number
    supply: number
    type: 'BEP20' | 'ERC20'
    price: string
  }

  feeds?: string[]
}

export const emptyGroup: IGroup = {
  address: zeroAddress,
  metadata: {},
}

/**
 * Group data structure
 * 0 - name
 * 1 - description
 * 2 - image
 *
 */
// FIXME:
// export const useGroup = ({ address }: IProps): IUseGroup => {
//   return { isLoading: false, setGroup: () => {} }
// }
