import { useLocation } from 'react-router'
import useIsMobile from '../../../hooks/chakra/useIsMobile'
import CryptoTopbar from '../crypto-topbar/CryptoTopbar'
import { BottomNavigation } from '../bottom-navigation/bottomNavigation'
import { Navbar } from '../navbar/navbar'

export const UpperBar = () => {
  const isMobile = useIsMobile()
  const location = useLocation()

  return (
    <>
      {!isMobile && <CryptoTopbar />}
      <Navbar />
      {isMobile && !location.pathname.startsWith('/chat') && <CryptoTopbar />}
      <BottomNavigation />
    </>
  )
}
