import { useEffect, useState } from 'react'
import { Button, Flex, Image, Spinner, Text, useBoolean } from '@chakra-ui/react'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import { QuickPost } from '../../../../../shared/actions/QuickPost/QuickPost'
import useAsyncEffect from '../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../utils/process'
import { fetchApi } from '../../../../../../utils/fetcher'
import { useProfileProvider } from '../../../../../../provider/Profile/profileProvider'
import { Post } from '../../../../Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import { Tab, tabs } from '../../../../Home/MiddlePanel/roles/tabs/discussions/Groups'
import { IPostSchema } from '../../../../Home/MiddlePanel/deafult/Default'
import { useEthereumProvider } from '../../../../../../provider/Ethereum/ethereumProvider'
import { usePagination } from '@ajna/pagination'
import { UserWallLayout } from '../UserWallLayout'
import { UserWallPagination } from '../UserWallPagination'

export const UserPosts = () => {
  const [count, setCount] = useState()
  const [state, setState] = useState(Tab.ALL)
  const [posts, setPosts] = useState<IPostSchema[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [contentFiltered, setContentFiltered] = useState<string | null>(null)
  const [timeFiltered, setTimeFiltered] = useState<number | null>(null)
  const [followingFiltered, setFollowingFiltered] = useState(false)
  const [targetFiltered, setTargetFiltered] = useState(false)
  const [defaultPosts, setDefaultPosts] = useState<IPostSchema[]>([])
  const [contentType, setContentType] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: totalPages * 10,
      limits: {
        outer: 2,
        inner: 2,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })

  const profile = useProfileProvider()
  const wallet = useEthereumProvider()
  const isOwnProfile =
    profile.address && wallet.account && profile.address.toLowerCase() === wallet.account.toLowerCase()

  const [isNewestFirst, setIsNewestFirst] = useBoolean(true)

  const insertPost = (post: IPostSchema) => setPosts(prev => [post, ...prev])

  const filterBy = async (contentType: string, hours?: number | null) => {
    setContentType(contentType)
    if (!profile.isLoading) {
      await loaded(async () => {
        const res = await fetchApi(
          'post/getFiltered',
          hours
            ? {
                timeLimit: hours,
                content: contentType,
                page: currentPage,
                user: profile.address,
                order: isNewestFirst ? 'desc' : 'asc',
              }
            : targetFiltered
            ? {
                target: { roles: profile.roles, interests: profile.interests },
                content: contentType,
                page: currentPage,
                user: profile.address,
                order: isNewestFirst ? 'desc' : 'asc',
              }
            : {
                content: contentType,
                page: currentPage,
                user: profile.address,
                order: isNewestFirst ? 'desc' : 'asc',
              }
        )
        setTotalPages(res.totalPages)
        setPosts(res.posts)
      })
    }
  }

  useAsyncEffect(async () => {
    if (!profile.isLoading) {
      await loaded(async () => {
        await filterBy('')
      })
    }
  }, [profile.isLoading])

  const handleContentFilter = async (tabId: Tab) => {
    setState(tabId)
    if (tabId === Tab.ALL) {
      setContentFiltered(null)
      filterBy('')
    }
    if (tabId === Tab.TEXT) {
      setContentFiltered('TEXT')
      timeFiltered !== null ? filterBy('text', timeFiltered) : filterBy('text')
    }
    if (tabId === Tab.IMAGES) {
      setContentFiltered('IMAGES')
      timeFiltered !== null ? filterBy('images', timeFiltered) : filterBy('images')
    }
    if (tabId === Tab.VIDEOS) {
      setContentFiltered('VIDEOS')
      timeFiltered !== null ? filterBy('videos', timeFiltered) : filterBy('videos')
    }
  }

  const filterByTarget = async () => {
    setTimeFiltered(null)
    setFollowingFiltered(false)

    await loaded(async () => {
      const res = await fetchApi(
        'post/getFiltered',
        contentFiltered
          ? {
              target: { roles: profile.roles, interests: profile.interests },
              content: contentFiltered,
              page: currentPage,
              user: profile.address,
              order: isNewestFirst ? 'desc' : 'asc',
            }
          : {
              target: { roles: profile.roles, interests: profile.interests },
              page: currentPage,
              user: profile.address,
              order: isNewestFirst ? 'desc' : 'asc',
            }
      )
      setTotalPages(res.totalPages)
      setPosts(res.posts)
    })
  }

  useAsyncEffect(async () => {
    await handleContentFilter(state)
  }, [isNewestFirst])

  useEffect(() => {
    if (targetFiltered) {
      filterByTarget()
    }
  }, [targetFiltered])

  useEffect(() => {
    if (contentFiltered === null) {
      timeFiltered !== null ? filterBy('', timeFiltered) : filterBy('')
    }
  }, [contentFiltered])

  useEffect(() => {
    if (followingFiltered) {
      const filteredPosts = posts
        ?.map((post: any) => {
          if (profile.followed.includes(post.author)) return post
        })
        .filter((el: any) => el !== undefined)
      setPosts(filteredPosts)
    }
  }, [followingFiltered])

  useAsyncEffect(async () => {
    if (!profile.address) {
      setIsLoading(false)
      return
    }

    await loaded(async () =>
      setCount(
        await fetchApi('post/counts/total', {
          author: { $regex: profile.address, $options: 'i' },
        })
      )
    )
  }, [profile.address])

  useAsyncEffect(async () => {
    await loaded(async () => {
      filterBy('')
    }, setIsLoading)
  }, [profile.address])

  useAsyncEffect(async () => {
    await loaded(async () => {
      filterBy(contentType, timeFiltered)
    }, setIsLoading)
  }, [currentPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <>
      <UserWallLayout
        heading="Posts"
        headingDetails={`${isLoading ? <SpinnerText /> : count || 0} Posts`}
      >
        <Flex
          flexDir="column"
          gap="16px"
          padding={{ base: '0', md: '16px' }}
          px={{ base: '0', md: '16px' }}
        >
          {isOwnProfile && <QuickPost resultHandle={insertPost} />}
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            gap="14px"
            flexWrap={{ base: 'wrap', xl: 'unset' }}
            px={{ base: '16px', md: 'unset' }}
          >
            <Flex
              align="center"
              gap="8px"
            >
              <Button
                borderRadius="8px"
                border="1px solid"
                borderColor="borderPrimary"
                gridGap="8px"
                p="8px 16px"
                alignItems="center"
                bg="none"
                onClick={setIsNewestFirst.toggle}
              >
                <Image src="/assets/icons/arrows-sort.svg" />
                <Text
                  fontSize="12px"
                  color="textSecondary"
                >
                  {isNewestFirst ? 'Newest first' : 'Oldest first'}
                </Text>
              </Button>
            </Flex>
            <Flex flexWrap="wrap">
              {tabs.map(tab => (
                <Button
                  variant="slave"
                  key={tab.id}
                  borderRadius="8px"
                  p="10px"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    '*': {
                      filter: 'unset',
                    },
                  }}
                  sx={{
                    '*': {
                      filter: tab.id === state ? 'unset' : 'saturate(.15) brightness(1.7)',
                    },
                  }}
                  bg={tab.id === state ? '_accentSecondaryLight' : 'unset'}
                  cursor="pointer"
                  onClick={() => {
                    handleContentFilter(tab.id)
                  }}
                >
                  <Image src={`/assets/icons/${tab.iconId}.svg`} />
                </Button>
              ))}
            </Flex>
          </Flex>
          {isLoading && (
            <Flex
              flexGrow="1"
              justifyContent="center"
            >
              <Spinner color="#6F6A4F" />
            </Flex>
          )}
          {posts?.length ? (
            posts.map(post => (
              <Post
                key={post.ipfs}
                data={post}
              />
            ))
          ) : (
            <Text
              pt="12px"
              fontSize="14px"
              color="textQuaternary"
              textAlign="center"
            >
              This user hasn't posted anything.
            </Text>
          )}
        </Flex>
      </UserWallLayout>
      <UserWallPagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </>
  )
}

export default UserPosts
