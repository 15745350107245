import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { adminRoutes, routes } from '../../navbar/routes'
import { LinkGroup } from './LinkGroup/linkGroup'

export const FooterLinks = () => {
  const accountProfile = useAccountProfileProvider()

  return (
    <>
      <LinkGroup
        title="Mapa"
        items={[...routes, ...(accountProfile.isAdmin ? adminRoutes : [])].filter(
          item => !['/', '/chat'].includes(item.href)
        )}
      />
      <LinkGroup
        title="Dodatkowe"
        items={[
          {
            label: 'Docs',
            href: 'https://docs.degen.pl',
          },
          {
            label: 'Kontakt',
            href: 'mailto:hello@hashup.it',
          },
          {
            label: 'Czat',
            href: '/chat',
          },
        ]}
      />
      <LinkGroup
        title="Community"
        items={[
          {
            label: 'Community',
            href: 'https://hashup.it',
            target: '_blank',
          },
          {
            label: 'Whitepaper',
            href: 'https://docs.degen.pl',
            target: '_blank',
          },
        ]}
      />
      <LinkGroup
        title="Docsy"
        items={[
          {
            label: 'Oprogramowanie',
            href: '/docs/licenses',
          },
        ]}
      />
    </>
  )
}
