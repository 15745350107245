import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select as ChakraSelect,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Select, Type } from '../../../../select/Select'
import { Address } from '../../../../../../contracts/address'
import { includesInsensitive } from '../../../../../../utils/ts'
import { isAddress } from 'viem'
import { IUsePost } from '../../usePost'
import { useEthereumProvider } from '../../../../../../provider/Ethereum/ethereumProvider'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Reward } from './Reward'
import { getColor } from '../../../../../../theme/global'

interface IQuestModalProps {
  isOpen: boolean
  onClose: () => void
  setQuests: any
  onParentOpen: () => void
  post: IUsePost | null
  submit?: (Quest) => void
}

export type Token = {
  address: string
  amount: number | BigInt
}

type EOffer = 'Quest' | 'Task' | 'Job' | 'Transfer'

const formSchema = z.object({
  type: z.enum(['Quest', 'Task', 'Job', 'Transfer']),
  name: z.string().min(1, { message: 'Offer name is required' }),
  winnersAmount: z.string().min(1, { message: 'Quest must have at least one winner' }),
})

type Form = z.infer<typeof formSchema>

export const QuestModal: FC<IQuestModalProps> = ({ isOpen, onClose, setQuests, onParentOpen, post, submit }) => {
  const toast = useToast()
  const wallet = useEthereumProvider()

  const [type, setType] = useState<EOffer>('Quest')

  const [description, setDescription] = useState('')
  const [roles, setRoles] = useState<string[]>(['Account Collector'])
  const [tokens, setTokens] = useState<Token[]>([])
  const [recipient, setRecipient] = useState('')
  const [isRecipientValid, setIsRecipientValid] = useState(true)
  const [isTransacting, setIsTransacting] = useState(false)

  const [tvl, setTvl] = useState(0)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({ resolver: zodResolver(formSchema) })

  const onSubmit: SubmitHandler<Form> = (data: Form) => {
    if (roles.length === 0) {
      toast({ status: 'error', title: 'You must provide at least one role' })
      return
    }

    if (tokens.length === 0) {
      toast({ status: 'error', title: 'You must provide at least one reward token' })
      return
    }

    if (tvl === 0) {
      toast({ status: 'error', title: 'Total value locked must be greater than 0' })
      return
    }

    if (submit)
      submit({
        name: data.name,
        description: description,
        prize: '100',
        roles: roles,
        tokens: tokens,
        type,
        winners: data.winnersAmount,
      })

    if (data.type === 'Transfer') {
      onParentOpen()

      post?.setContent(previous => ({
        ...previous,
        type: type as any,
        transfer:
          isAddress(tokens?.[0].address) && isAddress(recipient) && !!tokens?.[0].amount
            ? {
                token: tokens?.[0].address as address,
                amount: Number(tokens?.[0].amount) as number,
                recipient: recipient as address,
              }
            : undefined,
      }))

      onClose()
      return
    }

    onParentOpen()

    post?.setContent(previous => ({ ...previous, type: type as any }))
    setQuests({
      name: data.name,
      description: description,
      prize: '100',
      roles: roles,
      tokens: tokens,
      type,
      winners: data.winnersAmount,
    })

    setDescription('')
    onClose()
  }

  /** Handle transaction state reporting */
  useEffect(() => {
    if (!isTransacting) {
      toast.close('status_transfer')
      return
    }

    !toast.isActive('status_transfer') &&
      toast({ status: 'loading', title: 'Processing transfer', id: 'status_transfer', duration: null })
  }, [isTransacting])

  useEffect(() => {
    let newTVL = 0
    const whitelistedTokens = [Address.BUSD, Address.USDC, Address.USDT, Address.W_CDO]
    tokens.forEach(token => {
      if (includesInsensitive(whitelistedTokens, token.address)) {
        newTVL += +token.amount.toString()
      }
    })
    setTvl(newTVL)
  }, [tokens])

  /** Handle add a token when type === Transfer and there are no tokens init yet */
  useEffect(() => {
    if (type !== 'Transfer' || !!tokens.length) {
      return
    }

    setTokens(prev => [...prev, { address: '', amount: 0 }])
  }, [type, tokens.length])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onParentOpen()
        onClose()
      }}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="backgroundMain"

        borderRadius="16px"
        p="0"
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            color="_accentSecondary"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
          >
            Add offer
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody
            p="24px"
            display="flex"
            flexDirection="column"
            gap="16px"
          >
            <Text
              color="_accentSecondary"
              fontWeight="500"
              fontSize="14px"
            >
              Type of the offer
            </Text>
            <ChakraSelect {...register('type')}>
              <option value="Quest">Quest</option>
              <option value="Task">Task</option>
              <option value="Job">Job</option>
              <option value="Transfer">Transfer</option>
            </ChakraSelect>

            {errors.type && <Text sx={{ fontSize: '12px', color: 'redPrimary' }}> {errors.type?.message}</Text>}

            {type === 'Transfer' && (
              <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
                <Text
                  color="_accentSecondary"
                  fontWeight="500"
                  fontSize="14px"
                >
                  Value Transferred
                </Text>
                {!!tokens.length && (
                  <Reward
                    key={0}
                    address={tokens?.[0]?.address}
                    amount={tokens?.[0]?.amount as any}
                    setTokens={setTokens}
                    tokens={tokens}
                    index={0}
                    isClosable={false}
                  />
                )}
                <Text
                  color="_accentSecondary"
                  fontWeight="500"
                  fontSize="14px"
                >
                  Transfer to
                </Text>
                <Input
                  placeholder="0x0…"
                  value={recipient}
                  isInvalid={!isRecipientValid}
                  onChange={event => {
                    setRecipient(event.target.value)
                    setIsRecipientValid(event.target.value.length < 42 ? true : isAddress(event.target.value))
                  }}
                />
                {!isRecipientValid && (
                  <Text
                    color="redPrimary"
                    sx={{ fontSize: '14px' }}
                  >
                    This address is invalid
                  </Text>
                )}
              </Flex>
            )}
            {type !== 'Transfer' && (
              <>
                <Text
                  color="_accentSecondary"
                  fontWeight="500"
                  fontSize="14px"
                >
                  Name the offer
                </Text>
                <Input
                  placeholder="Name"
                  {...register('name')}
                />
                {errors.name && <Text sx={{ fontSize: '12px', color: 'redPrimary' }}> {errors.name?.message}</Text>}
                <Select
                  placehodler="Select roles"
                  type={Type.ROLES}
                  title="Choose roles"
                  items={roles}
                  setItems={setRoles}
                  fetch={() => null as any}
                  buttonText="Add role"
                />
                <Text
                  color="_accentSecondary"
                  fontWeight="500"
                  fontSize="14px"
                >
                  Rewards
                </Text>
                <Button
                  variant="none"
                  color="#000000"
                  bgColor="_accentPrimary"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="min"
                  onClick={() => setTokens(prev => [...prev, { address: '', amount: 0 }])}
                >
                  Add new token
                </Button>
                {tokens?.map((token: any, index: number) => (
                  <Reward
                    key={index}
                    address={token?.address}
                    amount={token?.amount}
                    setTokens={setTokens}
                    tokens={tokens}
                    index={index}
                  />
                ))}
                <Text
                  color="_accentSecondary"
                  fontWeight="500"
                  fontSize="14px"
                >
                  Amount of winners
                </Text>
                <Input
                  type="number"
                  {...register('winnersAmount')}
                  // onKeyDown={(event: any) => {
                  //   if (event.key === '.') {
                  //     event.preventDefault()
                  //   }
                  // }}
                  // onInput={(event: any) => {
                  //   event.target.value = event.target.value.replace(/[^0-9]*/g, '')
                  // }}
                  // value={winnersAmount}
                  // onChange={e => setWinnersAmount(e.target.value)}
                />
                {errors.winnersAmount && (
                  <Text sx={{ fontSize: '12px', color: 'redPrimary' }}> {errors.winnersAmount?.message}</Text>
                )}
                <Text
                  color="_accentSecondary"
                  fontWeight="500"
                  fontSize="14px"
                >
                  Summary
                </Text>
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  {/* <Box
              px="10px"
              py="6px"
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              flexDirection="column"
              background="borderPrimary"
              borderRadius="4px"
              gap="2px"
              width="90px"
            >
              <Text
                color="#6F6A4F"
              fontSize="12px"
              >
                Amount
              </Text>
              <Text
                color="_accentSecondary"
                fontSize="12px"
              >
                {winnersAmount.length > 0 ? winnersAmount : '0'}
              </Text>
            </Box> */}
                  {/* TODO: Change DHC value also here */}
                  <Box
                    px="10px"
                    py="6px"
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    flexDirection="column"
                    background="borderPrimary"
                    borderRadius="4px"
                    gap="2px"
                    width="130px"
                  >
                    <Text
                      color="#6F6A4F"
                      fontSize="12px"
                    >
                      Rewards*
                    </Text>
                    <Text
                      color="_accentSecondary"
                      fontSize="12px"
                    >
                      <Text
                        color="_accentPrimary"
                        as="span"
                      >
                        $
                      </Text>
                      {Number((tvl * 0.8).toFixed(4)) * 1}
                    </Text>
                  </Box>
                  <Box
                    px="10px"
                    py="6px"
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    flexDirection="column"
                    background="borderPrimary"
                    borderRadius="4px"
                    gap="2px"
                    width="130px"
                  >
                    <Text
                      color="#6F6A4F"
                      fontSize="12px"
                    >
                      Invite 20%*
                    </Text>
                    <Text
                      color="_accentSecondary"
                      fontSize="12px"
                    >
                      <Text
                        color="_accentPrimary"
                        as="span"
                      >
                        $
                      </Text>
                      {Number((tvl * 0.2).toFixed(4)) * 1}
                    </Text>
                  </Box>
                </Flex>
                <Box>
                  <Text
                    color="#6F6A4F"
                    fontSize="10px"
                  >
                    *Allocate a 20% commission to individuals who invite the right roles for your offer.
                  </Text>
                  <Text
                    color="#6F6A4F"
                    fontSize="10px"
                  >
                    **The rewards represent the total value locked, divided among the selected winners.
                  </Text>
                </Box>
              </>
            )}
          </ModalBody>
          <ModalFooter
            borderTop="1px solid borderPrimary"
            width="100%"
          >
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text fontSize="14px">
                <Text
                  fontSize="14px"
                  color="_accentPrimary"
                  as="span"
                >
                  $
                </Text>
                {Number(tvl.toFixed(4)) * 1} Total value locked
              </Text>
              <Flex gap="8px">
                <Button
                  onClick={() => {
                    onParentOpen()
                    onClose()
                  }}
                >
                  Back
                </Button>
                <Tooltip label={wallet.isLoggedIn ? '' : 'Nie jesteś zalogowany'}>
                  <Button
                    bg="_accentPrimary"
                    color="backgroundMain"
                    variant="none"
                    type="submit"
                    onClick={() => console.log('submitting')}
                    isDisabled={!wallet.isLoggedIn}
                  >
                    Add offer
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
