import React, { useState } from 'react'
import { Button, Flex, Tooltip } from '@chakra-ui/react'
import { pages } from '../../../pages/Admin'

export enum Switcher {
  Activity,
  Reported,
  Suspended,
  ToBeApproved,
  Chat,
  Settings,
}

export const ContentSwitcher = ({
  initialValue,
  setValue,
}: {
  initialValue: Switcher
  setValue: React.Dispatch<React.SetStateAction<Switcher>>
}) => {
  const [isActive, setIsActive] = useState(initialValue)

  const handlerActive = (value: Switcher) => {
    setIsActive(value)
    setValue(value)
  }

  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      w={{ base: '100%', sm: 'auto' }}
      flexDirection="column"
      gridGap="2px"
      border="1px solid"
      borderColor="borderPrimary"
      borderX={{ base: 'none', lg: '1px solid' }}
      borderLeftColor="borderPrimary !important"
      borderRightColor="borderPrimary !important"
      bgColor="backgroundMain"
      borderRadius="16px"
      p="16px"
      alignSelf="flex-start"
    >
      {pages.map(tab => {
        return (
          <Tooltip key={tab.id}>
            <Button
              display="flex"
              __css={{}}
              _disabled={{ filter: 'grayscale(1)', opacity: '.5', cursor: 'not-allowed' }}
              key={tab.name}
              _hover={{
                background: '_accentSecondaryLight',
              }}
              onClick={() => handlerActive(tab.id)}
              bgColor={isActive === tab.id ? '_accentSecondaryLight' : 'transparent'}
              cursor="pointer"
              borderRadius="4px"
              p="6px 16px"
              color={isActive === tab.id ? '_accentPrimary' : 'textSecondary'}
            >
              {tab.name}
            </Button>
          </Tooltip>
        )
      })}
    </Flex>
  )
}
