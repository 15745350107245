import { useState, useEffect } from 'react'

export const useCopy = () => {
  const [isRecentlyCopied, setIsRecentlyCopied] = useState(false)

  const handleCopy = (content: string) => {
    window.navigator.clipboard.writeText(content)
    setIsRecentlyCopied(true)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isRecentlyCopied) {
        setIsRecentlyCopied(false)
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [isRecentlyCopied])

  return { isCopied: isRecentlyCopied, handleCopy }
}
