import { Flex } from '@chakra-ui/react'
import { DegenTokenInfo } from '../components/pages/Dashboard/DegenTokenInfo/degenTokenInfo'
import { MainInfo } from '../components/pages/Dashboard/MainInfo/mainInfo'

export const Dashboard = () => {
  return (
    <Flex
      flexWrap="wrap"
      flexDir="column"
      gap="48px"
      bg="backgroundSecondary"
      w="100%"
    >
      <Flex
        gap="24px"
        w="100%"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex
          w="100%"
          justifyContent="space-between"
        >
          <MainInfo />
        </Flex>
        <DegenTokenInfo />
      </Flex>
    </Flex>
  )
}
