const includesInsensitive = (values: string[], value: string) =>
  values.some(element => element?.toLowerCase() === value?.toLowerCase())

const splitByIndex = (data?: string, index: number = 0): string => {
  if (!data || Math.abs(index || 0) > data.length) {
    return ''
  }

  const _index = index < 0 ? data.length + index : index

  return data.substring(0, _index) + '.' + data.substring(_index)
}

const isEqualInsensitive = (a: string, b: string) => a?.toLowerCase() === b?.toLowerCase()

/**
 * @param limit `limit` amount decimal places to leave out at maximum
 */
const trimDecimals = (value: string, limit: number) => {
  value.replace(',', '.')

  if (!value.includes('.')) {
    return value
  }

  if (!limit) {
    return value.split('.')[0]
  }

  return value.slice(0, value.indexOf('.') + 1 + limit)
}

export { includesInsensitive, splitByIndex, isEqualInsensitive, trimDecimals }
