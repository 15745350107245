import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react'
import { Wrapper } from './components/global/wrapper'
import { AccountProfileProvider } from './provider/AccountProfile/context'
import { ContractsProvider } from './provider/Contracts/contractsProvider'
import { theme } from './theme/theme'

import { ChatProvider } from './provider/Chat/chatProvider'
import { EthereumProvider } from './provider/Ethereum/ethereumProvider'
import { IconsProvider } from './provider/Icons/iconsProvider'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Web3Modal } from './context/web3modal'
import { NotificationProvider } from './provider/Notification/notificationProvider'
import { ProfilesProvider } from './provider/Profiles'

const queryClient = new QueryClient()

export const App = () => {
  const { ToastContainer } = createStandaloneToast()

  return (
    <ChakraProvider theme={theme}>
      <Web3Modal>
        <QueryClientProvider client={queryClient}>
          <EthereumProvider>
            <AccountProfileProvider>
              <ProfilesProvider>
              <NotificationProvider>
                <ContractsProvider>
                  <ChatProvider>
                    <IconsProvider>
                      <Wrapper />
                      <ToastContainer />
                    </IconsProvider>
                  </ChatProvider>
                </ContractsProvider>
                </NotificationProvider>
              </ProfilesProvider>
            </AccountProfileProvider>
          </EthereumProvider>
        </QueryClientProvider>
      </Web3Modal>
    </ChakraProvider>
  )
}
