import { zeroAddress } from 'viem'

export type User = {
  mainAddress: string
  avatarUrl: string
  name: string
  nickname: string
  description: string
  lastActive: Date
}

export const emptyUser = {
  mainAddress: '',
  avatarUrl: '',
  name: 'Name',
  nickname: 'Nickname',
  description: 'Description',
  lastActive: new Date(),
}

export type Thread = {
  users: User[]
  messages: Message[]
  id: string
  lastMessage: Message
  page: number
  loading: boolean
  scrollTrigger: boolean
}

export const emptyThread: Thread = {
  users: [emptyUser],
  messages: [],
  id: zeroAddress,
  lastMessage: {
    text: 'Loading',
    assets: [],
    thread: zeroAddress,
    sender: emptyUser.mainAddress,
    date: new Date(),
  },
  loading: true,
  scrollTrigger: false,
  page: 0,
}

export type Asset = {
  id: string
  path: string
  type: 'image' | 'audio'
}

export type Message = {
  text: string
  date: Date
  assets: Asset[]
  thread: string
  sender: string
  uuid?: string
}
