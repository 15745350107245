import { Center, Flex, Link, Spinner, Text } from '@chakra-ui/react'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import { UserWallLayout } from '../UserWallLayout'
import { useState } from 'react'
import useAsyncEffect from '../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../utils/process'
import { useProfileProvider } from '../../../../../../provider/Profile/profileProvider'
import { fetchApi } from '../../../../../../utils/fetcher'
import { IGroup } from '../../../../../../hooks/useGroup'
import { trimAddress } from '../../../../../../utils/parser'
import { GroupIcon } from './groupIcon'

export const UserGroups = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const profile = useProfileProvider()
  const [groups, setGroups] = useState<IGroup[]>([])

  useAsyncEffect(async () => {
    await loaded(async () => {
      const groups: IGroup[] = await fetchApi(`profile/${profile.address}/groups`)
      setGroups(groups)
    }, setIsLoading)
  }, [])
  return (
    <UserWallLayout
      heading="Groups"
      headingDetails={` ${isLoading ? <SpinnerText /> : groups.length || 0} groups`}
    >
      {isLoading ? (
        <Center my="30px">
          <Spinner />
        </Center>
      ) : (
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '16px',
            padding: { base: '0', md: '16px' },
            px: { base: '0', md: '16px' },
          }}
        >
          {groups.map(group => (
            <Link
              key={group.address}
              href={`/group/${group.address}`}
              sx={{
                display: 'flex',
                width: '100%',
                border: '1px solid',
                borderColor: 'borderPrimary',
                borderRadius: '16px',
                p: '20px',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <GroupIcon
                name={group.metadata.name}
                image={group.metadata.image}
              />
              <Text sx={{ fontWeight: '500' }}>{group.metadata.name}</Text>
              <Text sx={{ fontSize: '12px', color: 'textSecondary' }}>Manager: {trimAddress(group.owner)}</Text>
              {group.symbol && <Text sx={{ fontSize: '12px', color: '_accentPrimary' }}>Token: {group.symbol}</Text>}
            </Link>
          ))}
        </Flex>
      )}
    </UserWallLayout>
  )
}
