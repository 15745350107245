import { useState } from 'react'
import useAsyncEffect from '../effects/async'

const DEGEN_PRICE = 0.2

export const useDegenPrice = () => {
  const [percentage, setPercentage] = useState<string>()
  const [oldPrice, setOldPrice] = useState<number>()
  const [price, setPrice] = useState<number>(DEGEN_PRICE)

  useAsyncEffect(async () => {
    // TODO: Makadu backend deprecation fix
    setOldPrice(DEGEN_PRICE)
    // const data = await loaded(async () => await fetcher('https://api.degen.pl/api/token/cdo/price'))
    // // setPrice(data?.new_cdo_price)
    // setOldPrice(data?.old_cdo_price)
    setPercentage('0')
  }, [])

  return { percentage, oldPrice, price }
}
