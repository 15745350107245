import {
  Button,
  Flex,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { trimAddress } from '../../../../utils/parser'
import { AddressEntry } from './AddressEntry'
import { useProfileProvider } from '../../../../provider/Profile/profileProvider'
import { useContractsProvider } from '../../../../provider/Contracts/contractsProvider'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { loaded } from '../../../../utils/process'
import { fetchApi } from '../../../../utils/fetcher'
import { AddressZero } from '../../../../utils/ethereum'
import { zeroAddress } from 'viem'
import { useSignMessageSafe } from '../../../../hooks/utils/useSignMessageSafe'
import { waitForTransaction } from '@wagmi/core'
import { getColor } from '../../../../theme/global'

export const AddressesPopup = () => {
  const [isConnecting, setIsConnecting] = useState(false)
  const [isClaimedOrRoot, setIsClaimedOrRoot] = useState<boolean>()

  const contracts = useContractsProvider()
  const toast = useToast()
  const safeSigner = useSignMessageSafe()

  const { id } = useParams()
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: true,
      sm: false,
    },
    { ssr: false }
  )

  const wallet = useEthereumProvider()
  const profile = useProfileProvider()

  /**
   * TODO: Show notificaiton on the other profile that someone wanted to merge that address with some other profile.
   */
  const handleClaimAddress = async () => {
    await loaded(
      async () => {
        const hash = await contracts.onChainRef?.write.addressIDAddPrepare([profile.address])
        fetchApi(`profile/notifyConnection`, { accountConnected: profile.address, connector: wallet.account })

        const result = await waitForTransaction({ hash })

        if (result.status === 'success') {
          toast({
            status: 'success',
            title: 'Connection started',
            description: 'Please use your wallet to switch to the address added',
          })
        } else {
          toast({
            status: 'error',
            title: 'Connection failed',
            description: 'Please, try again',
          })
        }
      },
      setIsConnecting,
      error => {
        if (error.cause.code === 4001) {
          toast({
            status: 'info',
            title: 'Connection cancelled',
          })
        } else {
          toast({
            status: 'error',
            title: 'Connection failed',
            description: error.cause,
          })
        }
      }
    )
  }

  /* Sync OnChainRef.AddressID status */
  useEffect(() => {
    if (!profile.address || !contracts.onChainRef) {
      setIsClaimedOrRoot(undefined)

      return
    }

    void loaded(async () => {
      const isRegistered = await contracts.onChainRef?.read.getReferralParent([profile.address])

      /* This address has already been registered (either plugged into some other address (it is a child) or registered as-is, standalone (it is the root)). */
      if (isRegistered !== zeroAddress) {
        setIsClaimedOrRoot(true)

        return
      }

      const parentAddress = await contracts.onChainRef?.read.getAddressID([profile.address])
      const parentAddressMembers: address[] = await contracts.onChainRef?.read.getAddressIDMembers([parentAddress])

      /* This address has already been attached as a child to the logged-in address. */
      if (parentAddressMembers.map(address => address.toLowerCase()).includes(profile.address?.toLowerCase() || '')) {
        setIsClaimedOrRoot(true)

        return
      }

      /* This address can be assigned as a child to anyone, by anyone. */
      setIsClaimedOrRoot(false)
    })
  }, [profile.address, contracts.onChainRef])

  useEffect(() => {
    if (!isConnecting) {
      toast.close('account_connecting')
      return
    }

    toast({ title: 'Connecting…', id: 'account_connecting', duration: null })

    return () => {
      toast.close('account_connecting')
    }
  }, [isConnecting])

  return (
    <Popover>
      <PopoverTrigger>
        <Button gap="8px">
          <Image src="/assets/icons/wallet-icon.svg" />
          <Text color="textSecondary">{isMobile ? trimAddress(id ?? AddressZero) : id}</Text>
          <Flex
            align="center"
            justify="center"
            bgColor="borderPrimary"
            height="16px"
            width="16px"
            borderRadius="4px"
          >
            <Image
              src="/assets/icons/chevron-down-small.svg"
              boxSize="12px"
            />
          </Flex>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          borderRadius="8px"
          width="auto"
        >
          <Flex
            p="16px"
            borderRadius="8px"
            bgColor="backgroundMain"
            boxShadow={`0px 4px 8px ${getColor('borderPrimary')}`}
            flexDir="column"
            align="flex-start"
            w="auto"
          >
            <Text
              fontSize="14px"
              color="_accentSecondary"
              fontWeight="500"
            >
              Połączone adresy
            </Text>
            <Text
              fontSize="12px"
              color="textSecondary"
              pb="16px"
            >
              Adresy tego profilu
            </Text>
            <Flex
              flexDir="column"
              gap="8px"
              w="100%"
            >
              {profile.address_.map((wallet: address, index: number) => (
                <AddressEntry
                  key={`${wallet}_${index}`}
                  address={wallet}
                  isDisabled={wallet.toLowerCase() === id?.toLowerCase()}
                />
              ))}
              {isClaimedOrRoot === undefined ? (
                <Spinner />
              ) : isClaimedOrRoot ? null : (
                <Tooltip
                  label={
                    wallet.account?.toLowerCase() === id?.toLowerCase() ||
                    profile.address_.map(wallet => wallet.toLowerCase()).includes(wallet.account?.toLowerCase()!)
                      ? 'Your blockchain address is already connected to this profile'
                      : 'Connect this address to your profile'
                  }
                >
                  <Button
                    variant="ghost"
                    onClick={handleClaimAddress}
                    display="flex"
                    gap="8px"
                    border="none"
                    outline="none"
                    justifyContent="flex-start"
                    isDisabled={
                      wallet.account?.toLowerCase() === id?.toLowerCase() ||
                      profile.address_.map(wallet => wallet.toLowerCase()).includes(wallet.account?.toLowerCase()!) ||
                      !wallet.account
                    }
                  >
                    <Image
                      src="/assets/icons/circle-plus-outline.svg"
                      boxSize="20px"
                    />
                    <Text
                      fontWeight="500"
                      color="_accentPrimary"
                    >
                      Claim this address
                    </Text>
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
