import React, { useState } from 'react'
import { Center, Spinner } from '@chakra-ui/react'
import { IProfileBatch, useProfileProvider } from '../../../../../../provider/Profile/profileProvider'
import useAsyncEffect from '../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../utils/process'
import { fetchApi } from '../../../../../../utils/fetcher'
import UserFollowersComponent from './userFollowersComponent'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import { usePagination } from '@ajna/pagination'
import { UserWallLayout } from '../UserWallLayout'
import { UserWallPagination } from '../UserWallPagination'

export const UserFollowers = () => {
  const [profiles, setProfiles] = useState<IProfileBatch>()
  const [isLoading, setIsLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: totalPages * 10,
      limits: {
        outer: 2,
        inner: 2,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })
  const profile = useProfileProvider()

  useAsyncEffect(async () => {
    if (!profile.followers?.length) {
      setIsLoading(false)
      return
    }

    void loaded(async () => {
      const data: { profiles: IProfileBatch; totalPages: string } = await fetchApi(`profile`, {
        addresses: profile.followers,
        page: currentPage,
      })

      setTotalPages(+data.totalPages)
      data.profiles.forEach(datum => (datum.address = datum.address_?.[0]))
      setProfiles(data.profiles)
    }, setIsLoading)
  }, [profile.followers, currentPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <>
      <UserWallLayout
        heading="Followers"
        headingDetails={` ${isLoading ? <SpinnerText /> : profiles?.length || 0} followers`}
      >
        {isLoading ? (
          <Center my="30px">
            <Spinner />
          </Center>
        ) : (
          profiles?.map(profile => (
            <React.Fragment key={profile.address}>
              <UserFollowersComponent
                address={profile.address!}
                name={profile.name}
                nickname={profile.nickname}
                roles={profile.roles!}
                description={profile.description}
              />
            </React.Fragment>
          ))
        )}
      </UserWallLayout>
      <UserWallPagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </>
  )
}

export default UserFollowers
