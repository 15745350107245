import { Box, Flex, Tooltip, Image } from '@chakra-ui/react'
import { useOnboardingProvider } from '../../../provider/Onboarding/onboardingProvider'
import { routes, adminRoutes } from '../navbar/routes'
import { Link as LinkR, useLocation, useNavigate } from 'react-router-dom'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import { getColor } from '../../../theme/global'
import { liveChatAnimation } from '../../pages/Home/LeftPanel/leftPanel'

export const BottomNavigation = () => {
  const onboarding = useOnboardingProvider()
  const accountProfile = useAccountProfileProvider()
  const history = useLocation()

  return (
    <Flex
      sx={{
        display: {
          base: 'flex',
          lg: 'none',
        },
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '50px',
        bg: getColor('_backgroundTertiary', .75),
        backdropFilter: 'blur(10px)',
        justifyContent: 'space-around',
        alignItems: 'center',
        zIndex: onboarding.shouldElevateTopBar ? 'popover' : 'sticky',
      }}
    >
      {[...routes, ...(accountProfile.isAdmin ? adminRoutes : [])].map(item => {
            return (
              <Tooltip
                key={item.href}
                hasArrow
              >
                <Box
                  borderRadius="8px"
                  key={item.href}
                  _hover={{
                    borderRadius: '8px',
                    background: '_accentSecondaryLight',
                  }}
                  position="relative"
                  _after={item.href === '/chat' ? {
                    content: '""',
                    position: 'absolute',
                    boxSize: '9px',
                    borderRadius: 'full',
                    bg: '#34dfcd',
                    top: '-16px',
                    right: '16px',
                    boxShadow: `0px 0px 25px 5px ${getColor('#5cf5cb')}`,
                    animation: `${liveChatAnimation} ease-in-out 2.66s infinite`,
                  } : {}}
                >
                  <LinkR
                    to={item.href}
                    style={{
                      padding: '6px 12px',
                      borderRadius: '8px',
                      background: history.pathname === item.href ? getColor('_accentSecondaryLight') : 'transparent',
                      fontSize: '12px',
                      color: `${history.pathname === item.href ? getColor('_accentPrimary') : getColor('textSecondary')}`,
                      display: 'block',
                    }}
                  >
                    {item.label === 'PUMP' ? (
                        <Box sx={{
                          position: 'relative',
                          w: '42px',
                        }}>
                          <Image
                            src="/assets/icons/pump.svg"
                            sx={{ position: 'absolute', top: '0', left: '0', transform: 'translate(-23%, -50%)' }}
                          />
                        </Box>
                      ) : (
                        item.label
                      )}
                  </LinkR>
                </Box>
              </Tooltip>
            )
          })}
    </Flex>
  )
}
