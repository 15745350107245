import { Box, Flex, Icon, Tooltip, Image } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { ConnectWalletCustom } from './ConnectWallet/ConnectWalletCustom'
import { useScrollPosition } from '../../../hooks/utils/useScrollPosition'
import { useOnboardingProvider } from '../../../provider/Onboarding/onboardingProvider'
import { adminRoutes, routes } from './routes'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import LogoExtended, { EVariant } from '../../images/LogoExtended'
import { getColor } from '../../../theme/global'
import useIsMobile from '../../../hooks/chakra/useIsMobile'

export const Navbar = () => {
  const history = useLocation()
  const { scrollPosition } = useScrollPosition()
  const onboarding = useOnboardingProvider()
  const accountProfile = useAccountProfileProvider()

  const isMobile = useIsMobile()

  return (
    <Flex
      mb="2px"
      position="sticky"
      top="0"
      width="100%"
      background="backgroundSecondary"
      zIndex={onboarding.shouldElevateTopBar ? 'popover' : 'sticky'}
      transition="box-shadow .5s ease-in-out"
      {...(scrollPosition > 1 || onboarding.shouldElevateTopBar
        ? { boxShadow: !onboarding ? '3.4px 6.7px 6.7px hsl(0deg 0% 0% / 0.05)' : 'none' }
        : {})}
      justifyContent="center"
      py="11px"
    >
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        gap="20px"
        maxW="1180px"
        px={{ base: '12px', sm: '16px' }}
        {...(isMobile ? {} : { pb: '8px' })}
      >
        <Flex
          align="center"
          gap="8px"
          flexShrink="0"
        >
          <Link to="/">
            <Flex
              align="center"
              gap="8px"
              flexShrink="0"
            >
              <LogoExtended variant={isMobile ? EVariant.Small : EVariant.Normal} />
            </Flex>
          </Link>
        </Flex>

        <Flex
          gap="8px"
          align="center"
          justifyContent="space-between"
          display="flex"
          userSelect="none"
          ml="auto"
        >
          <Flex
            gap="4px"
            display={{ base: 'none', lg: 'flex' }}
          >
            {[...routes, ...(accountProfile.isAdmin ? adminRoutes : [])].map(item => {
              return (
                <Tooltip
                  key={item.href}
                  hasArrow
                  label={item.href === '/domains' && 'Coming soon!'}
                >
                  <Box
                    key={item.href}
                    _hover={{
                      borderRadius: '8px',
                      background: '_accentSecondaryLight',
                      color: 'textSecondary',
                    }}
                  >
                    <Link
                      to={item.href}
                      draggable={false}
                      style={{
                        pointerEvents: item.href === '/domains' ? 'none' : 'auto',
                        padding: '6px 14px',
                        lineHeight: '20px',
                        borderRadius: '8px',
                        background: history.pathname === item.href ? getColor('_accentSecondaryLight') : 'transparent',
                        fontSize: '14px',
                        color: `${history.pathname === item.href ? getColor('_accentPrimary') : 'inherit'}`,
                        display: 'block',
                        position: 'relative',
                      }}
                    >
                      {item.label === 'PUMP' ? (
                        <Box sx={{
                          position: 'relative',
                          w: '48px',
                        }}>
                          <Image
                            src="/assets/icons/pump.svg"
                            sx={{ position: 'absolute', top: '0', left: '0', transform: 'translateY(-33%)' }}
                          />
                        </Box>
                      ) : (
                        item.label
                      )}
                    </Link>
                  </Box>
                </Tooltip>
              )
            })}
          </Flex>

          <Flex
            display="flex"
            gridGap="8px"
          >
            <ConnectWalletCustom />
            {/* <w3m-button /> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
