export const SelectStyle = {
  baseStyle: {
    field: {
      fontSize: '14px',
      color: '#6F6A4F',
    },
    icon: {
      h: '24px',
      w: '24px',
      borderRadius: '4px',
      bgColor: 'borderPrimary',
      cursor: 'pointer !important',
    },
  },
}
