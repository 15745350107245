import { Box, Image, keyframes } from '@chakra-ui/react'
import { css } from '@emotion/react'

export const shimmerAnimation = (step1: number, step2: number) => keyframes`
  0% {
    transform: translateX(-100%);
  }
  ${step1}% {
    transform: translateX(100%);
  }
  ${step2}%, 100% {
    transform: translateX(100%);
  }
`

/**
 * @param movement [s]
 * @param pause [s]
 */
export const shimmerEffect = (movement: number, pause: number) => {
  const percentage = (movement / (movement + pause)) * 100
  const time = movement + pause

  return css`
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: ${shimmerAnimation(percentage, percentage + 0.1)} ${time}s infinite;
  `
}

export enum EVariant {
  Normal,
  Small,
}

const LogoExtended = ({ variant = EVariant.Small }: { variant?: EVariant }) => {
  return (
    <Box position="relative">
      <Image
        src={variant === EVariant.Small ? '/logo.svg' : '/assets/brand/logo-extended.svg'}
        maxW={variant === EVariant.Small ? '175px' : '224px'}
        height="32px"
        sx={{
          position: 'absolute',
        }}
      />
      <Box
        position="relative"
        overflow="hidden"
        height="32px"
        w={variant === EVariant.Small ? '175px' : '224px'}
        sx={{
          maskImage: `url(${variant === EVariant.Small ? '/logo.svg' : '/assets/brand/logo-extended.svg'})`,
          maskSize: 'contain',
          maskRepeat: 'no-repeat',
          maskOrigin: 'fill-box',
          maskPosition: 'center',
        }}
      >
        <Box
          bgGradient="linear(to-r, transparent, _foregroundSecondary, _foregroundPrimary, transparent)"
          w="100%"
          h="100%"
          css={shimmerEffect(1, 5)}
        />
      </Box>
    </Box>
  )
}

export default LogoExtended
