import { Box, Flex } from '@chakra-ui/react'
import { CardContainer } from '../../../shared/containers/CardContainer'
import { InfoItem } from './InfoItem/infoItem'
import useAsyncEffect from '../../../../hooks/effects/async'
import { useReducer } from 'react'
import { fetchApi } from '../../../../utils/fetcher'
import find from 'lodash/find'
import { loaded } from '../../../../utils/process'

export interface IRateAccumulationItem {
  _id: Date
  count: number
}

export interface IRateGlobal
  extends Record<
    'profiles' | 'follows' | 'unfollows' | 'friends' | 'posts' | 'transactions' | 'idUpdates' | 'onboardedProfiles',
    IRateAccumulationItem[]
  > {}

type IMainInfo = { [key in 'profiles' | 'domains' | 'posts' | 'transactions']: { count: number; change: number } }

// todo: check changes (whether the date gets parsed properly)
export const MainInfo = () => {
  const [state, dispatch] = useReducer(
    (state: IMainInfo, updatedProperties: Partial<IMainInfo>) => ({
      ...state,
      ...updatedProperties,
    }),
    { domains: {}, posts: {}, profiles: {}, transactions: {} } as IMainInfo
  )

  useAsyncEffect(
    async () =>
      loaded(async () => {
        const ratesData = (await fetchApi('rate', {})) as IRateGlobal

        dispatch({
          profiles: {
            count: ratesData.profiles.reduce((acc, curr) => acc + curr.count, 0),
            change: getThisDayChangeFromDayRates(ratesData.profiles),
          },
          posts: {
            count: ratesData.posts.reduce((acc, curr) => acc + curr.count, 0),
            change: getThisDayChangeFromDayRates(ratesData.posts),
          },
          transactions: {
            count: ratesData.transactions.reduce((acc, curr) => acc + curr.count, 0),
            change: getThisDayChangeFromDayRates(ratesData.transactions),
          },
        })
      }),
    []
  )

  useAsyncEffect(
    async () =>
      loaded(async () => {
        /* FIXME: TODO: api.degen.pl deprecation; count the /domain/bought-domains/count; where did this data come from to their first backend? */
        dispatch({ domains: { count: undefined as any, change: 0 } })
      }),
    []
  )

  return (
    <CardContainer width="100%">
      <Flex
        pt="24px"
        flexDirection="column"
        gridGap="24px"
      >
        <Flex
          justifyContent="space-between"
          w="100%"
          gridGap="24px"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <InfoItem
            icon="/assets/icons/data-users.svg"
            value={state.profiles.count}
            day={state.profiles.change}
            label="Total Profiles"
          />
          <Box
            h={{ base: '1px', md: '32px' }}
            w={{ base: '100%', md: '1px' }}
            bg="borderPrimary"
          />
          <InfoItem
            icon="/assets/icons/data-world.svg"
            label="Registered Domains"
            value={state.domains.count}
            day={state.domains.change}
          />
        </Flex>
        <Box
          h="1px"
          w="100%"
          bg="borderPrimary"
        />
        <Flex
          justifyContent="space-between"
          w="100%"
          gridGap="24px"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <InfoItem
            icon="/assets/icons/data-txn.svg"
            value={state.posts.count}
            day={state.posts.change}
            label="Total Posts"
          />
          <Box
            h={{ base: '1px', md: '32px' }}
            w={{ base: '100%', md: '1px' }}
            bg="borderPrimary"
          />
          <InfoItem
            icon="/assets/icons/data-wallet.svg"
            value={state.transactions.count}
            day={state.transactions.change}
            label="Total Transactions"
          />
        </Flex>
      </Flex>
    </CardContainer>
  )
}

const getThisDayChangeFromDayRates = (rates: IRateAccumulationItem[]) =>
  (find(rates, { _id: Date.now() - (Date.now() % (24 * 60 * 60 * 1000)) }) as IRateAccumulationItem | undefined)
    ?.count ?? 0
