import React, { useState } from 'react'
import { Box, Button, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import { IProfileBatch, useProfileProvider } from '../../../../../../provider/Profile/profileProvider'
import useAsyncEffect from '../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../utils/process'
import { fetchApi } from '../../../../../../utils/fetcher'
import { Link } from 'react-router-dom'
import { ButtonFollow } from '../../../../../shared/actions/ButtonFollow'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import { getInitials } from '../../../../../../utils/parser'
import { usePagination } from '@ajna/pagination'
import { UserWallLayout } from '../UserWallLayout'
import { UserWallPagination } from '../UserWallPagination'

export const UserFriends = () => {
  const [profiles, setProfiles] = useState<IProfileBatch>()
  const [isLoading, setIsLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)

  const profile = useProfileProvider()

  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: totalPages * 10,
      limits: {
        outer: 2,
        inner: 2,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })

  useAsyncEffect(async () => {
    if (!profile.friends?.length) {
      setIsLoading(false)
      return
    }

    void loaded(async () => {
      const data: { profiles: IProfileBatch; totalPages: string } = await fetchApi(`profile`, {
        addresses: profile.followers,
        page: currentPage,
      })

      setTotalPages(+data.totalPages)
      data.profiles.forEach(datum => (datum.address = datum.address_?.[0]))
      data.profiles.reverse()
      setProfiles(data.profiles)
    }, setIsLoading)
  }, [profile.friends, currentPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <>
      <UserWallLayout
        heading="Friends"
        headingDetails={` ${isLoading ? <SpinnerText /> : profiles?.length || 0} friends`}
      >
        {isLoading ? (
          <Center my="30px">
            <Spinner />
          </Center>
        ) : (
          profiles?.map(profile => (
            <Box
              key={profile.address}
              sx={{
                '&:not(:last-of-type)': {
                  borderBottom: '1px solid borderPrimary',
                },
              }}
            >
              <Flex
                p="12px 16px"
                justifyContent="space-between"
              >
                <Link to={`/profile/${profile.address}`}>
                  <Flex gridGap="8px">
                    <Box
                      alignItems="center"
                      justifyContent="center"
                      w="40px"
                      h="40px"
                      display="flex"
                      borderRadius="50%"
                      bgColor="bluePlaceholder"
                      color="_textQuaternary"
                      textTransform="uppercase"
                    >
                      {getInitials(profile.name)}
                    </Box>
                    <Flex direction="column">
                      <Text
                        fontSize="14px"
                        color="_accentSecondary"
                      >
                        {profile.name}
                      </Text>
                      <Text
                        fontSize="12px"
                        color="textSecondary"
                      >
                        @{profile.nickname}
                      </Text>
                    </Flex>
                  </Flex>
                </Link>
                <Flex
                  gridGap="8px"
                  height="32px"
                >
                  {/* <Button
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="_borderPrimary"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="32px"
                  >
                    <Image
                      src="/assets/icons/dots.svg"
                      boxSize="16px"
                    />
                  </Button> */}
                  <Button
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="_borderPrimary"
                    display="flex"
                    alignItems="center"
                    height="32px"
                    justifyContent="center"
                  >
                    <Image
                      src="/assets/icons/message-icon.svg"
                      boxSize="16px"
                    />
                  </Button>
                  <ButtonFollow target={profile.address} />
                </Flex>
              </Flex>
              <Flex
                p="8px 64px"
                flexDirection="column"
              >
                <Flex gridGap="4px">
                  {/* TODO: fix overflow */}
                  {/* <RolesWithIndicator roles={profile.roles} /> */}
                </Flex>
                <Text
                  pt="10px"
                  fontSize="14px"
                  color="textQuaternary"
                >
                  {profile.description}
                </Text>
              </Flex>
            </Box>
          ))
        )}
      </UserWallLayout>
      <UserWallPagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </>
  )
}

export default UserFriends
