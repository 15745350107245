import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { buildIpfsGateway, getInitials, trimAddress } from '../../../../../utils/parser'
import { emptyPostContent, IPostContent, IUsePost } from '../usePost'
import { RecipientsModal } from './RecipientsModal'
import { IPostSchema, PostType } from '../../../../pages/Home/MiddlePanel/deafult/Default'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { fetchApi } from '../../../../../utils/fetcher'
import { loaded } from '../../../../../utils/process'
import { emptyProfile, IProfile } from '../../../../../provider/Profile/profileProvider'
import { Post } from '../../../../pages/Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import useWindowDimensions from '../../../../../hooks/utils/useWindowDimensions'
import SpinnerText from '../../../tags/SpinnerText'
import { QuestModal } from './QuestModal/QuestModal'
import { Address } from '../../../../../contracts/address'
import { includesInsensitive } from '../../../../../utils/ts'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { Link } from 'react-router-dom'
import { getColor } from '../../../../../theme/global';

interface IQuickPostModalProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  post: IUsePost
  resultHandle?: (post: IPostSchema) => void
  pickFile?: () => void
  removeFile?: (idx: number) => void
  sharedPost?: IPostSchema
  isImagesAvailable?: boolean
  group?: address
}

export const QuickPostModal: FC<IQuickPostModalProps> = ({
  isOpen,
  onClose,
  onOpen,
  post,
  resultHandle,
  pickFile,
  removeFile,
  sharedPost,
  isImagesAvailable,
  group,
}) => {
  const initialRef = useRef(null)
  const dropzoneRef = useRef(null!)

  const { isOpen: isRecipentOpen, onOpen: onRecipentOpen, onClose: onRecipentClose } = useDisclosure()
  const { isOpen: isQuestOpen, onOpen: onQuestOpen, onClose: onQuestClose } = useDisclosure()
  const [recipients, setRecipients] = useState<IProfile[]>([emptyProfile])
  const [targetAmount, setTargetAmount] = useState<number>(0)
  const [maxRecipients, setMaxRecipients] = useState<number>(0)

  const [quest, setQuest] = useState<{
    name: string
    description: string
    tokens: any[]
    roles: any[]
    type: PostType
    winners: number
  }>()

  const accountProfile = useAccountProfileProvider()
  const wallet = useEthereumProvider()
  const toast = useToast()
  const { width } = useWindowDimensions()

  const [detailsVisible, setDetailsVisible] = useState(false)

  const handlePost = async () => {
    onClose()
    const data: IPostContent = { ...post.content, group }

    const result = await post.post(data)
    if (!result) {
      return
    }

    console.log('resulting with', result.data)
    resultHandle?.(result.data)

    post.setContent(emptyPostContent)
  }

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length === 0) {
      onClose()
    }

    post.setContent(prev => ({ ...prev, text: e.target.value }))
  }

  // TODO: fetch this somewhere else (and cache)
  useAsyncEffect(
    async () =>
      await loaded(async () => {
        setMaxRecipients(parseInt(await fetchApi('profile/counts/total', {})))
      }),
    []
  )

  // FIXME: backend impl needed
  // useAsyncEffect(
  //   async () =>
  //     await loaded(async () => {
  //       setRecipients(await fetchApi('profile', {}))
  //     }),
  //   []
  // )

  useEffect(() => {
    if (quest !== undefined) {
      post.setContent(prev => ({
        ...prev,
        quest: {
          name: quest.name,
          id: Math.floor(Math.random() * 100).toString(),
          roles: quest.roles,
          slots: quest.winners,
        },
        reward: {
          tokens: [...quest.tokens],
          usd: 100,
        },
        type: quest.type,
      }))
    }
  }, [quest])

  useEffect(() => {
    if (post.content.target.roles.length || post.content.target.interests.length) {
      return
    }

    setTargetAmount(maxRecipients)
  }, [maxRecipients, post.content.target])

  useEffect(() => {
    const roles = post.content.target.roles
    const interests = post.content.target.interests

    if (!roles.length && !interests.length) {
      return
    }

    let targetedRecipients: IProfile[] = []

    targetedRecipients = recipients.filter(recipient =>
      recipient.interests.some(interest => interests.includes(interest))
    )
    targetedRecipients = targetedRecipients.concat(
      recipients
        .filter(recipient => !targetedRecipients.includes(recipient))
        .filter(recipient => recipient.roles.some(role => roles.includes(role)))
    )

    setTargetAmount(targetedRecipients.length)
  }, [post.content.target])

  const targetingAudienceModal = useDisclosure()

  const handleAddQuest = () => {
    if (post.content.target.roles.length === 0) {
      onClose()
      onQuestOpen()
    } else {
      targetingAudienceModal.onOpen()
    }
  }

  const handleRemoveRoles = () => {
    post.setContent({ ...post.content, target: { roles: [], interests: post.content.target.interests } })
    onQuestOpen()
  }
  const whitelistedTokens = [Address.BUSD, Address.USDC, Address.USDT, Address.W_CDO]
  const [tvl, setTvl] = useState(0)
  useEffect(() => {
    let newTVL = 0

    quest?.tokens.forEach(token => {
      if (includesInsensitive(whitelistedTokens, token.address)) {
        newTVL += token.amount
      }
    })
    setTvl(newTVL)
  }, [quest])

  return (
    <>
      <Modal
        isOpen={targetingAudienceModal.isOpen}
        onClose={targetingAudienceModal.onClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent
          bgColor="backgroundMain"

          borderRadius="16px"
          p="16px"
          mt={{ base: '100px', sm: 'auto' }}
        >
          <ModalBody>
            <Text
              fontSize="16px"
              fontWeight="600"
              color="_accentSecondary"
            >
              Targeting audience modal
            </Text>
            <Text
              mt="10px"
              color="textQuaternary"
              fontSize="14px"
            >
              Initiating a new task will reset your audience selection, and the targeting option will be disabled.
            </Text>
            <Flex
              width="full"
              alignItems="center"
              justifyContent="space-between"
              mt="12px"
            >
              <Button onClick={() => targetingAudienceModal.onClose()}>Back</Button>
              <Button
                variant="none"
                color="#000000"
                bgColor="_accentPrimary"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={handleRemoveRoles}
              >
                Continue
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <RecipientsModal
        isOpen={isRecipentOpen}
        onClose={onRecipentClose}
        count={targetAmount}
        post={post}
        openParent={onOpen}
      />
      <QuestModal
        isOpen={isQuestOpen}
        onClose={onQuestClose}
        setQuests={setQuest}
        onParentOpen={onOpen}
        post={post}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={(width ?? Number.MAX_SAFE_INTEGER) >= 480}
        initialFocusRef={initialRef}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          bgColor="backgroundMain"

          borderRadius="16px"
          p="0"
          mx="10px"
          mt={{ base: '100px', sm: 'auto' }}
        >
          {!!group && (
            <ModalHeader>
              <Text sx={{ fontSize: 'smaller' }}>[posting in group: {trimAddress(group ?? '')}]</Text>
            </ModalHeader>
          )}
          <ModalBody p="32px 16px 16px">
            <Flex
              flexDir="column"
              gap="24px"
            >
              <Flex
                gridGap="16px"
                pb={{ base: '12px', md: '0px' }}
                w="100%"
              >
                <Flex>
                  <Box
                    textTransform="uppercase"
                    fontSize="12px"
                    color="_accentPrimary"
                    display="flex"
                    borderRadius="50%"
                    bgColor="_accentSecondary"
                    h="32px"
                    w="32px"
                    alignItems="center"
                    justifyContent="center"
                    backgroundImage={buildIpfsGateway(accountProfile.avatar)}
                    backgroundPosition="center center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                  >
                    {accountProfile.avatar ? '' : getInitials(accountProfile.name)}
                  </Box>
                </Flex>
                <Textarea
                  resize="none"
                  disabled={post.isPosting}
                  value={post.content.text}
                  onChange={handleInput}
                  color="_textPrimary"
                  p="0px"
                  h="20px"
                  fontSize="14px"
                  placeholder={`Co się dzieje${
                    accountProfile.name ? `, ${accountProfile.name.split(/[ ,]+/)[0]}` : ''
                  }?`}
                  _placeholder={{ color: 'textTertiary' }}
                  border="none"
                  sx={{
                    borderRadius: '0'
                  }}
                  _focus={{
                    boxShadow: 'none',
                    border: 'none !important',
                    outline: 'none !important',
                  }}
                  onFocus={e =>
                    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                  }
                  ref={initialRef}
                />
              </Flex>
              {!!post.content.transfer && (
                <Flex
                  sx={{
                    flexDirection: 'column',
                    border: '1px solid',
                    borderColor: 'textSecondary',
                    borderRadius: '8px',
                    padding: '8px',
                  }}
                >
                  <Text sx={{ fontWeight: '600' }}>Transfer</Text>
                  <Flex sx={{ fontSize: '14px', flexDirection: 'column', pb: '8px' }}>
                    <Text sx={{ fontWeight: '500' }}>value&nbsp;</Text>
                    <Text>{post.content.transfer?.amount}</Text>
                    <Text>{post.content.transfer?.token}</Text>
                  </Flex>
                  <Flex sx={{ fontSize: '14px', flexDirection: 'column' }}>
                    <Text sx={{ fontWeight: '500' }}>recipient&nbsp;</Text>
                    <Text>{post.content.transfer?.recipient}</Text>
                  </Flex>
                </Flex>
              )}
              {sharedPost && (
                <Box
                  mx="auto"
                  mb={sharedPost ? '8px' : '0'}
                  border="1px solid"
                  borderColor="borderPrimary"
                  borderRadius="16px"
                  width="100%"
                  overflow="hidden"
                >
                  <Post
                    isNested={true}
                    data={sharedPost as IPostSchema}
                  />
                </Box>
              )}
              {!sharedPost && (
                <Grid
                  templateColumns={post.content.images.length > 1 ? '1fr 1fr' : '1fr'}
                  gridGap="8px"
                  maxHeight="45vh"
                  overflowY="auto"
                >
                  {post.content.images.map((image, index: number) => (
                    <Flex
                      key={`${image}_${index}`}
                      bgColor="borderPrimary"
                      h={post.content.images.length > 1 ? '250px' : '350px'}
                      align="center"
                      justify="center"
                      borderRadius="8px"
                    >
                      <Flex
                        borderRadius="8px"
                        position="relative"
                        bgImage={buildIpfsGateway(image)}
                        backgroundSize="cover"
                        w="100%"
                        h="100%"
                        zIndex="1"
                      >
                        <Image
                          src="/assets/icons/delete-attachment.svg"
                          position="absolute"
                          top="8px"
                          left="8px"
                          _hover={{ cursor: 'pointer' }}
                          onClick={removeFile !== undefined ? () => removeFile(index) : () => {}}
                        />
                      </Flex>
                      <Box
                        sx={{
                          zIndex: 0,
                          position: 'absolute',
                          fontSize: '128px',
                          transform: 'translateY(-20%)',
                          color: 'textTertiary',
                        }}
                      >
                        <SpinnerText />
                      </Box>
                    </Flex>
                  ))}
                </Grid>
              )}
              {quest?.name !== '' && quest?.name !== undefined && (
                <Flex
                  flexDir="column"
                  gap="10px"
                  bg="backgroundTertiary"
                  border="1px solid"
                  borderColor="_borderPrimary"
                  borderRadius="8px"
                  p="15px 23px"
                >
                  <Flex
                    align="center"
                    justify="space-between"
                    w="100%"
                  >
                    <Text
                      fontSize="16px"
                      fontWeight="600"
                      color="_accentSecondary"
                    >
                      {quest?.roles[0]}
                    </Text>
                    <Flex
                      align="center"
                      gap="4px"
                    >
                      <Text
                        fontSize="12px"
                        cursor="pointer"
                        color="_accentPrimary"
                        fontWeight="500"
                        onClick={handleAddQuest}
                      >
                        Edit
                      </Text>
                      <Image
                        src="/assets/icons/trash.svg"
                        cursor="pointer"
                        onClick={() =>
                          setQuest({
                            name: '',
                            description: '',
                            tokens: [],
                            roles: [],
                            type: PostType.Post,
                            winners: 0,
                          })
                        }
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    bg="backgroundMain"
                    p="6px 10px"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="borderPrimary"
                    fontSize="12px"
                    color="textSecondary"
                    flexDir="column"
                    mt="8px"
                  >
                    <Flex
                      align="center"
                      justify="space-between"
                    >
                      <Flex
                        gap="13px"
                        align="center"
                      >
                        <Flex as="span">
                          Total value locked:&nbsp;
                          <Flex
                            as="span"
                            color="textPrimary"
                            fontWeight="500"
                          >
                            <Text color="_accentPrimary">$</Text>
                            {(tvl * 1).toFixed(4)}
                          </Flex>
                        </Flex>
                        <Box
                          h="15px"
                          w="1px"
                          bg="#D2D2D2"
                        />
                        <Flex as="span">
                          Assets:&nbsp;
                          <Text
                            as="span"
                            color="textPrimary"
                            fontWeight="500"
                          >
                            {quest?.tokens?.length}
                          </Text>
                        </Flex>
                        <Box
                          h="15px"
                          w="1px"
                          bg="#D2D2D2"
                        />
                      </Flex>
                      <Flex
                        align="center"
                        gap="4px"
                        onClick={() => setDetailsVisible(prev => !prev)}
                        userSelect="none"
                        cursor="pointer"
                      >
                        <Text>Details</Text>
                        <Image
                          src="/assets/icons/chevron-down-small.svg"
                          sx={{ transform: detailsVisible && 'rotate(180deg)' }}
                        />
                      </Flex>
                    </Flex>
                    {detailsVisible && (
                      <Flex
                        flexDir="column"
                        gap="16px"
                        my="16px"
                      >
                        <Flex
                          align="center"
                          gap="24px"
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          <Flex
                            sx={{ width: '100%' }}
                            align="center"
                            gap="12px"
                          >
                            <Image src="/assets/icons/sheet-icon.svg" />
                            <Flex
                              as="span"
                              fontSize="12px"
                              color="textSecondary"
                              sx={{ width: '100%', justifyContent: 'space-between' }}
                            >
                              Contract details:&nbsp;
                              <Link
                                to={`https://bscscan.com/address/${Address.QUEST}`}
                                target="_blank"
                              >
                                <Text
                                  color="_accentPrimary"
                                  textDecoration="underline"
                                >
                                  {/* TODO: get it from somewhere */}
                                  {trimAddress(Address.QUEST)}
                                </Text>
                              </Link>
                            </Flex>
                          </Flex>
                          <Image src="/assets/icons/share-icon.svg" />
                        </Flex>
                        <Flex
                          p="8px"
                          bg="_backgroundTertiary"
                          fontSize="10px"
                          fontWeight="600"
                          color="textSecondary"
                          borderRadius="4px"
                        >
                          Assets: {quest?.tokens?.length}
                        </Flex>
                        {quest?.tokens?.map((token: any, idx) => (
                          <Flex
                            key={`${idx}-${token?.address}`}
                            p="8px 14px"
                            borderRadius="8px"
                            border="1px solid"
                            borderColor="_backgroundTertiary"
                            align="center"
                            justify="space-between"
                          >
                            <Flex
                              align="center"
                              gap="4px"
                            >
                              {token?.address?.slice(0, 6) + '...' + token?.address?.slice(-6)}
                            </Flex>
                            <Flex
                              gap="48px"
                              align="center"
                            >
                              <Flex
                                flexDir="column"
                                gap="2px"
                                textAlign="end"
                              >
                                <Text
                                  fontSize="12px"
                                  color="#6F6A4F"
                                >
                                  Amount
                                </Text>
                                <Text
                                  color="textQuaternary"
                                  fontSize="12px"
                                >
                                  {(token?.amount * 1).toFixed(4)}
                                </Text>
                              </Flex>
                              <Flex
                                flexDir="column"
                                gap="2px"
                                textAlign="end"
                              >
                                <Text
                                  fontSize="12px"
                                  color="#6F6A4F"
                                >
                                  Value USD
                                </Text>
                                <Text
                                  color="textQuaternary"
                                  fontSize="12px"
                                >
                                  {/* TODO: GET IT FROM SOMEWHERE */}
                                  {whitelistedTokens.includes(token.address)
                                    ? (Number(token?.amount) * 1).toFixed()
                                    : 'unknown'}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    )}
                  </Flex>
                  <Flex
                    w="100%"
                    gap="8px"
                  >
                    <Flex
                      p="6px 10px"
                      bg="backgroundMain"
                      border="1px solid"
                      borderColor="borderPrimary"
                      borderRadius="8px"
                      flexDir="column"
                      w="100%"
                      gap="4px"
                    >
                      <Text
                        fontSize="12px"
                        color="#6F6A4F"
                      >
                        Winners
                      </Text>
                      <Text
                        fontSize="14px"
                        color="_accentSecondary"
                      >
                        {quest?.winners}
                      </Text>
                    </Flex>
                    <Flex
                      p="6px 10px"
                      bg="backgroundMain"
                      border="1px solid"
                      borderColor="borderPrimary"
                      borderRadius="8px"
                      flexDir="column"
                      w="100%"
                      gap="4px"
                    >
                      <Text
                        fontSize="12px"
                        color="#6F6A4F"
                      >
                        Rewards
                      </Text>
                      <Flex
                        as="span"
                        fontSize="14px"
                        color="_accentPrimary"
                      >
                        $<Text color="_accentSecondary">{Number((tvl * 0.8).toFixed(4)) * 1}</Text>
                      </Flex>
                    </Flex>
                    <Flex
                      p="6px 10px"
                      bg="backgroundMain"
                      border="1px solid"
                      borderColor="borderPrimary"
                      borderRadius="8px"
                      flexDir="column"
                      w="100%"
                      gap="4px"
                    >
                      <Text
                        fontSize="12px"
                        color="#6F6A4F"
                      >
                        Invite 20%
                      </Text>
                      <Flex
                        as="span"
                        fontSize="14px"
                        color="_accentPrimary"
                      >
                        $<Text color="_accentSecondary">{Number((tvl * 0.2).toFixed(4)) * 1}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              )}
              <Flex
                align="center"
                justify="space-between"
                w="100%"
              >
                <Flex
                  align="center"
                  gap="16px"
                >
                  {/* /** todo: this should copy <ref> link post and save the share action
                 * count to the database for the user clicking the share action.
                 * The creator cannot trigger the db-save action. If the creator
                 * is the one creating the link, the share action is counted
                 * towards when someone foollows that link (this geets saved
                 * in the db for that user only once and so is counted only once).
                <Image src="/assets/icons/post-link-icon.svg" cursor="pointer" /> */}
                  {isImagesAvailable && (
                    <Image
                      src="/assets/icons/photo-plus.svg"
                      cursor="pointer"
                      onClick={pickFile}
                    />
                  )}
                  <Tooltip
                    hasArrow
                    label={!wallet.isLoggedIn ? 'Nie jesteś zalogowany' : ''}
                  >
                    <Button
                      mr="4px"
                      variant="none"
                      height="auto"
                      color="#000000"
                      bgColor="_accentPrimary"
                      fontSize="12px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="8px"
                      h="32px"
                      onClick={handlePost}
                      isDisabled={!wallet.isLoggedIn}
                      isLoading={post.isAssetLoading || post.isPosting}
                    >
                      {!!post.content.transfer ? 'Transferuj' : 'Publikuj'}
                    </Button>
                  </Tooltip>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
