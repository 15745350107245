import React, { useState } from 'react'
import { Divider, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { isDev } from '../../utils/config'

type IAd = 'topbar' | 'banner'

export interface IAds {
  setup: {
    open: (type: IAd) => void
    close: () => void
  }
}

export const emptyAds: IAds = {
  setup: {
    open: () => {},
    close: () => {},
  },
}

export const AdsProvider = ({ children }: { children: React.ReactNode }) => {
  // TODO: extract this into an ad hook so that it has its own dispatched reducer inside
  const [modalType, setModalType] = useState<IAd>()

  const modalController = useDisclosure()

  const handleOpenModal = (type: IAd) => {
    setModalType(type)
    modalController.onOpen()
  }
  const handleCloseModal = modalController.onClose

  return (
    <AdsContext.Provider
      value={{
        setup: {
          open: handleOpenModal,
          close: handleCloseModal,
        },
      }}
    >
      {isDev() && (
        <Modal {...modalController}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ad setup for {modalType}</ModalHeader>
            <Divider />
            <ModalBody>asdf</ModalBody>
          </ModalContent>
        </Modal>
      )}
      {children}
    </AdsContext.Provider>
  )
}

const AdsContext = React.createContext(emptyAds)

export const useAdsProvider = () => {
  const context = React.useContext(AdsContext)

  if (!context) {
    throw new Error('`useAdsProvider` cannot be used outside of a `AdsProvider`!')
  }
  return context
}
