import { useMemo, useState } from 'react'
import useAsyncEffect from '../../../../../../hooks/effects/async'
import { Flex, Link } from '@chakra-ui/layout'
import { Tooltip, Image, Text } from '@chakra-ui/react'
import { formatUnits, zeroAddress } from 'viem'
import { trimAddress, formatUsd, buildIpfsGateway } from '../../../../../../utils/parser'
import { isEqualInsensitive } from '../../../../../../utils/ts'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import { Message as IMessage } from '../../../../../../provider/Chat/types'
import { useProfilesProvider } from '../../../../../../provider/Profiles'
import { IProfile, emptyProfile } from '../../../../../../provider/Profile/profileProvider'
import { useAccountProfileProvider } from '../../../../../../provider/AccountProfile/context'
import { useEthereumProvider } from '../../../../../../provider/Ethereum/ethereumProvider'
import { useChatProvider } from '../../../../../../provider/Chat/chatProvider'
import { _log } from '../../../../../../logger'

export const Message = ({ data, rendererIndex }: { data: IMessage; rendererIndex: number }) => {
  const [profile, setProfile] = useState<IProfile>(emptyProfile)

  const wallet = useEthereumProvider()
  const account = useAccountProfileProvider()
  const chat = useChatProvider()
  const profiles = useProfilesProvider()

  const thread = useMemo(() => chat.getThreadById(chat.selectedThreadId || zeroAddress), [chat.selectedThreadId])
  const address = wallet ? wallet.account ?? '' : zeroAddress

  const isFirstInUserBatch =
    !isEqualInsensitive(data.sender, account.address!) &&
    !isEqualInsensitive(thread.messages.slice(1)[rendererIndex - 1]?.sender, data.sender)
  const isEmoji =
    [...new Intl.Segmenter().segment(data.text)].length === 1 && /\p{Extended_Pictographic}/u.test(data.text)

  useAsyncEffect(async () => {
    if (!data.sender || !isFirstInUserBatch) {
      return
    }

    setProfile(await profiles.retrieve(data.sender as address))
  }, [data.sender])

  return (
    <Flex direction="column">
      <Tooltip
        label="Zobacz w nowej karcie"
        hasArrow
      >
        <Link
          sx={{
            fontSize: '14px',
            alignSelf: 'start',
          }}
          href={`/profile/${data.sender}`}
          target="_blank"
        >
          {isFirstInUserBatch && (
            <Flex>
              {!!profile.nickname && <Text>{profile.nickname}&nbsp;</Text>}
              <Text sx={{ opacity: '.66' }}>{trimAddress(data.sender)}</Text>
              {!!profile.power && (
                <Text>
                  <Text
                    as="span"
                    sx={{ fontSize: '18px', position: 'absolute', lineHeight: '21px', transform: 'translateY(-7%)' }}
                  >
                    &nbsp;
                    {formatUsd(formatUnits(BigInt(profile.power.value), profile.power.decimals || 18))} $
                  </Text>
                </Text>
              )}
            </Flex>
          )}
        </Link>
      </Tooltip>
      <Flex
        fontSize={isEmoji ? '112px' : '14px'}
        color={address == data.sender ? 'backgroundMain' : 'textQuaternary'}
        padding={isEmoji ? '0' : '6px 8px'}
        {...(isEmoji ? { lineHeight: '128px' } : {})}
        flexDir="column"
        maxW="50%"
        borderRadius="8px"
        bg={address == data.sender ? '_accentPrimary' : '_backgroundTertiary'}
        alignSelf={address == data.sender ? 'flex-end' : 'flex-start'}
      >
        <Flex
          sx={{
            overflow: 'hidden',
            overflowWrap: 'anywhere',
          }}
        >
          {data.text}
        </Flex>
        <Flex>
          {data.assets &&
            data.assets
              .filter(({ type }) => ['image'].includes(type))
              .map((asset: any) => {
                return (
                  <Image
                    key={asset.path}
                    src={buildIpfsGateway(asset.path)}
                  />
                )
              })}
        </Flex>
      </Flex>
    </Flex>
  )
}
