import { useBreakpointValue } from '@chakra-ui/react'
import { theme } from '../../theme/theme'

const useIsMobile = (customBreakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'degen' | 'xl' | 'chat' = 'sm') => {
  const customBreakpointTranslated = customBreakpoint === 'chat' ? 'md' : customBreakpoint

  const isMobile = useBreakpointValue(
    {
      base: true,
      [customBreakpointTranslated]: false,
    },
    { ssr: false }
  )

  return isMobile
}

export default useIsMobile
