import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { buildIpfsGateway, getInitials } from '../../../../../../../../../utils/parser'
import { ButtonFollow } from '../../../../../../../../shared/actions/ButtonFollow'
import useAsyncEffect from '../../../../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../../../../utils/process'
import { fetchApi } from '../../../../../../../../../utils/fetcher'
import { IId } from '../../../../../../../../../provider/Profile/profileProvider'

export const SharedModal = ({ data, isOpen, onClose }: { data: any; isOpen: boolean; onClose: () => void }) => {
  const [showUserPopup, setShowUserPopup] = useState<`0x${string}`[] | undefined>(undefined)
  const [users, setUsers] = useState<IId[]>([])

  useAsyncEffect(async () => {
    await loaded(async () => {
      const sharers = data.map((datum: any) => datum.author)

      const res = await fetchApi(`id`, { addresses: sharers })
      setUsers(res)
    })
  }, [])

  const handleOnHover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(address)
    }, 100)
  }

  const handleOnUnhover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(undefined)
    }, 100)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
        borderRadius="16px"
      >
        <ModalHeader>
          <Text
            fontWeight="600"
            fontSize="18px"
          >
            People who shared
          </Text>
        </ModalHeader>
        <ModalCloseButton marginTop="6px">
          <Image
            src="/assets/icons/close-dark.svg"
            boxSize="16px"
          />
        </ModalCloseButton>

        <Divider orientation="horizontal" />

        <ModalBody>
          <Flex
            flexDir="column"
            gap="16px"
            fontSize="14px"
            color="_accentSecondary"
            py="24px"
          >
            {users.slice(0, 5).map((user: IId, index: number) => {
              return (
                <Flex
                  key={`${user.address}_${index}`}
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Link
                    to={`/profile/${user.address}`}
                    onMouseEnter={() => handleOnHover(user.address_)}
                    onMouseLeave={() => handleOnUnhover(user.address_)}
                  >
                    <Flex
                      gridGap="8px"
                      position="relative"
                    >
                      <Box
                        borderRadius="50%"
                        textTransform="uppercase"
                        display="flex"
                        w="40px"
                        h="40px"
                        alignItems="center"
                        justifyContent="center"
                        backgroundImage={buildIpfsGateway(user.avatar)}
                        backgroundPosition="center center"
                        backgroundRepeat="no-repeat"
                        backgroundSize="cover"
                        color="_textQuaternary"
                        bgColor="bluePlaceholder"
                        userSelect="none"
                        zIndex={1}
                      >
                        {!user.avatar && getInitials(user.name || 'Bez nazwy')}
                      </Box>
                      <Flex
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Flex
                          alignItems="center"
                          gridGap="5px"
                        >
                          <Text
                            fontSize="14px"
                            color={user.name ? 'textPrimary' : 'textTertiary'}
                            sx={{
                              maxWidth: '16ch',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {user.name || 'Bez nazwy'}
                          </Text>
                        </Flex>
                        {user.nickname && (
                          <Text
                            fontSize="12px"
                            color="textSecondary"
                            sx={{
                              maxWidth: '16ch',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {user.nickname}
                          </Text>
                        )}
                      </Flex>
                      {/* {showUserPopup === datum.address_ && (
                        <Delayed>
                          <UserPopup address={datum.address} />
                        </Delayed>
                      )} */}
                    </Flex>
                  </Link>
                  <ButtonFollow target={user.address} />
                </Flex>
              )
            })}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
