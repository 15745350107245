import {
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useGroupProvider } from '../../../../provider/Group/groupProvider'

export const LinkView = ({
  disclosure,
  parentDisclosure,
}: {
  disclosure: UseDisclosureReturn
  parentDisclosure: UseDisclosureReturn
}) => {
  const [copied, setCopied] = useState(false)

  const group = useGroupProvider()
  const linkTextReference = useRef<any>(null!)
  // FIXME: Pass through a link shortener
  const link = `${window.location.origin}/group/${group.address}`

  const handleSelectLink = () => {
    linkTextReference.current.select()
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={() => {
        parentDisclosure.onOpen()
        disclosure.onClose()
      }}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader
          color="_accentSecondary"
          fontSize="18px"
          fontWeight="600"
        >
          Invite via link
        </ModalHeader>
        <Divider />
        <ModalCloseButton mt="8px" />
        <ModalBody
          display="flex"
          flexDir="column"
          p="24px"
          gap="24px"
        >
          <Text
            fontSize="14px"
            color="textSecondary"
          >
            Copy the link below and share it to invite others to join the group.
          </Text>
          <Flex
            w="100%"
            align="center"
            gap="8px"
            onClick={handleSelectLink}
          >
            <Input
              color="textQuaternary"
              fontSize="14px"
              ref={linkTextReference}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              p="10px 12px"
              border="1px solid"
              borderColor="_borderPrimary"
              borderRadius="8px"
              h="40px"
              w="100%"
              value={link}
              readOnly
              variant="unstyled"
              outline="none !important"
            />
            <Button
              h="40px"
              fontSize="12px"
              color="backgroundMain"
              bg="_accentPrimary"
              variant="dark"
              onClick={handleCopy}
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </Flex>
        </ModalBody>
        <Divider />
        <ModalFooter
          display="flex"
          alignItems="center"
          gap="8px"
        >
          <Button
            onClick={() => {
              parentDisclosure.onOpen()
              disclosure.onClose()
            }}
            h="32px"
            fontSize="12px"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              disclosure.onClose()
            }}
            h="32px"
            fontSize="12px"
            bg="_accentPrimary"
            color="backgroundMain"
            variant="dark"
          >
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
