import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navbar } from '../navbar/navbar'
import { UserPage } from '../../../pages/User'
import { Footer } from '../footer/footer'
import Error from '../../../pages/Error'
import { ScrollToTop } from '../../shared/containers/SctollToTop/sctollToTop'
import { Flex, useColorMode, useColorModePreference } from '@chakra-ui/react'
import { Dashboard } from '../../../pages/Dashboard'
import Transactions from '../../../pages/Transactions'
import Profiles from '../../../pages/Profiles'
import Transaction from '../../../pages/Transaction'
import * as React from 'react'
import { useEffect } from 'react'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { Home } from '../../../pages/Home'
import { Chat } from '../../../pages/Chat'
import { OnboardingProvider } from '../../../provider/Onboarding/onboardingProvider'
import PostPage from '../../pages/PostPage'
import { getNetwork } from '@wagmi/core'
import { useAccount } from 'wagmi'
import MaxWidthWrapper from './maxWidthWrapper'
import { Admin } from '../../../pages/Admin'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import { Group } from '../../../pages/Group'
import { Groups } from '../../../pages/Groups'
import { ContractsProvider } from '../../../provider/Contracts/contractsProvider'
import LinkForwarder from '../../../pages/LinkForwarder'
import { Licenses } from '../../../pages/Licenses'
import useIsMobile from '../../../hooks/chakra/useIsMobile'
import { BottomNavigation } from '../bottom-navigation/bottomNavigation'
import { Dao } from '../../../pages/Dao'
import { SearchProvider } from '../../../provider/Search'
import { AdsProvider } from '../../../provider/Ads'
import { UpperBar } from '../upper-bar'

export const Wrapper = () => {
  const { setColorMode } = useColorMode()
  const wallet = useEthereumProvider()
  const accountProfile = useAccountProfileProvider()
  const { chain } = getNetwork()
  const account = useAccount()

  const privatePages = [
    {
      path: '/admin',
      element: <Admin />,
    },
  ]

  const pages = [
    {
      path: '/profile/:id',
      element: <UserPage />,
    },
    {
      path: '/group/:id?',
      element: <Group />,
    },
    {
      path: '/groups',
      element: (
        <ContractsProvider>
          <Groups />
        </ContractsProvider>
      ),
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/dao',
      element: <Dao />,
    },
    {
      path: '/txs',
      element: <Transactions />,
    },
    {
      path: `/tx/:id`,
      element: <Transaction />,
    },
    {
      path: '/profiles',
      element: <Profiles />,
    },
    {
      path: '/chat/:id?',
      element: <Chat />,
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/post/:id',
      element: <PostPage />,
    },
    {
      path: '/l/:id',
      element: <LinkForwarder />,
    },
    {
      path: '/docs/licenses',
      element: <Licenses />,
    },
    ...(accountProfile.isAdmin ? privatePages : []),
  ].map((element, index) => ({ ...element, id: `${element.path}_${index}` }))

  /** Set color-mode to dark permanently */
  useEffect(() => setColorMode('dark'), [])

  /** Render network hint */
  useEffect(() => {
    // TODO: Fix
    // if (
    //   !account.connector ||
    //   !account.isConnected ||
    //   wallet.isConnecting ||
    //   (chain && !chain.unsupported && Object.values(ENetwork).includes(chain.id))
    // ) {
    //   toast.close('error_network')
    //   return
    // }
    // if (!toast.isActive('error_network')) {
    //   toast({ duration: null, render: () => <NetworkAlert />, id: 'error_network' })
    // }
  }, [chain?.id, account.isConnected, wallet.isConnecting, account.connector])

  return (
    <Flex
      flexWrap="wrap"
      minH="100dvh"
      direction="column"
      justifyContent="flex-start"
      pb={{
        base: '50px',
        lg: '0',
      }}
    >
      <BrowserRouter>
        <OnboardingProvider>
          <AdsProvider>
            <SearchProvider>
              <UpperBar />

              <ScrollToTop>
                <MaxWidthWrapper maxW="1180px">
                  <Routes>
                    {pages.map(({ path, element, id }) => (
                      <Route
                        path={path}
                        element={element}
                        key={`${path}_${id}`}
                      />
                    ))}
                    <Route
                      path="*"
                      element={<Error />}
                      key="*"
                    />
                  </Routes>
                </MaxWidthWrapper>
              </ScrollToTop>

              <Footer />
            </SearchProvider>
          </AdsProvider>
        </OnboardingProvider>
      </BrowserRouter>
    </Flex>
  )
}
