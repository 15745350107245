import { IUseEthereum } from '../hooks/utils/useEthereum'
import { IProfile, emptyProfile } from '../provider/Profile/profileProvider'
import { fetchApi } from './fetcher'
import { loaded } from './process'

/**
 * @param wallet wallet info if should authenticate as the user fetched
 */
export const fetchProfile = async (address: address, wallet?: IUseEthereum): Promise<IProfile> => {
  if (!address) {
    return new Promise(() => {})
  }

  const data_: IProfile = await fetchApi(`profile/${address.toLowerCase()}`)
  /**
   * TODO: Make this a single query (authed /profile/:authedAddress GET
   * fetch – update frontend fetching api not to use POST when `data`
   * parameter is present already || OR just make all requests be built on POST.)
   */
  const blockData: Pick<IProfile, 'blockedUsers'> =
    wallet?.isAuthed && wallet?.account?.toLowerCase() === data_.address && data_.isOnboarded
      ? await loaded(async () => await fetchApi(`settings`, {}))
      : []

  return {
    ...emptyProfile,
    ...data_,
    blockedUsers: blockData?.blockedUsers ?? [],
    address: data_.address_?.[0] ?? address.toLowerCase(),
    address_: data_.address_?.length ? data_.address_ : [address.toLowerCase() as address],
  }
}

export const fetchProfiles = async (addresses: address[]): Promise<IProfile[]> => {
  if (!addresses.length) {
    return []
  }

  const data_: IProfile[] = await fetchApi(`profile`, { addresses })

  return data_.map((datum, enumerator) => ({
    ...emptyProfile,
    ...datum,
    address: datum.address_?.[0] ?? addresses[enumerator].toLowerCase(),
    address_: datum.address_?.length ? datum.address_ : [addresses[enumerator].toLowerCase() as address],
  }))
}
