import {
  Flex,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Popover,
  Text,
  UseDisclosureReturn,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { SearchResults } from './searchResults/SearchResults'
import { useDebounce } from '../../../hooks/useDebounce'
import useAsyncEffect from '../../../hooks/effects/async'
import { loaded } from '../../../utils/process'
import { fetchApi } from '../../../utils/fetcher'
import { useLocation, useNavigate } from 'react-router-dom'
import { emptyProfile } from '../../../provider/Profile/profileProvider'
import { isAddress } from 'viem'
import { IRoles } from '../../../types/api'
import { isTransaction } from '../../../utils/ethereum'

export interface IResults {
  tx: any[]
  profile: any[]
  contract: any[]
  group: any[]
}

export interface ITotalCounts {
  txs: number
  profile: number
  contract: number
  group: number
}

export const emptyResults: IResults = { tx: [], profile: [], contract: [], group: [] }
export const emptyCounts: ITotalCounts = { txs: 0, profile: 0, contract: 0, group: 0 }

export const SearchModal = ({
  controller,
  isAutofocusDisabled = false,
}: {
  controller: UseDisclosureReturn;
  isAutofocusDisabled?: boolean
}) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { search: searchDelegated } = (state ?? { search: '' }) as { search: string }

  const [data, setData] = useState(emptyResults)
  const [totalCounts, setTotalCounts] = useState(emptyCounts)
  const [search, setSearch] = useState('')
  const searchThrottled = useDebounce(search, 500)
  const [isLoading, setIsLoading] = useState(false)

  const ref = useRef(null!)
  const inputReference = useRef<HTMLInputElement>(null!)
  const isInputFocused = document.activeElement === inputReference.current

  const [suggestion, setSuggestion] = useState<string>('')
  const [hasSuggestion, setHasSuggestion] = useState(false)
  const [roles, setRoles] = useState<IRoles>({})

  const keyboardHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isInputFocused) {
        // TODO: Open first result
      }
    },
    [suggestion, search]
  )

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler, false)

    return () => {
      document.removeEventListener('keydown', keyboardHandler, false)
    }
  }, [keyboardHandler])

  useOutsideClick({
    ref,
    handler: controller.onClose,
  })

  const handleSearch = (e: any) => {
    setSearch(e.target.value)
    const newSuggestion = Object.values(roles)
      .flat()
      .filter((role: any) => {
        return role.name.slice(0, e.target.value.length).toLowerCase().includes(e.target.value.toLowerCase())
      })[0]
    setSuggestion(newSuggestion?.name ? `${e.target.value}${newSuggestion?.name.slice(e.target.value.length)}` : '')
    setHasSuggestion(newSuggestion !== undefined)
  }

  /** Accept incoming search values */
  useEffect(() => {
    if (!searchDelegated) {
      return
    }

    setSearch(searchDelegated)
    navigate('/dashboard', { state: {} })
  }, [searchDelegated])

  useAsyncEffect(async () => {
    if (searchThrottled.length < 2) {
      setSuggestion('')
      setData(emptyResults)
      setIsLoading(false)
      return
    }

    void loaded(
      async () => {
        const data = await fetchApi(`search/${searchThrottled}`)
        /** Display account if not fetched and pasted in */
        if (
          isAddress(searchThrottled) &&
          !data.profile
            .flatMap((element: any) => element.address_?.[0]?.toLowerCase())
            .includes(searchThrottled.toLowerCase())
        ) {
          data.profile.push({
            ...emptyProfile,
            address_: [searchThrottled.toLowerCase()],
            address: searchThrottled.toLowerCase(),
            name: 'Bez nazwy',
            _created_at: new Date(),
            _updated_at: new Date(),
            artificial: true,
          })
        }

        /** Display transaction if not fetched and pasted in */
        if (
          isTransaction(searchThrottled) &&
          !data.tx.flatMap(element => element.hash?.toLowerCase()).includes(searchThrottled.toLowerCase())
        ) {
          data.tx.push({
            hash: searchThrottled,
            confirmed: true,
            artificial: true,
          })
        }

        setData(data)
        setTotalCounts(data.totalCount)
      },
      v => {
        setIsLoading(v)
      }
    )
  }, [searchThrottled])

  /** Focus input */
  useEffect(() => {
    if (isAutofocusDisabled) {
      return
    }

    inputReference.current?.focus()
  }, [])

  /** Display sth when about to change state */
  useEffect(() => {
    if (search.length < 2) {
      setSuggestion('')
      return
    }

    if (searchThrottled.length < 2) {
      setData(emptyResults)
    }

    setIsLoading(search !== searchThrottled)
  }, [search])

  return (
    <Modal
      isOpen={controller.isOpen}
      onClose={controller.onClose}
    >
      <ModalOverlay />
      <ModalContent sx={{ width: { base: '90%', lg: '744px' }, maxWidth: { base: '90%', lg: '744px' } }}>
        <Flex
          w="100%"
          justifyContent="center"
        >
          <Flex
            borderRadius="8px"
            alignItems="center"
            bg="backgroundSecondary"
            padding="0 16px"
            border="1px solid"
            borderColor="borderPrimary"
            justifyContent="center"
            w={{ base: '90%', lg: '744px' }}
            minH="64px"
            position="relative"
            flexDir="column"
            onClick={() => inputReference.current.focus()}
            cursor="text"
          >
            <Flex
              w="100%"
              alignItems="center"
            >
              <Flex
                w="100%"
                alignItems="center"
              >
                <Image
                  src="/assets/icons/zoom.svg"
                  boxSize="16px"
                />
                {/** todo update loading state updating (spinner does
                 * not show when data still hasnt finished loading) */}
                <Input
                  ref={inputReference}
                  value={search}
                  color="_textPrimary"
                  fontSize="14px"
                  placeholder="Szukaj po adresie, hashu, bloku lub nazwie"
                  _placeholder={{ color: 'textTertiary' }}
                  border="none"
                  _focus={{ boxShadow: 'none', border: 'none !important', outline: 'none !important' }}
                  onChange={handleSearch}
                />
                <Text
                  position="absolute"
                  fontSize="14px"
                  border="none"
                  opacity="0.4"
                  ml="32px"
                  color="textSecondary"
                  transform="translateY(.4px)"
                >
                  {suggestion}
                </Text>
              </Flex>
            </Flex>
            <SearchResults
              hasSuggestions={hasSuggestion}
              ref_={ref}
              closeHandle={controller.onClose}
              data={data}
              isLoading={isLoading}
              totalCounts={totalCounts}
              search={search}
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
