import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { UserTag } from '../../../shared/tags/UserTag'
import { useIconProvider } from '../../../../provider/Icons/iconsProvider'
import { getColor } from '../../../../theme/global'

interface IInterestsAndRolesModalProps {
  isOpen: boolean
  onClose: () => void
  data: {
    roles: string[]
    interests: string[]
  }
}

export const InterestsAndRolesModal: FC<IInterestsAndRolesModalProps> = ({ isOpen, onClose, data }) => {
  const { interests, roles } = useIconProvider()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="backgroundMain"

        borderRadius="16px"
        p="0"
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            color="_accentSecondary"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
          >
            User roles & interests
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          p="24px"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          {data.interests?.length > 0 && (
            <ItemsGrid title="Interests">
              {data.interests.map((interest: string, index) => (
                <Box key={`${interest}_${index}`}>
                  <UserTag
                    compact
                    text={interest}
                    imageUri={interests.get(interest)}
                    colorBg="_backgroundTertiary"
                    colorFg="textQuaternary"
                  />
                </Box>
              ))}
            </ItemsGrid>
          )}
          {data.roles?.length > 0 && (
            <ItemsGrid title="Roles">
              {data.roles.map((role: string, index) => {
                return (
                  <Box key={`${role}_${index}`}>
                    <UserTag
                      compact
                      text={role}
                      imageUri={roles.get(role)}
                      colorBg="_backgroundTertiary"
                      colorFg="textQuaternary"
                    />
                  </Box>
                )
              })}
            </ItemsGrid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface IItemsGridProps {
  title: string
  children: ReactNode[]
}

const ItemsGrid: FC<IItemsGridProps> = ({ title, children }) => {
  return (
    <Flex
      minH="48px"
      gap="2px"
      flexDirection="column"
      flexWrap="wrap"
    >
      <Text
        color="textSecondary"
        fontWeight="500"
        fontSize="12px"
      >
        {title}
      </Text>
      <Box
        display="flex"
        alignItems="start"
        justifyContent="start"
        flexWrap="wrap"
        gap="4px"
      >
        {children}
      </Box>
    </Flex>
  )
}
