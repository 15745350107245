import { createContext, ReactNode, useContext } from 'react'
import { Address } from '../../contracts/address'
import { Abi } from '../../contracts/abi'
import { Nullable } from '../../global'
import { useWalletClient } from 'wagmi'
import { getContract, GetWalletClientResult } from '@wagmi/core'

export interface IProps {
  follow: Nullable<any>
  friend: Nullable<any>
  id: Nullable<any>
  role: Nullable<any>
  interest: Nullable<any>
  onChainRef: Nullable<any>
  quest: Nullable<any>
  groups: Nullable<any>
  groupsDeployer: Nullable<any>
  transfer: Nullable<any>
  ico: Nullable<any>
  wCdo: Nullable<any>
  walletClient?: GetWalletClientResult
}

const emptyProps: IProps = {
  follow: null as unknown as any,
  friend: null as unknown as any,
  id: null as unknown as any,
  role: null as unknown as any,
  interest: null as unknown as any,
  onChainRef: null as unknown as any,
  quest: null as unknown as any,
  groups: null as unknown as any,
  groupsDeployer: null as unknown as any,
  transfer: null as unknown as any,
  ico: null as unknown as any,
  wCdo: null as unknown as any,
  walletClient: null as unknown as any,
}

export const ContractsProvider = ({ children }: { children: ReactNode }) => {
  const walletClient_ = useWalletClient()
  const walletClient = walletClient_.data!

  const degenFollowContract = getContract({
    abi: Abi.FOLLOW,
    address: Address.FOLLOW,
    walletClient,
  })
  const degenFriendContract = getContract({
    abi: Abi.FRIEND,
    address: Address.FRIEND,
    walletClient,
  })
  const degenIdContract = getContract({
    abi: Abi.ID,
    address: Address.ID,
    walletClient,
  })
  const degenRoleContract = getContract({
    abi: Abi.ROLE,
    address: Address.ROLE,
    walletClient,
  })
  const degenInterestContract = getContract({
    abi: Abi.INTEREST,
    address: Address.INTEREST,
    walletClient,
  })
  const degenOnChainRefContract = getContract({
    abi: Abi.ON_CHAIN_REF,
    address: Address.ON_CHAIN_REF,
    walletClient,
  })

  const degenQuestContract = getContract({
    abi: Abi.QUEST,
    address: Address.QUEST,
    walletClient,
  })
  const degenGroupsContract = getContract({
    abi: Abi.GROUPS,
    address: Address.GROUPS,
    walletClient,
  })
  const groupsDeployerContract = getContract({
    abi: Abi.GROUPS_DEPLOYER,
    address: Address.GROUPS_DEPLOYER,
    walletClient,
  })
  const degenTransferContract = getContract({
    abi: Abi.TRANSFER,
    address: Address.TRANSFER,
    walletClient,
  })

  const degenIcoContract = getContract({
    abi: Abi.ICO,
    address: Address.ICO,
    walletClient,
  })

  const degenWCdoContract = getContract({
    abi: Abi.W_CDO,
    address: Address.W_CDO,
    walletClient,
  })

  return (
    <ContractsContext.Provider
      value={{
        follow: degenFollowContract,
        friend: degenFriendContract,
        id: degenIdContract,
        role: degenRoleContract,
        interest: degenInterestContract,
        onChainRef: degenOnChainRefContract,
        quest: degenQuestContract,
        groups: degenGroupsContract,
        groupsDeployer: groupsDeployerContract,
        transfer: degenTransferContract,
        ico: degenIcoContract,
        wCdo: degenWCdoContract,
        walletClient,
      }}
    >
      {children}
    </ContractsContext.Provider>
  )
}

const ContractsContext = createContext<IProps>(emptyProps)

export const useContractsProvider = () => {
  const context = useContext(ContractsContext)

  if (!context) {
    throw new Error('`useContractsProvider` cannot be used outside of a `ContractsProvider`!')
  }
  return context
}
