import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  UseDisclosureReturn,
  useToast,
} from '@chakra-ui/react'
import { FC, useCallback, useRef, useState } from 'react'
import { useGroupProvider } from '../../../provider/Group/groupProvider'
import { _log } from '../../../logger'
import { useDropzone } from 'react-dropzone'
import { useContractsProvider } from '../../../provider/Contracts/contractsProvider'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { loaded } from '../../../utils/process'
import { waitForTransaction } from '@wagmi/core'
import getCroppedImg from '../../../utils/getCroppedImg'
import { fetchApi } from '../../../utils/fetcher'
import Cropper from 'react-easy-crop'
import { buildIpfsGateway } from '../../../utils/parser'
import { UserAvatar } from '../../shared/avatars/UserAvatar'

type EditGroupModalProps = {
  disclosure: UseDisclosureReturn
}

export const EditGroupModal: FC<EditGroupModalProps> = ({ disclosure }) => {
  const group = useGroupProvider()
  const contracts = useContractsProvider()
  const wallet = useEthereumProvider()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenBg, onOpen: onOpenBg, onClose: onCloseBg } = useDisclosure()

  const [tmpAvatar, setTmpAvatar] = useState<string | ArrayBuffer | null | any>(group?.metadata.image)
  const [tmpBackground, setTmpBackground] = useState<string | ArrayBuffer | null | any>(group?.metadata.background)

  const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false)
  const [isBackgroundLoading, setIsBackgroundLoading] = useState<boolean>(false)

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropBg, setCropBg] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [rotationBg, setRotationBg] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [zoomBg, setZoomBg] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedAreaPixelsBg, setCroppedAreaPixelsBg] = useState(null)
  const [_croppedAvatar, setCroppedAvatar] = useState<any>(null)
  const [_croppedBackground, setCroppedBackground] = useState(null)

  const dropzoneAvatarRef = useRef(null!)
  const dropzoneBackgroundRef = useRef(null!)

  const avatarDropzone = useDropzone({
    onDrop: acceptedFiles => {
      const reader = new FileReader()
      reader.onload = async () => {
        setIsAvatarLoading(true)
        const binary = reader.result
        onOpen()

        setTmpAvatar(binary)
        setIsAvatarLoading(false)
      }
      reader.readAsDataURL(acceptedFiles[0])
    },
  })

  const backgroundDropzone = useDropzone({
    onDrop: acceptedFiles => {
      const reader = new FileReader()
      reader.onload = async () => {
        setIsBackgroundLoading(true)
        const binary = reader.result
        onOpenBg()

        setTmpBackground(binary)
        setIsBackgroundLoading(false)
      }
      reader.readAsDataURL(acceptedFiles[0])
    },
  })

  const handlePicker = (zone: any) => {
    if (zone.current) {
      // @ts-ignore
      zone.current.click()
    }
  }

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onCropCompleteBg = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixelsBg(croppedAreaPixels)
  }, [])

  const showCroppedAvatar = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(tmpAvatar, croppedAreaPixels, rotation)
      setTmpAvatar(await croppedImage?.blob)

      return croppedImage?.base64
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const showCroppedBackground = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(tmpBackground, croppedAreaPixelsBg, rotationBg)
      setTmpBackground(await croppedImage?.blob)

      return croppedImage?.base64
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixelsBg, rotationBg])

  async function close() {
    onClose()

    const croppedAvatar = await showCroppedAvatar()
    setCrop({
      x: 0,
      y: 0,
    })
    setZoom(1)

    const b64 = await fetch(croppedAvatar as any).then(res => res.blob())

    const file = new File([b64], 'avatar.png')
    const formData = new FormData()
    formData.append('file', file)

    const result = await loaded(
      async () =>
        (
          await fetchApi('post/uploadAsset', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        ).path,
      setIsAvatarLoading,
      () => {
        toast({ status: 'error', title: 'Error while uploading avatar image' })
      }
    )

    setImage(result)
  }

  async function closeBg() {
    onCloseBg()

    const croppedBg = await showCroppedBackground()
    setCropBg({
      x: 0,
      y: 0,
    })
    setZoomBg(1)

    const b64 = await fetch(croppedBg as any).then(res => res.blob())

    const file = new File([b64], 'background.png')
    const formData = new FormData()
    formData.append('file', file)

    const result = await loaded(
      async () =>
        (
          await fetchApi('post/uploadAsset', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        ).path,
      setIsBackgroundLoading,
      () => {
        toast({ status: 'error', title: 'Error while uploading background image' })
      }
    )

    setBgImage(result)
  }

  const [image, setImage] = useState<string | ArrayBuffer | null | any>(group?.metadata.image)
  const [bgImage, setBgImage] = useState<string | ArrayBuffer | null | any>(group?.metadata.background)

  const [name, setName] = useState(group.metadata.name)
  const [description, setDescription] = useState(group.metadata.description)

  const [isUploadingLoading, setIsUploadingLoading] = useState(false)

  const uploadChanges = async () => {
    if (!wallet.account) {
      toast({ status: 'error', title: 'Connect wallet first' })
      return
    }
    await loaded(
      async () => {
        const calldata = [group.address, [0, 1, 2, 3], [name, description, image, bgImage]]
        await loaded(
          async () => {
            await contracts.groups?.estimateGas.setMultipleGroupData(calldata)
          },
          undefined,
          e => {
            _log(e)
            toast({ status: 'error', title: 'Error while transacting new group data' })
            throw new Error('Transaction error')
          }
        )
        const hash = await contracts.groups?.write.setMultipleGroupData(calldata)
        await waitForTransaction({ hash })

        /* update the group page state to the values just set */
        group.setData(previous => ({ ...previous, metadata: { name, description, image, background: bgImage } }))

        toast({ title: 'Group edited!' })
        disclosure.onClose()
      },
      setIsUploadingLoading,
      error => {
        if (!error.shortMessage) {
          return
        }
        toast({ status: 'error', title: error.shortMessage })
      }
    )
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          borderBottom="1px solid borderPrimary"
          fontSize="18px"
        >
          <Flex
            w="100%"
            justify="space-between"
            align="center"
          >
            <Text>Edit Group</Text>
            <Flex
              align="center"
              gap="8px"
              fontSize="12px"
            >
              <Button
                onClick={disclosure.onClose}
                h="32px"
              >
                Cancel
              </Button>
              <Tooltip label={isAvatarLoading || isBackgroundLoading ? 'Waiting for IPFS…' : ''}>
                <Button
                  onClick={uploadChanges}
                  isLoading={isUploadingLoading || isAvatarLoading || isBackgroundLoading}
                  bg="_accentPrimary"
                  h="32px"
                  color="backgroundMain"
                  border="none"
                >
                  Save
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody
          p="0"
          m="0"
        >
          <Flex flexDir="column">
            <Modal
              isOpen={isOpen}
              onClose={close}
              size="full"
            >
              <ModalOverlay />
              <ModalContent
                mx="10px"
                marginBottom={{ base: '10px', md: '64px' }}
              >
                <ModalHeader>Crop your avatar</ModalHeader>
                <ModalCloseButton />
                <ModalBody
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDir="column"
                >
                  <Flex
                    maxHeight="600px"
                    position="relative"
                    height="600px"
                    width="100%"
                  >
                    <Cropper
                      image={tmpAvatar}
                      crop={crop}
                      zoom={zoom}
                      aspect={4 / 4}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </Flex>
                  <Button
                    onClick={close}
                    width="100%"
                    mt="1rem"
                  >
                    Crop image
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal
              isOpen={isOpenBg}
              onClose={closeBg}
              size="full"
            >
              <ModalOverlay />
              <ModalContent
                mx="10px"
                marginBottom={{ base: '10px', md: '64px' }}
              >
                <ModalHeader>Crop your background image</ModalHeader>
                <ModalCloseButton />
                <ModalBody
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDir="column"
                >
                  <Flex
                    maxHeight="600px"
                    position="relative"
                    height="600px"
                    width="100%"
                  >
                    <Cropper
                      image={tmpBackground}
                      crop={cropBg}
                      zoom={zoomBg}
                      aspect={16 / 3}
                      onCropChange={setCropBg}
                      onCropComplete={onCropCompleteBg}
                      onZoomChange={setZoomBg}
                    />
                  </Flex>
                  <Button
                    onClick={closeBg}
                    width="100%"
                    mt="1rem"
                  >
                    Crop image
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
            <Flex
              {...backgroundDropzone.getRootProps({ onClick: event => event.stopPropagation() })}
              bg={tmpBackground !== undefined ? 'transparent' : '#DCF2EA'}
              backgroundImage={tmpBackground !== '' ? `url(${buildIpfsGateway(tmpBackground)})` : 'none'}
              // backgroundImage={croppedBackground ? `url(${croppedBackground})` : 'none'}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              bgSize="cover"
              h="200px"
              w="100%"
              align="center"
              justify="center"
              position="relative"
              gap="8px"
            >
              <input
                {
                  /* @ts-ignore */
                  ...backgroundDropzone.getInputProps({ ref: dropzoneBackgroundRef })
                }
                onClick={event => event.stopPropagation()}
              />

              {!isBackgroundLoading ? (
                <>
                  <Button
                    variant="slave"
                    borderRadius="50%"
                    bgColor="rgba(0, 0, 0, 0.4)"
                    h="32px"
                    w="32px"
                    justifyContent="center"
                    alignContent="center"
                    onClick={() => handlePicker(dropzoneBackgroundRef)}
                  >
                    <Image src="/assets/icons/camera-plus.svg" />
                  </Button>
                  <Button
                    variant="slave"
                    borderRadius="50%"
                    bgColor="rgba(0, 0, 0, 0.4)"
                    h="32px"
                    w="32px"
                    justifyContent="center"
                    alignContent="center"
                    onClick={() => {
                      setBgImage('')
                      setCroppedBackground(null)
                      setTmpBackground('')
                    }}
                  >
                    <Image src="/assets/icons/close.svg" />
                  </Button>
                </>
              ) : (
                <Spinner size="lg" />
              )}
              <Flex
                {...avatarDropzone.getRootProps({ onClick: event => event.stopPropagation() })}
                position="absolute"
                transform="translate(0, 50%)"
                bottom="0"
                left="23px"
              >
                <UserAvatar
                  size="96px"
                  bgColor="backgroundMain"
                  image={tmpAvatar !== '' ? tmpAvatar : undefined}
                >
                  {!isAvatarLoading || tmpAvatar === '' ? (
                    <Button
                      variant="slave"
                      borderRadius="50%"
                      bgColor="rgba(0, 0, 0, 0.4)"
                      h="32px"
                      w="32px"
                      justifyContent="center"
                      alignContent="center"
                      onClick={() => handlePicker(dropzoneAvatarRef)}
                    >
                      <Image src="/assets/icons/camera-plus.svg" />
                    </Button>
                  ) : (
                    <Spinner size="lg" />
                  )}

                  <input
                    {
                      /* @ts-ignore */
                      ...avatarDropzone.getInputProps({ ref: dropzoneAvatarRef })
                    }
                    onClick={event => event.stopPropagation()}
                  />
                </UserAvatar>
              </Flex>
            </Flex>
            <Flex
              p="32px 24px"
              mt="32px"
              flexDir="column"
              gap="24px"
            >
              <Flex
                flexDir="column"
                gap="8px"
                w="100%"
              >
                <Text
                  fontWeight="500"
                  color="_accentSecondary"
                  fontSize="14px"
                >
                  Name
                </Text>
                <Input
                  color="textQuaternary"
                  borderColor="_borderPrimary"
                  _hover={{ borderColor: '_borderPrimary' }}
                  onChange={(e: any) => setName(e.target.value)}
                  defaultValue={group.metadata.name}
                />
              </Flex>
              <Flex
                flexDir="column"
                gap="8px"
                w="100%"
              >
                <Text
                  fontWeight="500"
                  color="_accentSecondary"
                  fontSize="14px"
                >
                  Description
                </Text>
                <Text
                  color="textSecondary"
                  fontSize="14px"
                >
                  Define your group with a description that reflects its identity and mission
                </Text>
                <Textarea
                  color="textQuaternary"
                  resize="none"
                  borderColor="_borderPrimary"
                  _hover={{ borderColor: '_borderPrimary' }}
                  onChange={(e: any) => setDescription(e.target.value)}
                  defaultValue={group.metadata.description}
                />
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
