import { Box, Flex, Image } from '@chakra-ui/react'

export const NoMessages = () => {
  return (
    <Flex
      my="60px"
      gap="6px"
      align="center"
      flexDir="column"
    >
      <Image
        w="30px"
        src="/assets/icons/no-threads.svg"
      />
      <Box
        color="_textPrimary"
        fontSize="20px"
      >
        There are no messages.
      </Box>
      <Box
        fontSize="14px"
        color="textSecondary"
        textAlign="center"
        maxW="400px"
      >
        This token thread does not have any messages yet. Be the first one to start the conversation!
      </Box>
    </Flex>
  )
}
