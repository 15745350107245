export interface IRoute {
  label: string
  href: string
  target?: string
}

const routes: IRoute[] = [
  { label: 'Główna', href: '/' },
  { label: 'Ranking', href: '/profiles' },
  { label: 'Grupy', href: '/groups' },
  { label: 'DAO', href: '/dao' },
  { label: 'Transakcje', href: '/txs' },
  { label: 'PUMP', href: '/chat' },
]

const adminRoutes: IRoute[] = []

export { routes, adminRoutes }
