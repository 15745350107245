import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { useEthereum } from '../../../../../hooks/utils/useEthereum'
import { AddressZero } from '../../../../../utils/ethereum'

export const OnboardInfo = () => {
  const wallet = useEthereum()

  return (
    <Flex
      mx={{ base: '16px', md: 'unset' }}
      p="12px 16px"
      bg="_accentPrimary0D"
      border="1px solid"
      borderColor="_accentPrimary"
      borderRadius="16px"
      color="textQuaternary"
      fontSize="14px"
      fontWeight="500"
      gap="16px"
      mb="16px"
      align="center"
      minH="58px"
    >
      <Image src="/assets/icons/userRounded.svg" />
      <Flex
        as="span"
        display="inline-block"
      >
        Ustaw swój profil.&nbsp;
        <Box
          as="span"
          display="inline-block"
        >
          <Link
            color="_accentPrimary"
            to={`/profile/${wallet.account ?? AddressZero}`}
          >
            <Text
              textDecoration="underline"
              color="_accentPrimary"
            >
              Kliknij, by zrobić to teraz
            </Text>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}
