import { Box, Flex, Grid, Tooltip } from '@chakra-ui/react'
import { ChatWindow } from '../components/pages/Chat/ChatWindow'
import { useChatProvider } from '../provider/Chat/chatProvider'
import { useLocation, useParams } from 'react-router'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'
import { Search } from '../components/pages/Chat/Search'
import { useEffect, useMemo, useState } from 'react'
import { zeroAddress } from 'viem'
import { base } from 'viem/chains'
import useIsMobile from '../hooks/chakra/useIsMobile'
import { isMobile } from 'react-device-detect'
import { Swap } from '../components/pages/Chat/Swap'
import { Graph } from '../components/pages/Chat/Graph'
import { isDev } from '../utils/config'
import { SwapProvider } from '../lib/dex-overlay/components/SwapProvider'
import { SwapWindow } from '../components/pages/Chat/SwapWindow'
import { PumpWindow } from '../components/pages/Chat/PumpWindow'

export const Chat = () => {
  const [isPump, setIsPump] = useState(true)

  const chat = useChatProvider()
  const wallet = useEthereumProvider()
  const { id: thread_ } = useParams()
  const thread = useMemo(() => (['0', '0x0', '', undefined].includes(thread_) ? zeroAddress : thread_), [thread_])
  const isMobile = useIsMobile('chat')

  /* Load thread from url */
  useEffect(() => {
    if (chat.isLoading) {
      return
    }

    chat.openThread(thread)
  }, [thread, chat.isLoading])

  return (
    <Box
      sx={{
        width: '100%',
        h: { base: 'calc(100dvh - 50px - 54px)', sm: 'calc(100dvh - 50px - 54px - 42px)', md: '100dvh' },
        overflowY: 'scroll',
        scrollSnapType: 'y mandatory',
        ...(!isMobile ? { display: 'flex', gap: '24px', h: 'unset' } : {}),
      }}
    >
      {(wallet.isLoggedIn || isDev()) && (
        <>
          <ChatWindow />
          {isPump ? <PumpWindow handleHide={() => setIsPump(false)} /> : <SwapWindow />}
        </>
      )}
      {!wallet.isLoggedIn && !isDev() && (
        <Flex
          align="center"
          justify="center"
          padding="0px 60px"
          flexDir="column"
          sx={{
            mt: {
              base: '10vh',
              md: 'unset',
            },
            w: '100%',
          }}
        >
          {/* TODO: Make this not required, but an additional feature (so that anonymous chatting (i.e. without a user address specified) is still not allowed.) */}
          Zaloguj się, by użyć Degen PUMP
        </Flex>
      )}
    </Box>
  )
}
