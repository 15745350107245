import { Button, Flex, Image, Input, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useGroupProvider } from '../../../../../provider/Group/groupProvider'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import { isProduction } from '../../../../../utils/isProduction'
import { buildIpfsGateway, formatUsd } from '../../../../../utils/parser'
import { redirect } from '../../../../../utils/redirector'
import { Abi } from '../../../../../contracts/abi'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { readContract, waitForTransaction, writeContract } from '@wagmi/core'
import { loaded } from '../../../../../utils/process'
import { useContractsProvider } from '../../../../../provider/Contracts/contractsProvider'
import { _log } from '../../../../../logger'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { Address } from '../../../../../contracts/address'
import { formatUnits, getContract, isAddress, parseUnits } from 'viem'
import { useWalletClient } from 'wagmi'
import { splitByIndex } from '../../../../../utils/ts'

enum EBuy {
  IDLE,
  APPROVE,
  BUY,
}

enum ESell {
  IDLE,
  APPROVE,
  SELL,
}

export const GroupDetails = () => {
  const [isSc20, setIsSc20] = React.useState<boolean>()
  const [isBuying, setIsBuying] = React.useState(false)
  const [isSelling, setIsSelling] = React.useState(false)
  const [sc20PriceRaw, setSc20PriceRaw] = React.useState<BigInt>()
  const [sc20Price, setSc20Price] = React.useState('')
  const [buyState, setBuyState] = React.useState(EBuy.IDLE)
  const [sellState, setSellState] = React.useState(ESell.IDLE)
  const [isUpdatingPaymentToken, setIsUpdatingPaymentToken] = React.useState(false)
  const [newPaymentToken, setNewPaymentToken] = React.useState('')
  const [currentPaymentToken, setCurrentPaymentToken] = React.useState<string>()
  const [isPriceDetailsVisible, setIsPriceDetailsVisible] = React.useState(false)

  const data = useGroupProvider()
  const toast = useToast()
  const contracts = useContractsProvider()
  const wallet = useEthereumProvider()

  const walletClient_ = useWalletClient()
  const walletClient = walletClient_.data!

  const handleUpdatePriceGui = async () =>
    await loaded(async () => {
      const priceWei = (await readContract({
        abi: Abi.SC20,
        address: data.address,
        functionName: 'price',
      })) as string

      setSc20PriceRaw(BigInt(priceWei || 0))
      setSc20Price(formatUnits(BigInt(priceWei || 0), 18))
    })

  /* When token contract is not from a bonding curve, redirect to pancakeswap. */
  const handleBuy = async () => {
    if (!isSc20) {
      redirect(
        `https://pancakeswap.finance/swap?outputCurrency=${data.address}&chain=${isProduction() ? 'bsc' : 'bscTestnet'}`
      )

      return
    }

    await loaded(
      async () => {
        const amount = parseUnits(prompt('[USD]: Amount') || '0', 18 /* BSC stablecoin' decimals */)
        const st20 = data.address

        if (!amount) {
          return
        }

        setBuyState(EBuy.APPROVE)
        const allowance = BigInt(
          (await readContract({
            abi: Abi.ERC20,
            address: (currentPaymentToken as address) || Address.BUSD,
            functionName: 'allowance',
            args: [wallet.account, st20],
          })) as number
        )

        if (BigInt(allowance) < BigInt(amount)) {
          const contract = getContract({
            abi: Abi.ERC20,
            address: (currentPaymentToken as address) || Address.BUSD,
            walletClient,
          })

          const tx = await contract.write?.approve([st20, amount])

          await waitForTransaction({ hash: tx })
          _log(`Approved ${formatUnits(amount, 18)} [payment token] for ${st20} purchase`)
        }

        setBuyState(EBuy.BUY)
        const { hash } = await writeContract({
          abi: Abi.SC20,
          address: st20,
          functionName: 'buyTokens',
          args: [amount],
        })

        await waitForTransaction({ hash })
        toast({ status: 'success', title: 'Bought!' })

        void handleUpdatePriceGui()
      },
      setIsBuying,
      error => toast({ status: 'error', title: 'Purchase cancelled', description: error?.shortMessage })
    )
  }

  const handleUpdatePaymentToken = async () => {
    await loaded(
      async () => {
        const { hash } = await writeContract({
          abi: Abi.SC20,
          address: data.address,
          functionName: 'updatePaymentToken',
          args: [newPaymentToken],
        })

        await waitForTransaction({ hash })
        toast({ status: 'success', title: 'Updated' })

        setNewPaymentToken('')
        void handleUpdateCurrentPaymentToken()
      },
      setIsUpdatingPaymentToken,
      error =>
        toast({
          status: 'error',
          title: 'Updating the payment token was cancelled',
          description: error?.cause?.reason || 'This group does not support updating its payment address',
        })
    )
  }

  /**
   * @dev przy sprzedawaniu trzeba dać approve w kontrakcie SC20 (czyli adresie grupy) kontraktowi BUSD/token_platniczy (testnet) na sprzedawaną liczbe tokenów.
   */
  const handleSell = async () => {
    await loaded(
      async () => {
        const amount = parseUnits(prompt('[ST20]: Amount') || '0', 18 /* BSC payment token (any stablecoin) decimals */)
        const st20 = data.address

        if (!amount) {
          return
        }

        // Approve on ST20 contract to BUSD/paymenttoken amount of ST20
        setSellState(ESell.APPROVE)
        const allowance = BigInt(
          (await readContract({
            abi: Abi.SC20 /* this call is actually the same as of the ERC20 interface */,
            address: st20,
            functionName: 'allowance',
            args: [wallet.account, st20],
          })) as number
        )

        if (BigInt(allowance) < BigInt(amount)) {
          const contract = getContract({
            abi: Abi.SC20,
            address: st20,
            walletClient,
          })

          const tx = await contract.write?.approve([st20, amount])

          await waitForTransaction({ hash: tx })
          _log(`Approved ${formatUnits(amount, 18)} ST20 for ${st20} sale`)
        }

        setSellState(ESell.SELL)
        const { hash } = await writeContract({
          abi: Abi.SC20,
          address: data.address,
          functionName: 'sellTokens',
          args: [amount],
        })

        await waitForTransaction({ hash })
        toast({ status: 'success', title: 'Sold!' })

        void handleUpdatePriceGui()
      },
      setIsSelling,
      error => toast({ status: 'error', title: 'Sell cancelled', description: error?.shortMessage })
    )
  }

  const handleUpdateCurrentPaymentToken = async () => {
    await loaded(async () => {
      setCurrentPaymentToken(undefined)

      const paymentToken = (await readContract({
        abi: Abi.SC20,
        address: data.address,
        functionName: 'paymentTokenUSDBSC',
      })) as string | undefined

      setCurrentPaymentToken(paymentToken)
    })
  }

  /* Handle handleBuy events */
  useEffect(() => {
    toast.close(`purchase_status_${EBuy.APPROVE}`)
    toast.close(`purchase_status_${EBuy.BUY}`)

    if (!isBuying || buyState === EBuy.IDLE) {
      setBuyState(EBuy.IDLE)

      return
    }

    const description = {
      [EBuy.APPROVE]: 'Approving',
      [EBuy.BUY]: 'Buying',
    }

    toast({
      title: 'Purchase',
      description: `${description[buyState]}…`,
      status: 'loading',
      id: `purchase_status_${buyState}`,
      duration: null,
    })
  }, [buyState, isBuying])

  /* Handle handleSell events */
  useEffect(() => {
    toast.close(`sale_status_${ESell.APPROVE}`)
    toast.close(`sale_status_${ESell.SELL}`)

    if (!isSelling || sellState === ESell.IDLE) {
      setSellState(ESell.IDLE)

      return
    }

    const description = {
      [ESell.APPROVE]: 'Approving',
      [ESell.SELL]: 'Selling',
    }

    toast({
      title: 'Sale',
      description: `${description[sellState]}…`,
      status: 'loading',
      id: `sale_status_${sellState}`,
      duration: null,
    })
  }, [sellState, isSelling])

  /* Handle SC20 check by checking if the token has buyBalance */
  useAsyncEffect(async () => {
    if (!window.ethereum) {
      return
    }

    try {
      await readContract({
        abi: Abi.SC20,
        address: data.address,
        functionName: 'buyBalance',
      })

      setIsSc20(true)
    } catch {
      setIsSc20(false)
    }
  }, [wallet.account])

  /* Handle SC20 token price update – on page load and buy/sell transactions */
  useAsyncEffect(async () => {
    if (!isSc20 || isBuying || isSelling) {
      setSc20Price('')
      return
    }

    await handleUpdatePriceGui()
  }, [isSc20, isBuying, isSelling])

  /* Handle current payment token updates */
  useAsyncEffect(async () => {
    if (!isSc20) {
      return
    }

    handleUpdateCurrentPaymentToken()
  }, [isSc20])

  return (
    <Flex
      flexDir="column"
      bg="backgroundMain"
      sx={{
        borderRadius: '16px',
      }}
    >
      <Flex
        borderRadius="16px 16px 0 0"
        border="1px solid"
        borderColor="borderPrimary"
        p="16px"
        flexDir="column"
        gap="16px"
      >
        <Text
          fontSize="16px"
          fontWeight="600"
          color="_accentSecondary"
        >
          Group details
        </Text>
        <Flex
          w="100%"
          align="center"
          justify="space-between"
        >
          <Text
            fontSize="14px"
            color="textQuaternary"
          >
            TVL
          </Text>
          <Flex align="center">
            ${data.details?.tvl || data?.details?.tvl === 0 ? formatUsd(data.details?.tvl) : <SpinnerText />}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        borderLeft="1px solid"
        borderRight="1px solid"
        borderColor="borderPrimary"
        p="16px"
        flexDir="column"
        gap="2px"
      >
        <Flex
          w="100%"
          align="center"
          justify="space-between"
        >
          <Text
            fontSize="14px"
            color="textQuaternary"
          >
            VOL (24H)
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="textQuaternary"
          >
            ${data.details?.volume || data?.details?.volume === 0 ? formatUsd(data.details?.volume) : <SpinnerText />}
          </Text>
        </Flex>
        <Flex
          w="100%"
          align="center"
          justify="space-between"
        >
          <Text
            fontSize="14px"
            color="textQuaternary"
          >
            Market cap
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="textQuaternary"
          >
            $
            {data.details?.marketCap || data?.details?.marketCap === 0 ? (
              formatUsd(data.details?.marketCap)
            ) : (
              <SpinnerText />
            )}
          </Text>
        </Flex>
        <Flex
          w="100%"
          align="center"
          justify="space-between"
        >
          <Text
            fontSize="14px"
            color="textQuaternary"
          >
            Supply
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="textQuaternary"
          >
            {data.details?.supply?.toLocaleString('en-US') ?? <SpinnerText />}
          </Text>
        </Flex>
        <Flex
          w="100%"
          align="center"
          justify="space-between"
        >
          <Text
            fontSize="14px"
            color="textQuaternary"
          >
            Type
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="textQuaternary"
          >
            {data.details?.type || 'BEP20'}
          </Text>
        </Flex>
      </Flex>
      <Flex
        p="16px"
        borderRadius="0 0 16px 16px"
        border="1px solid"
        borderColor="borderPrimary"
        flexDir="column"
        gap="8px"
      >
        <Flex
          gap="6px"
          align="center"
        >
          <Image
            src={buildIpfsGateway(data.metadata.image)}
            fallback={
              <Image
                src="/assets/icons/bnbIcon.svg"
                border="1px solid"
                borderColor="borderPrimary"
                borderRadius="13px"
                sx={{ boxSize: '40px', p: '8px' }}
              />
            }
            borderRadius="50%"
            boxSize="40px"
          />
          <Flex flexDir="column">
            <Flex
              align="center"
              gap="8px"
              alignItems="baseline"
            >
              <Text
                color="textQuaternary"
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
              >
                {data.metadata.name ?? ''}
              </Text>
              <Text
                color="#6F6A4F"
                fontSize="10px"
                lineHeight="16px"
              >
                {data.symbol ?? ''}
              </Text>
            </Flex>
            <Text
              sx={{ fontWeight: '600', cursor: 'default', userSelect: 'none' }}
              onClick={() => setIsPriceDetailsVisible(previous => !previous)}
            >
              {/* If token is SC20, show the price here. */}$
              {isSc20 ? (
                sc20Price ? (
                  <Tooltip
                    hasArrow
                    label={`$${splitByIndex(sc20PriceRaw?.toString(), -18)} [wei]`}
                  >
                    {formatUsd(sc20Price)}
                  </Tooltip>
                ) : (
                  <SpinnerText />
                )
              ) : data.details?.price ? (
                formatUsd(data.details?.price)
              ) : (
                <SpinnerText />
              )}
            </Text>
          </Flex>
        </Flex>
        {isPriceDetailsVisible && <Text sx={{ cursor: 'auto' }}>${splitByIndex(sc20PriceRaw?.toString(), -18)}</Text>}
        <Tooltip
          hasArrow
          label={!wallet.account ? 'Connect your wallet first' : ''}
        >
          <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
            {/* When token contract is not from a bonding curve, dont show this button. */}
            {isSc20 && (
              <>
                <Tooltip
                  label="Enter new payment token address"
                  hasArrow
                >
                  <Input
                    placeholder="address"
                    value={newPaymentToken}
                    onChange={e => setNewPaymentToken(e.target.value)}
                  />
                </Tooltip>
                <Button
                  bg="_accentPrimary"
                  color="textWhite"
                  border="none"
                  onClick={handleUpdatePaymentToken}
                  isLoading={isUpdatingPaymentToken}
                  isDisabled={!wallet.account || !isAddress(newPaymentToken)}
                >
                  Set payment token
                </Button>
                <Text sx={{ fontWeight: 'bolder', fontSize: 'smaller', color: 'textSecondary' }}>
                  Current payment token
                </Text>
                {!currentPaymentToken ? (
                  <Spinner />
                ) : (
                  <Text sx={{ color: 'textSecondary', fontSize: 'smaller', mt: '-8px' }}>
                    {currentPaymentToken}
                    {currentPaymentToken.toLowerCase() === '0x86955396E0ddE6E12254a24b8773301dc863D989'.toLowerCase() ||
                    currentPaymentToken.toLowerCase() === '0x31e60B1B246331Cf630c16EB90bFC4B4A7735Fc2'.toLowerCase()
                      ? ' (CUSD)'
                      : ''}
                  </Text>
                )}
              </>
            )}
            <Flex sx={{ gap: '8px', flexGrow: 1 }}>
              <Button
                bg="_accentPrimary"
                color="textWhite"
                border="none"
                flexGrow="1"
                onClick={handleBuy}
                isLoading={(isSc20 === undefined || isBuying) && !!wallet.account}
                isDisabled={!wallet.account}
              >
                Buy token
              </Button>
              {/* When token contract is not from a bonding curve, dont show this button. */}
              {isSc20 && (
                <Button
                  bg="_accentPrimary"
                  color="textWhite"
                  border="none"
                  onClick={handleSell}
                  isLoading={isSelling}
                  isDisabled={!wallet.account}
                >
                  Sell
                </Button>
              )}
            </Flex>
          </Flex>
        </Tooltip>
      </Flex>
    </Flex>
  )
}
