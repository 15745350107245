import { Button, Flex, FormLabel, Input, InputGroup, Textarea, Tooltip, Image } from '@chakra-ui/react'
import { Token } from './Token'
import { zeroAddress } from 'viem'
import { DirectionSwitcher } from './DirectionSwitcher'
import { ArrowUpDownIcon } from '@chakra-ui/icons'
import { EMode, useSwapProvider } from '../../../../lib/dex-overlay/components/SwapProvider'

export const Swap = () => {
  const swap = useSwapProvider()

  return (
    <Flex
      direction="column"
      sx={{
        flexGrow: 1,
        m: '8px',
        gap: '8px',
        height: '100%',
      }}
    >
      <Token token={swap.tokens.external} />
      <DirectionSwitcher />
      <Token
        isThreadToken
        token={swap.tokens.main}
      />
      <Input
        sx={{ boxSize: '100%' }}
        placeholder="Notatka"
      />
      <Flex sx={{ gap: '4px', h: '100%' }}>
        <Button
          variant="light"
          onClick={swap.swap}
          isLoading={swap.helpers.isBusy}
          sx={{
            fontSize: 'bigger',
            w: '100%',
          }}
        >
          {swap.direction === EMode.Buy ? 'Kup' : 'Sprzedaj'}
        </Button>
      </Flex>
    </Flex>
  )
}
