import { Button, Flex, Tooltip, useToast } from '@chakra-ui/react'
import { useAdsProvider } from '../../../../provider/Ads'
import { getColor } from '../../../../theme/global'
import useIsMobile from '../../../../hooks/chakra/useIsMobile'

export const AdvertTrigger = () => {
  const toast = useToast()
  const ads = useAdsProvider()
  const isMobile = useIsMobile()

  const handleClick = () => {
    ads.setup.open('topbar')

    toast({ title: 'Już wkrótce!', status: 'info' })
  }

  return (
    <Tooltip
      label="Tu może być Twój token"
      hasArrow
    >
      <Flex
        sx={{
          height: '32px',
          width: 'calc(100% + 36px)',
          transform: 'translateX(-20px)',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Button
          variant="slave"
          sx={{
            borderRadius: '4px',
            bg: isMobile ? getColor('_successPrimary', 0.5) : getColor('_failSecondary', 1),
            mx: '2px',
            p: '2px 16px',
            fontSize: '13px',
            fontWeight: '500',
            color: 'white',
          }}
          onClick={handleClick}
        >
          Licytuj miejsce
        </Button>
      </Flex>
    </Tooltip>
  )
}
