import { IColor } from './types'

const degen = {
  _backgroundPrimary: '#050400',
  _backgroundSecondary: '#130F01',
  _backgroundTertiary: '#191710',
  _foregroundPrimary: '#ECDFD3',
  _foregroundSecondary: '#A361E8',

  _borderPrimary: /* degen._backgroundTertiary +*= 2% */ '#2d291d',

  _textPrimary: '#EDE7D8',
  _textSecondary: '#959075',
  _textTertiary: '#655D4E',
  _textQuaternary: '#6f5a06',
  _textBlack: '#050400',

  _accentPrimary: '#BF9A09',
  _accentSecondary: '#EDE7C1',

  _successPrimary: '#3EB1B6',
  _failPrimary: '#AB4B8E',
  _failSecondary: '#904468',
}

/* target color theme variant */
const light: IColor = {
  backgroundMain: '#000000',
  backgroundSecondary: degen._backgroundPrimary,
  backgroundTertiary: '#0c0900',
  backgroundQuaternary: '#060503',
  _accentSecondary: degen._accentSecondary,
  _accentSecondaryLight: '#140f00',
  borderPrimary: degen._borderPrimary,

  textWhite: '#000000',

  textPrimary: degen._textPrimary,
  textSecondary: degen._textSecondary,
  textTertiary: degen._textTertiary,
  _accentPrimary: degen._accentPrimary,
  textQuaternary: '#b8b299',

  greenPrimary: degen._successPrimary,
  greenSecondary: '#13020c',
  redPrimary: degen._failPrimary,
  redSecondary: '#010c0d',
  bluePrimary: '#d16f05',
  blueSecondary: '#100700',
  bluePlaceholder: degen._foregroundPrimary,
  goldPrimary: degen._foregroundSecondary,

  grayPrimary: '#0d0b08',
  graySecondary: '#060504',

  /* debug */
  ...(degen as any),
}

/**
 * @deprecated
 */
const dark: IColor = light

export const Colors: { [key in keyof typeof light]: { default: string; _dark: string } } = Object.fromEntries(
  Object.entries(light).map(([key, value]) => [
    key,
    {
      default: value,
      _dark: dark[key],
    },
  ])
) as any
