import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Address } from '../../../../../../../../../contracts/address'
import { Reward } from '../../../../../../../../shared/actions/QuickPost/modal/QuestModal/Reward'
import { includesInsensitive } from '../../../../../../../../../utils/ts'
import { getColor, getTheme } from '../../../../../../../../../theme/global'

type Token = {
  address: string
  amount: BigInt
}

export const BoostModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [tokens, setTokens] = useState<Token[]>([])
  const [tvl, setTvl] = useState<BigInt>(BigInt(0))

  useEffect(() => {
    let newTVL: BigInt = BigInt(0)
    const whitelistedTokens = [Address.BUSD, Address.USDC, Address.USDT, Address.W_CDO]
    tokens.forEach((token: Token) => {
      if (includesInsensitive(whitelistedTokens, token.address)) {
        //@ts-ignore
        newTVL = newTVL + token.amount
      }
    })
    setTvl(newTVL)
  }, [tokens])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        overflow="hidden"
        bgColor="backgroundMain"

        borderRadius="16px"
        p="0"
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          gap="8px"
          background="backgroundTertiary"
        >
          <Grid
            width="27px"
            height="27px"
            background="_accentPrimary"
            borderRadius="full"
            placeItems="center"
          >
            <Image
              src="/assets/icons/currency-dollar.svg"
              width="19px"
              height="19px"
              ml="1px"
              mb="2px"
            />
          </Grid>
          <Flex sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            <Text
              color="_accentSecondary"
              fontSize="18px"
              fontWeight="600"
              lineHeight="24px"
            >
              Boost Post
            </Text>
            <Flex
              sx={{
                p: '2px 6px',
                bg: '#C1EAFF',
                color: '#008ED7',
                fontSize: '12px',
                fontWeight: '400',
                borderRadius: '4px',
              }}
            >
              Become a designer
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalCloseButton mt="4px" />
        <Divider orientation="horizontal" />
        <ModalBody
          py="24px"
          display="flex"
          flexDir="column"
        >
          <Flex
            alignItems="start"
            justifyContent="flex-start"
            direction="column"
            gap="24px"
          >
            <Flex
              borderRadius="8px"
              border="1px solid"
              borderColor="_accentPrimary"
              bg={getColor('bluePrimary', 0.1)}
              p="12px 16px"
              gap="16px"
              align="start"
            >
              <Image
                src="/assets/icons/info.svg"
                mt="4px"
              />
              <Box>
                <Text
                  color={getTheme() === 'dark' ? 'textPrimary' : '_textQuaternary'}
                  fontSize="14px"
                  fontWeight="500"
                >
                  Share reward, gain visibility
                </Text>
                <Text
                  color="textSecondary"
                  mt="8px"
                  fontSize="12px"
                >
                  <Text
                    as="span"
                    color="_accentSecondary"
                  >
                    How it works?
                  </Text>{' '}
                  Deposit rewards, select social media platform, set incentive per activity and watch your post reach
                  new heights through real community engagement.
                </Text>
              </Box>
            </Flex>
            <Box>
              <Text
                color="_accentSecondary"
                fontWeight="500"
                fontSize="14px"
              >
                Rewards
                <Text
                  as="span"
                  color="#D14343"
                >
                  *
                </Text>
              </Text>
              <Text
                color="textSecondary"
                fontSize="12px"
                mb="8px"
              >
                Choose the tokens for rewards tailoring your incentives to suit your campaigns needs.
              </Text>
              <Flex
                sx={{
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                {tokens?.map((token, index) => (
                  <Reward
                    key={index}
                    address={token?.address}
                    amount={+token?.amount.toString()}
                    setTokens={setTokens}
                    tokens={tokens}
                    index={index}
                  />
                ))}
              </Flex>
              <Flex
                gap="8px"
                alignItems="center"
                justify="space-between"
              >
                <Button
                  mt="8px"
                  variant="none"
                  color="#000000"
                  bgColor="_accentPrimary"
                  display="flex"
                  px="16px"
                  py="8px"
                  h="min"
                  fontSize="12px"
                  alignItems="center"
                  justifyContent="center"
                  width="min"
                  onClick={() =>
                    setTokens(previousTokens => [
                      ...previousTokens,
                      {
                        address: '',
                        amount: BigInt(0),
                      },
                    ])
                  }
                >
                  Add new token
                </Button>
                <Text
                  color="textSecondary"
                  fontSize="12px"
                >
                  Total value:{' '}
                  <Text
                    as="span"
                    color="textQuaternary"
                  >
                    ${+tvl.toString()}
                  </Text>
                </Text>
              </Flex>
            </Box>
            <Divider />
            <Box>
              <Text
                color="textSecondary"
                fontSize="12px"
                mb="-16px"
              >
                Your budget for boosting is{' '}
                <Text
                  as="span"
                  color="textQuaternary"
                >
                  $1,421
                </Text>
                . Determine the reward per share to calculate the amount of rewards."
              </Text>
            </Box>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap="12px"
              width="100%"
            >
              <Flex
                direction="column"
                gap="8px"
                width="100%"
              >
                <Text
                  color="_accentSecondary"
                  fontSize="12px"
                >
                  Set rewards per share
                </Text>
                <InputGroup>
                  <InputLeftAddon children="$" />
                  <Input type="number" />
                </InputGroup>
              </Flex>
              <Flex
                direction="column"
                gap="8px"
                width="100%"
              >
                <Text
                  color="_accentSecondary"
                  fontSize="12px"
                >
                  Amount rewards{' '}
                  <Text
                    as="span"
                    color="textSecondary"
                    fontSize="9px"
                  >
                    (automatically calculated)
                  </Text>
                </Text>
                <Box
                  border="1px solid"
                  borderColor="_borderPrimary"
                  background="borderPrimary"
                  borderRadius="8px"
                  px="12px"
                  py="10px"
                  width="100%"
                >
                  <Text
                    color="_accentSecondary"
                    fontSize="14px"
                  >
                    31,214
                  </Text>
                </Box>
              </Flex>
            </Flex>
            <Text
              color="#6F6A4F"
              fontSize="10px"
            >
              *You can feel secure: report any fraudelent promoters, but fair play is key. <br />
              Your funds can be withrdraw anytime.
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid borderPrimary"
          width="100%"
        >
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Flex
              gap="8px"
              width="70%"
            >
              <Button>Cancel</Button>
              <Button
                bg="_accentSecondary"
                width="100%"
                color="backgroundMain"
                variant="none"
              >
                Deposit ${+tvl.toString()}
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
