const extractResponse = async (callback: Function) => {
  try {
    const response = await callback()
    return response.data
  } catch (error: any) {
    console.error(error)
    return { ...error }
  }
}

export { extractResponse }
