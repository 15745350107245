import { Flex } from '@chakra-ui/layout'
import { Button, Image, Tooltip } from '@chakra-ui/react'
import { useSwapProvider, EMode } from '../../../../../lib/dex-overlay/components/SwapProvider'

export const DirectionSwitcher = () => {
  const swap = useSwapProvider()

  const handleSwitch = () => {
    swap.switch()
  }

  return (
    <Flex
      sx={{
        flexGrow: 1,
        transition: 'transform 0.3s',
        transform: `rotate(${swap.direction === EMode.Buy ? '0' : '180'}deg)`,
      }}
      justify="center"
    >
      <Tooltip
        label="Kup ⇄ Sprzedaj"
        hasArrow
        placement="left"
        openDelay={450}
      >
        <Flex
          as={Button}
          variant="slave"
          onClick={handleSwitch}
          align="center"
          justify="center"
          sx={{
            boxSize: '40px',
          }}
        >
          <Image src="/assets/icons/swap.svg" />
        </Flex>
      </Tooltip>
    </Flex>
  )
}
