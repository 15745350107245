import React from 'react'
import { emptyGroup, IGroup } from '../../hooks/useGroup'
import { useEthereumProvider } from '../Ethereum/ethereumProvider'
import { RegexUtils } from '../../utils/regex'

type IContext = IGroup & { setData: React.Dispatch<React.SetStateAction<IGroup>>; isOwner: boolean }

export const GroupProvider = ({ children, group }: { children: React.ReactNode; group: IGroup }) => {
  const wallet = useEthereumProvider()
  const isOwner = !!wallet.account && RegexUtils.insensitive(group.owner || '').test(wallet.account || '')

  const [data, setData] = React.useState<IGroup>(group)

  return <GroupContext.Provider value={{ ...data, setData, isOwner }}>{children}</GroupContext.Provider>
}

const GroupContext = React.createContext<IContext>({
  ...emptyGroup,
  setData: () => {},
  isOwner: false,
})

export const useGroupProvider = () => {
  return React.useContext(GroupContext)
}
