import { Address, Hex, parseUnits, zeroAddress } from 'viem'
import { Flex, Input, Button, Text, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react'
import { erc20ABI, useAccount, useNetwork, useWalletClient } from 'wagmi'
import { getContract } from '@wagmi/core'
import { useEffect, useReducer, useState } from 'react'
import { makeReducer } from '../utils/react'
import routerContractAbi from '../lib/contract-abi/router-contract'
import { extractResponse } from '../utils/axios'
import { buildRouteBytes } from '../utils/1inch'
import { waitForTransaction } from '@wagmi/core'
import { fetchApi, fetcherGet } from '../../../utils/fetcher'
import { _log } from '../../../logger'
import React from 'react'
import { loaded } from '../../../utils/process'

type IRoute = /* 1inch.io response */ any

export interface ISwap {
  swap: () => void
  switch: () => void
  fetchRoute: (_: I1inchApiGetCalldataParameters) => IRoute
  setValue: (_?: bigint) => void
}

export enum EMode {
  Buy,
  Sell,
}

export interface IToken {
  /** identifies the token (this id is used to get dynamic data
   * concerning this particular token (i.e. desired swap amount
   * and whether this token should be swapped from/into) from
   * the swap provider) */
  address: address
  /** value swapped / swapped into; NOTE: setValue should not trigger `switch` */
  value?: bigint
  /** @deprecated this should be fetched from backend (cache) */
  decimals?: number
  /** @deprecated this should be fetched from backend (cache) */
  iconUri?: string
  /** @deprecated rest of the token attributes should be fetched from backend (cache) and put in a prop `meta` */
}

interface IData {
  direction: EMode
  tokens: {
    /** thread token */
    main: IToken
    external: IToken
  }
  /** router state */
  router: I1inchApiGetCalldataParameters
  status: EStatus
  helpers: {
    isBusy: boolean
  }
}

interface I1inchApiGetCalldataParameters {
  src: Address | string
  dst: Address | string
  /** number of Wei */
  amount: number | string
  from: Address
  /** int percentage */
  slippage: number
  /** swap details (1inch proprietary) */
  disableEstimate: boolean
  /** swap order (1inch proprietary) */
  allowPartialFill: boolean
}

export enum EStatus {
  Idle,
  Initializing,
  Approving,
  Finalizing,
}

export const emptySwap: ISwap = {
  swap: () => {},
  switch: () => {},
  fetchRoute: () => {},
  setValue: () => {},
}

const emptyTokens: IData['tokens'] = {
  external: {
    /** BSC USDC */
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    decimals: 18,
    iconUri: '/assets/elements/usdc.svg',
  },
  main: {
    /** BSC WETH */
    address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    decimals: 18,
    iconUri: '/assets/elements/eth.svg',
  },
}

const emptyRouter: I1inchApiGetCalldataParameters = {
  src: emptyTokens.external.address,
  dst: emptyTokens.main.address,
  amount: `${parseUnits('1', emptyTokens.external.decimals!)}`,
  from: zeroAddress,
  slippage: 1,
  disableEstimate: false,
  allowPartialFill: false,
}

const emptyData: IData = {
  direction: EMode.Buy,
  tokens: emptyTokens,
  router: emptyRouter,
  status: EStatus.Idle,
  helpers: {
    isBusy: false,
  },
}

/** 0.3% */
const COMMISSION_PART = 3
/** DegenOverlay */
const OVERLAY_ROUTER: Address = '0x59020742780078a58592e9406b872e81bd98a7c0'
const _1INCH_AGGREGATIONROUTERV5_EXECUTOR = '0xE37e799D5077682FA0a244D46E5649F71457BD09'

export const SwapProvider = ({ children }: { children: React.ReactNode }) => {
  const [data, dispatch] = useReducer(...makeReducer<IData>(emptyData))
  const [routeCache, setRouteCache] = useState<Record<string, IRoute>>({})

  const wagmiWalletClient = useWalletClient()
  const wagmiAccount = useAccount()
  const { chain: wagmiChain } = useNetwork()

  const getRouteId = () =>
    `${data.tokens.main.address}-${data.tokens.external.address}-${data.direction === EMode.Buy ? 'buy' : 'sell'}`

  /**
   * This refreshes route data
   */
  const fetchRoute = async (params: I1inchApiGetCalldataParameters) => {
    const result = await extractResponse(
      async () =>
        await fetcherGet(`retrieve-calldata/${wagmiChain?.id}`, {
          params,
        })
    )

    setRouteCache(state => ({
      ...state,
      /** `target-destination-[buy|sell]` */
      [getRouteId()]: result,
    }))

    return result
  }

  /**
   * This method will call the 1inch API for calldata to execute a token swap:
   *  1. Check for allowance (for max)
   *  2. If not enough allowance, approve (for max)
   *  3a. Swap – fetch calldata from 1inch (for max - commission)
   *  3b. Swap (for max, though with `calldata` for max - commission)
   */
  const swap_ = async () => {
    if (!wagmiWalletClient?.data || !wagmiAccount?.address || !wagmiChain) {
      return
    }

    dispatch({
      status: EStatus.Initializing,
    })
    const srcContract = getContract({
      abi: erc20ABI,
      address: data.router.src as Address,
      walletClient: wagmiWalletClient.data!,
    })

    const allowance = await srcContract.read.allowance([wagmiAccount.address, OVERLAY_ROUTER])

    if (BigInt(allowance) < BigInt(data.router.amount)) {
      dispatch({
        status: EStatus.Approving,
      })

      const tx = await srcContract.write?.approve([OVERLAY_ROUTER, BigInt(data.router.amount)])
      await waitForTransaction({ hash: tx })
    }

    dispatch({
      status: EStatus.Finalizing,
    })

    /* The amount swapped is the amount deduced by the commission amount – and so this is the
        value passed in to 1inch rather than to the contract itself. */
    const commissionDeductedAmount =
      BigInt(data.router.amount) - (BigInt(data.router.amount) * BigInt(COMMISSION_PART)) / BigInt(1000)

    /* Values passed in to the 1inch off-chain router for route calculation */
    const $1inchArguments: I1inchApiGetCalldataParameters = {
      ...data.router,
      amount: commissionDeductedAmount.toString(),
    }

    _log('Routing via 1inch with args:', $1inchArguments)
    const $1inchApiResponse = routeCache[getRouteId()] ?? (await fetchRoute($1inchArguments))
    _log('Got 1inch API stdout:', $1inchApiResponse)

    const $1inchCalldata = buildRouteBytes($1inchApiResponse.tx.data as Hex)

    const $1inchMinReturnAmount = $1inchApiResponse.toAmount as string

    /** Clear route cache */
    setRouteCache(state => ({
      ...state,
      [getRouteId()]: undefined,
    }))

    const routerContract = getContract({
      abi: routerContractAbi,
      address: OVERLAY_ROUTER,
      walletClient: wagmiWalletClient.data!,
    })

    // address, (address,address,address,address,uint256,uint256,uint256), bytes, bytes
    const routerArguments = [
      // Unknown; seems to be different for every chain but the same for every transaction
      // executor	            address
      _1INCH_AGGREGATIONROUTERV5_EXECUTOR,
      [
        // Token in
        // desc.srcToken	      address
        data.router.src,
        // Token out
        // desc.dstToken	      address
        data.router.dst,
        // Same as `executor`; address actually manipulating the token in
        // desc.srcReceiver	    address
        _1INCH_AGGREGATIONROUTERV5_EXECUTOR,
        // Address receiving the token out
        // desc.dstReceiver	    address
        data.router.from,
        // Amount of token in in wei; this value is changed in the contract
        //  as to fit the commission specified (i.e. reducing it by the commission amount)
        // desc.amount	        uint256
        data.router.amount,
        // Calculated by 1inch API; minimum amount of token out in wei received
        // desc.minReturnAmount	uint256
        $1inchMinReturnAmount,
        // Additional swap settings; defaults to `4` for no effect
        // desc.flags	          uint256
        BigInt(4).toString(),
      ],
      // Additional swap settings; defaults to `0x` for no effect
      // permit	              bytes
      '0x' as Hex,
      // Swap route; calculated by 1inch API
      // data	                bytes
      $1inchCalldata,
    ]

    _log('Swapping with args:', routerArguments)
    const tx = await routerContract.write?.swap(routerArguments)
    await waitForTransaction({ hash: tx })

    dispatch({
      status: EStatus.Idle,
    })
  }

  const swap = async () =>
    loaded(
      async () => await swap_(),
      undefined,
      () => dispatch({ status: EStatus.Idle })
    )

  const switch_ = () => {
    _log('switching')

    dispatch({
      direction: Math.abs(data.direction - 1) as EMode,
    })
  }

  const setValue = (value?: bigint) => {
    dispatch({
      ...data,
      tokens: {
        ...data.tokens,
        external: {
          ...data.tokens.external,
          value,
        },
      },
    })
  }

  /* Sync current wallet account */
  useEffect(() => {
    if (!wagmiAccount.isConnected) {
      return
    }

    dispatch({
      router: {
        ...data.router,
        from: wagmiAccount.address!,
      },
    })
  }, [wagmiAccount.isConnected])

  /** Helpers: isBusy */
  useEffect(() => {
    dispatch({
      helpers: {
        ...data.helpers,
        isBusy: data.status !== EStatus.Idle,
      },
    })
  }, [data.status])

  return (
    <SwapContext.Provider
      value={{
        swap,
        switch: switch_,
        fetchRoute,
        setValue,
        ...data,
      }}
    >
      {children}
    </SwapContext.Provider>
  )
}

const SwapContext = React.createContext<ISwap & IData>({ ...emptySwap, ...emptyData })

export const useSwapProvider = () => {
  const context = React.useContext(SwapContext)

  if (!context) {
    throw new Error('`useSwapProvider` cannot be used outside of a `SwapProvider`!')
  }
  return context
}
