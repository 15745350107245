import { Button, Flex, Image, Text, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { emptyProfile, IProfile } from '../../../../../provider/Profile/profileProvider'
import { fetchApi } from '../../../../../utils/fetcher'
import { buildIpfsGateway } from '../../../../../utils/parser'
import { loaded } from '../../../../../utils/process'
import { ButtonFollow } from '../../../../shared/actions/ButtonFollow'
import { RolesWithIndicator } from '../../../../shared/roles/RolesWithIndicator'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import { Member } from './Members'

export const MemberItem = ({ member }: { member: Member }) => {
  const wallet = useEthereumProvider()
  const navigate = useNavigate()
  const [user, setUser] = useState<IProfile>(emptyProfile)

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        const profile: IProfile = await fetchApi(`profile/${member.address}`)

        setUser(profile)
      }),
    [member]
  )

  return (
    <Flex sx={{ p: '12px 16px', w: '100%' }}>
      <Flex sx={{ gap: '8px', w: '100%' }}>
        <Image
          src={buildIpfsGateway(user.avatar)}
          sx={{ borderRadius: '50%', boxSize: '40px' }}
        />
        <Flex sx={{ flexDir: 'column', gap: '8px', w: '100%' }}>
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', w: '100%' }}>
            <Flex sx={{ gap: '8px', alignItems: 'center' }}>
              <Flex sx={{ flexDir: 'column' }}>
                <Text sx={{ fontSize: '14px', color: '_accentSecondary' }}>{user.name || 'Bez nazwy'}</Text>
                <Flex sx={{ gap: '14px', alignItems: 'center' }}>
                  {user.nickname && <Text sx={{ fontSize: '12px', color: '_textTertiary' }}>@{user.nickname}</Text>}
                  <Flex
                    sx={{ fontSize: '12px', color: '_textTertiary', alignItems: 'center', gap: '5px' }}
                    as="span"
                  >
                    <Image src="/assets/icons/coin-dollar.svg" />
                    <Text
                      as="span"
                      sx={{ fontSize: '12px', color: '_textTertiary' }}
                    >
                      Power:
                    </Text>
                    <Text
                      as="span"
                      sx={{ fontSize: '12px', color: '_accentPrimary' }}
                    >
                      <SpinnerText />
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex sx={{ alignItems: 'center', gap: '8px' }}>
              <Button
                width="35px"
                height="35px"
                p="0"
                onClick={wallet.account && wallet.isAuthed ? () => navigate('/chat') : undefined}
              >
                <Image
                  src="/assets/icons/envelope.svg"
                  boxSize="16px"
                />
              </Button>
              <ButtonFollow />
              <Tooltip label="Coming soon">
                <Button
                  onClick={() => null}
                  sx={{ display: 'flex', p: '0 !important', h: '36px' }}
                  isDisabled
                >
                  <Flex
                    sx={{
                      bg: '_accentPrimary',
                      w: '50%',
                      color: 'white',
                      h: '100%',
                      alignItems: 'center',
                      px: '16px',
                      borderRadius: '8px 0 0 8px',
                    }}
                  >
                    Buy
                  </Flex>
                  <Flex sx={{ w: '50%', color: '_accentPrimary', h: '100%', alignItems: 'center', px: '12px' }}>
                    $<SpinnerText />
                  </Flex>
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
          <Flex sx={{ gap: '4px', alignItems: 'center' }}>
            <RolesWithIndicator roles={member?.roles} />
          </Flex>
          <Text sx={{ fontSize: '12px', color: '#474D66' }}>{user.description}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
