import {
  Button,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
  Image,
} from '@chakra-ui/react'
import { getColor } from '../../../../../theme/global'
import { useAdsProvider } from '../../../../../provider/Ads'
import { useState } from 'react'

export const Advert = () => {
  const [advert, setAdvert] = useState('')

  const toast = useToast()
  const ads = useAdsProvider()

  const handleClick = () => {
    ads.setup.open('banner')

    toast({ title: 'Już wkrótce!', status: 'info' })
  }

  return (
    <Popover
      trigger="hover"
      openDelay={50}
      closeDelay={300}
    >
      <PopoverTrigger>
        <Flex
          as={Link}
          justify="space-between"
          sx={{
            width: { base: 'unset', md: '100%' },
            bg: 'linear-gradient(128.77deg, #B9AEFB 23.25%, #F7CCFF 70.65%)',
            // TODO: 16px -> 12px for   every border radius
            borderRadius: '16px',
            p: '20px',
            color: '_textBlack',
            mx: { base: '12px', md: '0' },
          }}
          onClick={!advert ? handleClick : undefined}
        >
          <Flex
            direction="column"
            sx={{ gap: '6px' }}
          >
            <Flex
              sx={{
                boxSize: '32px',
              }}
            >
              <Image src="/assets/icons/coins.svg" />
            </Flex>
            <Text sx={{ fontWeight: '500', lineHeight: '24px', fontSize: '20px' }}>Licytuj reklamę</Text>
            <Text sx={{ lineHeight: '20px', fontSize: '14px' }}>To jest miejsce na twoją reklamę</Text>
          </Flex>
        </Flex>
      </PopoverTrigger>
      {/* This should open up a modal with auction content, for this advert + a link to the coin advert auction, and vice-versa for the coin advert. */}
      <PopoverContent
        sx={{
          w: 'auto',
          bg: '_foregroundPrimary',
          borderRadius: '16px',
          borderColor: '_foregroundPrimary',
        }}
      >
        <PopoverArrow
          sx={{
            bg: '_foregroundPrimary !important',
            boxShadow: `-1px -1px 0 ${getColor('_foregroundPrimary')} !important`,
          }}
        />
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            p: '16px',
            flexDirection: 'column',
            gap: '16px',
            color: '_textBlack',
          }}
        >
          <Flex
            direction="column"
            sx={{ alignItems: 'center' }}
          >
            <Text
              sx={{
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              This is a place for your ad
            </Text>
            <Text>Bid your ad now!</Text>
          </Flex>
          <Button
            sx={{
              bg: '_foregroundSecondary',
              color: 'white',
              fontWeight: '500',
            }}
            onClick={handleClick}
          >
            Licytuj reklamę
          </Button>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}
