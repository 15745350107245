import { Flex, Image, Link, Spinner, Text, Tooltip, useSafeLayoutEffect } from '@chakra-ui/react'
import { Message as IMessage, Thread } from '../../../../../provider/Chat/types'
import { buildIpfsGateway, formatUsd, trimAddress } from '../../../../../utils/parser'
import { ReactNode, createRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { GENERAL_THREAD_ID, useChatProvider } from '../../../../../provider/Chat/chatProvider'
import { NoMessages } from '../../NoThreads/noThreads'
import { isEqualInsensitive } from '../../../../../utils/ts'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { formatUnits, zeroAddress } from 'viem'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import useIsMobile from '../../../../../hooks/chakra/useIsMobile'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { profile } from 'console'
import { Message } from './Message'
import { _log } from '../../../../../logger'

export const MessagesList = () => {
  const [ref, setRef] = useState<HTMLDivElement>()

  const chat = useChatProvider()
  const thread = useMemo(() => chat.getThreadById(chat.selectedThreadId || zeroAddress), [chat.selectedThreadId])

  const ref_ = createRef<HTMLDivElement>()
  const observerTarget = createRef<HTMLDivElement>()

  const scrollToBottom = (isSmooth?: boolean) => {
    ref?.scroll({ top: ref?.scrollHeight, behavior: isSmooth ? 'smooth' : undefined })
  }

  const handleScrollEvent = () => {
    if (!ref) {
      return
    }

    chat.setIsAtBottom(Math.abs(ref.scrollHeight - (ref.scrollTop + ref.clientHeight)) < 24)
  }

  /* Update scrolling reference based on current height made up of messages */
  useLayoutEffect(() => {
    if (ref_.current?.scrollHeight === undefined) {
      return
    }

    setRef(ref_.current)
  }, [ref_.current?.scrollHeight, chat.selectedThreadId])

  /** TODO: Fix; not always scrolls to bottom: i.e. if images load later than messages */
  useLayoutEffect(() => {
    if (!ref?.scrollHeight || !thread.messages.length) {
      return
    }

    scrollToBottom()
    chat.registerScrollToBottom(scrollToBottom)
  }, [thread.messages.length, ref?.scrollHeight, chat.selectedThreadId])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          if (thread.id) {
            chat.loadThread(thread.id)
          }
        }
      },
      { threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [observerTarget, thread.id])

  useEffect(() => {
    ref?.addEventListener('scroll', handleScrollEvent)

    return () => {
      ref?.removeEventListener('scroll', handleScrollEvent)
    }
  }, [ref])

  return (
    <Flex
      flexDir="column"
      px="20px"
      mb={{
        base: '42px',
        md: '72px',
      }}
      pb="18px"
      pos="relative"
      ref={ref_}
      overflowX="scroll"
      gap="5px"
    >
      <Flex ref={observerTarget}></Flex>
      <Flex
        w="100%"
        justify="center"
        align="center"
      >
        {thread?.loading && !!thread.messages.length && <Spinner boxSize="20px" />}
      </Flex>

      {!thread.messages.length ? (
        <Flex margin="auto">
          <Spinner />
        </Flex>
      ) : thread.messages.length === 1 ? (
        <NoMessages />
      ) : (
        <Flex sx={{ gap: '5px', flexDirection: 'column-reverse' }}>
          {thread.messages
            .slice(1)
            .reverse() /* reverse rendering order */
            .map((message: IMessage, index) => (
              <Message
                key={message.uuid}
                data={message}
                rendererIndex={thread.messages.slice(1).length - index - 1}
              />
            ))}
        </Flex>
      )}
    </Flex>
  )
}
