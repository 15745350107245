import { Flex, Spinner, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { buildIpfsGateway, getInitials } from '../../../utils/parser'
import { IParent } from '../../../types/Component'

interface IUserAvatarProps {
  bgColor: string
  size: string | number
  children?: React.ReactNode
  image?: string
  name?: string
  isLoading?: boolean
  textSize?: {
    short: string
    long: string
  }
  borderRadius?: string
  sx?: IParent['css']
}

export const Avatar: FC<IUserAvatarProps> = ({
  bgColor,
  image,
  size,
  isLoading,
  name,
  textSize = {
    long: 'xx-large',
    short: 'xxx-large',
  },
  borderRadius,
  children,
  sx,
}) => {
  return (
    <Flex
      bgColor="_foregroundPrimary"
      align="center"
      justify="center"
      height={size}
      width={size}
      borderRadius={borderRadius ?? '50%'}
      border={parseInt(size.toString().slice(0, 2)) < 100 ? '4px solid' : 'none'}
      borderColor="_foregroundPrimary"
      backgroundImage={buildIpfsGateway(image)}
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      sx={sx}
    >
      {children}
      {isLoading && (
        <Spinner
          color="backgroundMain"
          size="sm"
        />
      )}
      {!isLoading && !children && !image && (
        <Text
          textTransform="uppercase"
          color="#6f5a06"
          fontSize={textSize.short}
          userSelect="none"
        >
          {getInitials(name)}
        </Text>
      )}
    </Flex>
  )
}
