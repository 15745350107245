import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from '@ajna/pagination'
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Image,
  Spinner,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useReducer, useState } from 'react'
import { useAccount } from 'wagmi'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { useProfilesProvider } from '../../../../../provider/Profiles'
import { getBlockedHeaders } from '../../../../../utils/auth'
import { fetchApi } from '../../../../../utils/fetcher'
import { loaded } from '../../../../../utils/process'
import { QuickPost } from '../../../../shared/actions/QuickPost/QuickPost'
import PostsFilter from '../PostsFilter/PostsFilter'
import TabSwitcher from '../TabSwitcher/tabSwitcher'
import { TimeAndTarget, defaultState, filterReducer } from '../filterReducer'
import { Post } from '../roles/tabs/discussions/Post/Post'
import { OnboardInfo } from './OnboardInfo'
import { PostVisibilityWarning } from './PostVisibilityWarning'

enum Tab {
  ALL,
  TEXT,
  IMAGES,
  VIDEOS,
  AUDIO,
  QUEST, // TODO: Add filter to backend only with quests
}

const tabs: Array<{ id: Tab; iconId: string; description: string }> = [
  { id: Tab.ALL, iconId: 'circle-icon', description: 'Wszystko' },
  { id: Tab.TEXT, iconId: 'text-icon', description: 'Tekstowe' },
  { id: Tab.IMAGES, iconId: 'photo-icon', description: 'Z obrazkami' },
  { id: Tab.VIDEOS, iconId: 'movie-icon', description: 'Z wideo' },
]

export enum PostType {
  Post = 'Post',
  Task = 'Task',
  Quest = 'Quest',
  Job = 'Job',
  Transfer = 'Transfer',
}

export interface IPostSchema {
  ipfs: string
  /* shortened post-profile-referral URI, e.g.:
    `post/Qme7ehrZEyvNe7B4Wsvt9M66HNFXWxEBCHptMJk5kw5ozG-5e6c3a12-c7ef-
    47f0-a85b-0773ee393c2f?referral=rHtd2ChO5U` */
  shortLink?: string

  type: PostType
  _created_at: string | Date
  author?: string
  /** Backend-store data duplication */
  images?: string[]
  text?: string
  thread?: string
  /** the thread ID this post is in context of; post.json UUID */
  sharing?: string
  sharers?: this[]
  reports?: string[]
  power?: {
    voters: string[]
    value: string
    valueDown?: string
    valueUp?: string
  }
  target?: {
    roles: []
    interests: []
  }
  isPinned?: boolean
  quest?: {
    name: string
    id: string
    slots: number
    roles: string[]
  }
  transfer?: {
    token: address
    amount: number
    recipient: address
  }
  reward?: {
    tokens: {
      name: string
      address: string
      amount: number
      usd: number
    }[]
    usd: number
  }
  isClosed?: boolean
  winner?: {
    address: string
    isClaimed: boolean
    invitedBy: string
    reward: {
      tokens: {
        token: string
        amount: number
        usd: number
      }[]
      usd: number
    }
  }
  participants?: {
    address: string
    invitedBy: string
    asRole: string
  }[]
  /* determines membership in a group */
  group?: address
}

export const emptyPostSchema: IPostSchema = {
  ipfs: '',
  _created_at: '',
  type: PostType.Post,
}

export const Default = () => {
  const [state, setState] = useState(Tab.ALL)
  const [posts, setPosts] = useState<IPostSchema[]>([])
  const [isPostsLoading, setIsPostsLoading] = useState(true)
  const [postVisibilityWarning, setPostVisibilityWarning] = useState(true)
  const [numOfPages, setNumOfPages] = useState(1)

  const outerLimit = 2
  const innerLimit = 2
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: numOfPages * 10,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const colorMode = useColorMode()

  const insertPost = (post: IPostSchema) => {
    setPosts(prev => [post, ...prev])
  }
  const account = useAccountProfileProvider()
  const wallet = useAccount()
  const ethereum = useEthereumProvider()
  const profiles = useProfilesProvider()

  const baseStyles: ButtonProps = {
    w: '32px',
    h: '32px',
    fontSize: 'sm',
    border: 'none',
  }

  const normalStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: '_accentSecondaryLight',
    },
  }

  const activeStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: '_accentSecondaryLight',
    },
    bg: '_accentSecondaryLight',
    color: '_accentPrimary',
  }

  const [filterState, dispatchFilter] = useReducer(filterReducer, defaultState)

  const fetchFilteredPosts = async (page?: number) => {
    const body = {
      page: page ?? currentPage,
      timeLimit:
        filterState.timeAndTarget === TimeAndTarget['7D']
          ? 7 * 24
          : filterState.timeAndTarget === TimeAndTarget['24H']
            ? 24
            : 0,
      target:
        filterState.timeAndTarget === TimeAndTarget.FOR_YOU
          ? { roles: account?.roles, interests: account?.interests }
          : [],
      following: filterState.timeAndTarget === TimeAndTarget.FOLLOWING ? account?.followed : [],
      content:
        filterState.type === Tab.ALL
          ? ''
          : filterState.type === Tab.IMAGES
            ? 'images'
            : filterState.type === Tab.TEXT
              ? 'text'
              : filterState.type === Tab.VIDEOS
                ? 'videos'
                : filterState.type === Tab.QUEST
                  ? 'quest'
                  : '',
      ...(filterState.timeAndTarget === TimeAndTarget.NEW ? { order: 'desc' } : {}),
    }

    await loaded(async () => {
      const res = await fetchApi('post/getFiltered', body, {
        headers: getBlockedHeaders(account.blockedUsers),
      })

      setPosts(res.posts)
      setNumOfPages(res.totalPages)
    }, setIsPostsLoading)
  }

  /** Fetch posts author' profile data in a batch on post list update */
  useEffect(() => {
    profiles.refresh(posts.map(post => post.author as address))
  }, [JSON.stringify(posts.map(post => post.ipfs))])

  useEffect(() => {
    if (account.isLoading && !wallet.isDisconnected) {
      return
    }

    fetchFilteredPosts()
  }, [filterState, currentPage, account.isLoading, wallet.isDisconnected])

  useEffect(() => {
    setCurrentPage(1)
  }, [filterState])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <Flex
      flexDirection="column"
      w={{ base: '100vw', md: '100%' }}
    >
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'borderPrimary',
          flexDirection: 'column',
          bg: 'backgroundMain',
          mx: { base: '16px', md: '0' },
          mb: { base: '16px', md: '18px' },
        }}
      >
        <Flex
          w="100%"
          gap="12px"
          justifyContent="flex-start"
          p="16px"
          alignItems="center"
          borderRadius="16px"
        >
          <Text
            color="textPrimary"
            fontWeight="600"
            fontSize="18px"
          >
            Główna
          </Text>
        </Flex>
      </Box>
      {!account.isOnboarded && ethereum.isAuthed && <OnboardInfo />}
      <QuickPost
        resultHandle={insertPost}
        isHome
      />
      <Flex
        flexDir="column"
        gap="16px"
        align="center"
      >
        {!!(account as any)?.warnings?.posts?.length && postVisibilityWarning && (
          <PostVisibilityWarning
            onClose={() => {
              setPostVisibilityWarning(false)
            }}
          />
        )}
        {/** sticky post */}
      </Flex>
      <Flex
        w="100%"
        justifyContent="space-between"
        mb="18px"
        direction="row"
        px={{ base: '12px', md: '0' }}
      >
        <Flex
          alignItems="center"
          pt="0"
          gap="14px"
        >
          <PostsFilter
            isOpen={isOpen}
            onClose={onClose}
            postsAmount={45800}
            submit={() => null}
          />
          <TabSwitcher
            filterByTimeAndTarget={(payload: TimeAndTarget) => {
              dispatchFilter({ type: 'FILTER_BY_TIME_AND_TARGET', payload: payload })
            }}
          />
        </Flex>
        <Flex>
          <Flex
            flexWrap="wrap"
            gap="2px"
          >
            {tabs.map(tab_ => (
              <Tooltip
                label={tab_.description}
                key={tab_.id}
              >
                <Button
                  variant="slave"
                  key={tab_.id}
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    '*': {
                      filter: 'unset',
                    },
                  }}
                  boxSize="36px"
                  p="10px"
                  bg={tab_.id === state ? '_accentSecondaryLight' : 'unset'}
                  cursor="pointer"
                  onClick={() => {
                    setState(tab_.id)
                    dispatchFilter({ type: 'FILTER_BY_CONTENT', payload: tab_.id })
                  }}
                  sx={{
                    '*': {
                      filter: tab_.id === state ? 'unset' : 'saturate(.15) brightness(.66)',
                    },
                  }}
                >
                  <Image
                    src={`/assets/icons/${tab_.iconId}.svg`}
                    boxSize="16px"
                    pointerEvents="none"
                  />
                </Button>
              </Tooltip>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
        gap="16px"
        align="center"
      >
        {/* <IcoPost /> */}
        {isPostsLoading ? (
          <Flex
            flexGrow="1"
            justifyContent="center"
            height="192px"
            alignItems="center"
          >
            <Spinner />
          </Flex>
        ) : !!posts?.length ? (
          <>
            {posts.map(post => (
              <Post
                key={post.ipfs}
                data={post}
              />
            ))}
          </>
        ) : (
          <Text
            py="25px"
            fontSize="14px"
            color="textQuaternary"
          >
            Nie ma tu jeszcze żadnych postów
          </Text>
        )}
      </Flex>
      <Flex
        w="100%"
        justify="center"
        gap="8px"
      >
        {/* @ts-ignore */}
        <Pagination
          pagesCount={numOfPages}
          currentPage={currentPage}
          // normalStyles={normalStyles}
          // activeStyles={activeStyles}
          // separatorStyles={baseStyles}
          onPageChange={(p: number) => handlePageChange(p)}
        >
          <PaginationContainer
            align="center"
            justify="space-between"
            w="auto"
            p={4}
            gap="4px"
          >
            <PaginationPrevious
              border="none"
              maxH="32px"
              maxW="32px"
              minH="32px"
              minW="32px"
              p="0"
            >
              <Image
                src="/assets/icons/chevron-right.svg"
                transform="rotate(180deg)"
              />
            </PaginationPrevious>
            <PaginationPageGroup
              align="center"
              separator={
                <PaginationSeparator
                  isDisabled
                  bg="_accentSecondaryLight"
                  border="none"
                  fontSize="sm"
                  w="32px"
                  h="32px"
                  jumpSize={11}
                />
              }
            >
              {pages.map((page: number) => (
                <PaginationPage
                  w="32px"
                  h="32px"
                  border="none"
                  bg="none"
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize="sm"
                  color="_textSecondary"
                  _hover={{
                    bg: '_accentSecondaryLight',
                  }}
                  _current={{
                    bg: '_accentSecondaryLight',
                    fontSize: 'sm',
                    color: '_accentPrimary',
                  }}
                />
              ))}
            </PaginationPageGroup>
            <PaginationNext
              border="none"
              maxH="32px"
              maxW="32px"
              minH="32px"
              minW="32px"
              p="0"
            >
              <Image src="/assets/icons/chevron-right.svg" />
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      </Flex>
    </Flex>
  )
}
