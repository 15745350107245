import { ArrowRightIcon } from '@chakra-ui/icons'
import { Flex, Text, Link, Box } from '@chakra-ui/layout'
import { Link as ReactLink } from 'react-router-dom'
import { EVariant, shimmerEffect } from '../../../images/LogoExtended'
import { getColor } from '../../../../theme/global'

export const DegenPumpAd = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Link
        as={ReactLink}
        sx={{
          bg: getColor('_foregroundSecondary', .75),
          px: '8px',
          py: '4px',
          mb: '16px',
          borderRadius: { base: '0', md: '8px' },
          justifyContent: 'center',
          alignItems: 'center',
          color: '_textPrimary',
          display: 'flex',
          position: 'relative',
        }}
        to="/chat"
      >
        Check out&nbsp;
        <Text
          as="span"
          sx={{ fontWeight: '600' }}
        >
          Degen PUMP
        </Text>
        &nbsp;now!
        <ArrowRightIcon sx={{ position: 'absolute', right: '8px', bottom: '0', transform: 'translateY(-50%)' }} />
      </Link>
      <Box
        sx={{
          maskSize: 'contain',
          maskRepeat: 'no-repeat',
          maskOrigin: 'fill-box',
          maskPosition: 'center',
          w: '100%',
          h: 'calc(100% - 16px)',
          top: '0',
          left: '0',
          overflow: 'hidden',
          position: 'absolute',
          borderRadius: { base: '0', md: '8px' },
          pointerEvents: 'none',
          opacity: 0.33,
        }}
      >
        <Box
          bgGradient="linear(to-r, transparent, _foregroundSecondary, _foregroundPrimary, transparent)"
          w="100%"
          h="100%"
          css={shimmerEffect(1.5, 1.5)}
        />
      </Box>
    </Box>
  )
}
