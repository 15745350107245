import { useState, Fragment as ReactFragment } from 'react'
import {
  Box,
  ButtonProps,
  Flex,
  HTMLChakraProps,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  border,
  useToast,
} from '@chakra-ui/react'
import { CardContainer } from '../components/shared/containers/CardContainer'
import ProfilesInfo from '../components/pages/Pages/profilesInfo'
import useAsyncEffect from '../hooks/effects/async'
import moment from 'moment/moment'
import { buildIpfsGateway, formatUsd, getInitials, trimAddress } from '../utils/parser'
import { Link } from 'react-router-dom'
import { IProfile, IProfileBatch } from '../provider/Profile/profileProvider'
import { fetchApi } from '../utils/fetcher'
import { IRate } from '../components/pages/Dashboard/LatestProfiles/dailyProfiles'
import { loaded } from '../utils/process'
import { copyToClipboard } from '../utils/os'
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from '@ajna/pagination'
import { UserTag } from '../components/shared/tags/UserTag'
import { formatUnits } from 'viem'
import { getColor } from '../theme/global'

export const Profiles = () => {
  const [data, setData] = useState<IProfileBatch>([])
  const [cdoHolders, setCdoHolders] = useState<number>()
  const [isLoading, setIsLoading] = useState(true)

  const [numOfPages, setNumOfPages] = useState(1)
  const outerLimit = 2
  const innerLimit = 2
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: numOfPages * 10,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })
  const [profileCount, setProfileCount] = useState<number>()
  const [activeProfilesCount, setActiveProfilesCount] = useState<number>()

  const [state, setState] = useState<{
    [key in 'active' | 'total']: { total: number; dayChange: number }
  }>()

  const toast = useToast()
  const isFirstPage = currentPage === 1

  const infoProfiles = [
    {
      title: 'Liczba Profilów',
      number: profileCount,
      percent: 0,
      subTitle: 'Total profiles',
      subInfo: 'Last 24h',
    },
    { title: 'DHC Holders', subTitle: 'Holders', number: cdoHolders, percent: 0, subInfo: 'Percentage' },
    {
      title: 'Aktywne Adresy',
      number: activeProfilesCount,
      percent: 0,
      subTitle: 'Daily Active Accounts',
      subInfo: '24h Change',
    },
  ] as any

  const baseStyles: ButtonProps = {
    w: '32px',
    h: '32px',
    fontSize: 'sm',
    border: 'none',
  }

  const normalStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: '_accentSecondaryLight',
    },
  }

  const activeStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: '_accentSecondaryLight',
    },
    bg: '_accentSecondaryLight',
    color: '_accentPrimary',
  }

  useAsyncEffect(
    () =>
      loaded(async () => {
        const accounts: IRate = await fetchApi('rate/profile', {})
        const dayAccounts: IRate = await fetchApi('rate/profile', { rate: 24 })
        const cdoHolders = await fetchApi(`ico/holders`)
        const profileCount = await fetchApi('profile/counts/total', {})
        const activeAccounts = await fetchApi('rate', {})

        setState({
          active: { dayChange: dayAccounts.change, total: accounts.rate },
          total: { dayChange: dayAccounts.change, total: accounts.rate },
        })
        setActiveProfilesCount(activeAccounts.onboardedProfiles.length)
        setProfileCount(profileCount)
        setCdoHolders(cdoHolders?.length)
      }),
    []
  )

  useAsyncEffect(
    () =>
      loaded(async () => {
        const data = await fetchApi(`profile/page/1`)

        /* empty `address` helper-property fixup */
        data.profiles.forEach((datum: any) => (datum.address = datum.address_?.[0]))

        setData(data.profiles)
        setNumOfPages(data.totalPages)
      }),
    []
  )

  const handlePageChange = async (page: number) =>
    loaded(async () => {
      setCurrentPage(page)
      const data = await fetchApi(`profile/page/${page}`)

      /* empty `address` helper-property fixup */
      data.profiles.forEach((datum: any) => (datum.address = datum.address_?.[0]))

      setData(data.profiles)
      setNumOfPages(data.totalPages)
    })

  const [showUserPopup, setShowUserPopup] = useState<`0x${string}`[] | undefined>(undefined)

  const handleOnHover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(address)
    }, 100)
  }

  const handleOnUnhover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(undefined)
    }, 100)
  }

  const handleCopyLink = async (address: address) => {
    await copyToClipboard(`${document.location.host}/profile/${address}`)
    toast({ title: `Copied!` })
  }

  const renderTableHeaders = () =>
    ['Wartość', 'Profil', 'Nick', 'Data dołączenia', 'Śledzący', 'Adres'].map(title => (
      <Th
        key={title}
        border="0px"
        fontSize="10px"
        color="textSecondary"
      >
        {title}
      </Th>
    ))

  interface ITableCell {
    id: string
    props: HTMLChakraProps<'td'>
    children: React.ReactNode
  }

  const renderTableContent = () => {
    return data.map((datum, index: number) => (
      <Tr
        key={datum.address}
        fontSize="14px"
        maxW="100%"
      >
        {/* TODO: Factor this out (this impacts performance) */}
        {(() => {
          const cellData: ITableCell[] = [
            {
              id: 'rank',
              props: {
                color: `color-mix(in srgb, ${getColor('_accentSecondary')} ${isFirstPage ? (index + 1) * 10 : 100}%, ${getColor('_successPrimary')})`,
                border: '0px',
              },
              children: (
                <Flex>
                  <Text
                    sx={{
                      fontSize: '24px',
                      fontWeight: '600',
                      ...(!datum.power
                        ? {
                            opacity: 0.33,
                          }
                        : {
                            textShadow: `0 0 15px ${getColor('_successPrimary', isFirstPage ? (10 - index) / 10 : 0)},
                    0 0 33px ${getColor('_successPrimary', isFirstPage ? (10 - index) / 10 : 0)},
                    0 0 45px ${getColor('_successPrimary', isFirstPage ? (10 - index) / 10 : 0)},
                    0 0 100px ${getColor('_successPrimary', isFirstPage ? (10 - index) / 10 : 0)},
                    0 0 100px ${getColor('_successPrimary', isFirstPage ? (10 - index) / 10 : 0)}`,
                          }),
                    }}
                  >
                    $
                  </Text>
                  <Text sx={{ fontSize: '24px', fontWeight: '600', color: '_textPrimary' }}>
                    {datum.power
                      ? `${formatUsd(formatUnits(BigInt(datum.power.value || /* fixup for bad db data; FIXME: */ 0), datum.power.decimals))}`
                      : ''}
                  </Text>
                </Flex>
              ),
            },
            {
              id: 'profile',
              props: {
                color: '_accentPrimary',
                border: '0px',
                position: 'relative',
                minW: '220px',
              },
              children: (
                <Link
                  to={`/profile/${datum.address}`}
                  style={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                  onMouseEnter={() => handleOnHover(datum.address_)}
                  onMouseLeave={() => handleOnUnhover(datum.address_)}
                >
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    w="40px"
                    h="40px"
                    bgColor="bluePlaceholder"
                    color="_textQuaternary"
                    borderRadius="50%"
                    backgroundImage={buildIpfsGateway(datum.avatar)}
                    backgroundPosition="center center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    userSelect="none"
                    position="relative"
                  >
                    {(!datum.avatar || datum.avatar.length < 35) && (
                      <Text textTransform="uppercase">{getInitials(datum.name)}</Text>
                    )}
                  </Box>
                  <Text color={datum.name ? 'textPrimary' : 'textTertiary'}>{datum.name || 'Bez nazwy'}</Text>
                </Link>
              ),
            },
            {
              id: 'nickname',
              props: { color: '_accentSecondary', border: '0px' },
              children: datum.nickname ? <Text>@{datum.nickname}</Text> : <Text color="textTertiary">Inactive</Text>,
            },
            {
              id: 'date',
              props: {
                color: 'textSecondary',
                border: '0px',
                minW: '150px',
              },
              children: moment(datum._created_at).fromNow(),
            },
            {
              id: 'followers',
              props: { color: '_accentSecondary', border: '0px' },
              children: (
                <>
                  {!!datum.followers?.length && <Text>{datum.followers.length}</Text>}
                  {!!datum.followers?.length || <Text opacity=".33">-</Text>}
                </>
              ),
            },
            {
              id: 'address',
              props: { color: '_accentPrimary', border: '0px' },
              children: (
                <Tooltip
                  hasArrow
                  label="Copy profile link"
                >
                  <Flex
                    onClick={() => handleCopyLink(datum.address!)}
                    cursor="pointer"
                    w="auto"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="borderPrimary"
                    p="6px 8px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      color="_accentPrimary"
                      mx="4px"
                    >
                      {trimAddress(datum.address)}
                    </Text>
                    <Box
                      bgColor="borderPrimary"
                      borderRadius="4px"
                      w="20px"
                      h="20px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image src="/assets/icons/link-icon.svg" />
                    </Box>
                  </Flex>
                </Tooltip>
              ),
            },
          ]

          return cellData.map(cell => (
            <Td
              key={cell.id}
              {...cell.props}
            >
              {cell.children}
            </Td>
          ))
        })()}
      </Tr>
    ))
  }

  return (
    <Flex
      justify="center"
      w="100%"
      direction="column"
      bg="backgroundSecondary"
    >
      <Text
        pt="16px"
        pb="24px"
        fontSize="24px"
        color="textPrimary"
        fontWeight="600"
        mx={{ base: '16px', degen: 'unset' }}
      >
        Ranking
      </Text>
      <Flex
        w="100%"
        justifyContent="space-between"
        pb="32px"
        gridGap="24px"
        direction={{ base: 'column', lg: 'row' }}
      >
        {infoProfiles.map((item: any) => (
          <ProfilesInfo
            key={item.title}
            title={item.title}
            numbers={item.number}
            percent={item.percent}
            subTitle={item.subTitle}
            subInfo={item.subInfo}
          />
        ))}
      </Flex>
      <CardContainer props={{ overflow: 'scroll' }}>
        {/* <TableContainer w="100%"  maxW="100vw"> */}
        <Table variant="simple">
          <Thead
            bgColor="backgroundMain"
            borderRadius="20px"
          >
            <Tr borderRadius="8px">{renderTableHeaders()}</Tr>
          </Thead>
          <Tbody alignItems="center">{renderTableContent()}</Tbody>
        </Table>
        {!data.length && (
          <Flex
            height="200px"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Spinner size="xl" />
          </Flex>
        )}
        <Flex
          w="100%"
          justify="center"
          gap="8px"
        >
          {/* @ts-ignore */}
          <Pagination
            pagesCount={numOfPages}
            currentPage={currentPage}
            // normalStyles={normalStyles}
            // activeStyles={activeStyles}
            // separatorStyles={baseStyles}
            onPageChange={(p: number) => handlePageChange(p)}
          >
            <PaginationContainer
              align="center"
              justify="space-between"
              w="auto"
              p={4}
              gap="4px"
            >
              <PaginationPrevious
                border="none"
                maxH="32px"
                maxW="32px"
                minH="32px"
                minW="32px"
                p="0"
              >
                <Image
                  src="/assets/icons/chevron-right.svg"
                  transform="rotate(180deg)"
                />
              </PaginationPrevious>
              <PaginationPageGroup
                align="center"
                separator={
                  <PaginationSeparator
                    isDisabled
                    bg="_accentSecondaryLight"
                    border="none"
                    fontSize="sm"
                    w="32px"
                    h="32px"
                    jumpSize={11}
                  />
                }
              >
                {pages.map((page: number) => (
                  <PaginationPage
                    w="32px"
                    h="32px"
                    border="none"
                    bg="none"
                    key={`pagination_page_${page}`}
                    page={page}
                    fontSize="sm"
                    color="_textSecondary"
                    _hover={{
                      bg: '_accentSecondaryLight',
                    }}
                    _current={{
                      bg: '_accentSecondaryLight',
                      fontSize: 'sm',
                      color: '_accentPrimary',
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                border="none"
                maxH="32px"
                maxW="32px"
                minH="32px"
                minW="32px"
                p="0"
              >
                <Image src="/assets/icons/chevron-right.svg" />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Flex>
        {/* </TableContainer> */}
      </CardContainer>
    </Flex>
  )
}

export default Profiles
