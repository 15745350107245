enum EAuthType {
  Local,
}

const getDegenPlatformJwtAuthKey = (address: address) =>
  `jwt-degenplatform-${address ? address.toLowerCase() : 'invalid'}`

const getDegenJwtAuthKey = (address: address) => `jwt-degen-access-${address ? address.toLowerCase() : 'invalid'}`
const getDegenJwtRefreshKey = (address: address) => `jwt-degen-refresh-${address ? address.toLowerCase() : 'invalid'}`

const getAuthHeaders = (address: address, type = EAuthType.Local) => {
  switch (type) {
    case EAuthType.Local:
      return {
        authorization: JSON.stringify({
          address,
          token: process.env.REACT_APP_DEGEN_ADMIN_KEY || localStorage.getItem(getDegenPlatformJwtAuthKey(address)),
        }),
      }
  }
}

const getBlockedHeaders = (addresses: address[]) => ({
  block: JSON.stringify(addresses),
})

const parseNonce = (nonce: string) => `Sign to prove you are you to Degen

${nonce}`

const shouldReconnect = localStorage.getItem('shouldReconnect') === 'true'
const setShouldReconnect = (value: boolean) => {
  localStorage.setItem('shouldReconnect', value ? 'false' : 'false')
  localStorage.setItem('shouldConnectWallet', value ? 'false' : 'false')
}

export {
  EAuthType,
  getAuthHeaders,
  getBlockedHeaders,
  parseNonce,
  getDegenPlatformJwtAuthKey,
  getDegenJwtAuthKey,
  getDegenJwtRefreshKey,
  shouldReconnect,
  setShouldReconnect
}
