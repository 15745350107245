import { Flex } from '@chakra-ui/layout'
import { SwapProvider } from '../../../../lib/dex-overlay/components/SwapProvider'
import { Graph } from '../Graph'
import { Swap } from '../Swap'
import { Search } from '../Search'

export const SwapWindow = () => {
  return (
    <Flex
      direction="column"
      sx={{
        flexGrow: 1,
        gap: '16px',
        h: { base: 'calc(100dvh - 50px - 54px)', sm: 'calc(100dvh - 50px - 54px - 42px)', md: 'unset' },
        scrollSnapAlign: 'center',
      }}
    >
      <Flex
        justify="center"
        align="center"
        sx={{
          height: '100%',
          w: '100%',
          borderRadius: { base: '0', md: '16px' },
          color: '_accentPrimary',
          overflow: 'hidden',
        }}
      >
        <Graph />
      </Flex>
      <Search />
      <Flex
        justify="center"
        align="end"
        sx={{
          height: '50%',
          w: '100%',
          borderRadius: '16px',
        }}
      >
        <SwapProvider>
          <Swap />
        </SwapProvider>
      </Flex>
    </Flex>
  )
}
