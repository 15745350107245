import { useMemo, useState } from 'react'
import { emptyProfile, IProfile } from '../Profile/profileProvider'

import React, { useEffect } from 'react'
import { useAccountProfileProvider } from '../AccountProfile/context'
import { fetchProfile, fetchProfiles } from '../../utils/degen'
import uniqBy from 'lodash/uniqBy'

export interface IProfilesProvider {
  retrieve: (address: address, options?: { refetch?: boolean; doNotFetch?: boolean }) => Promise<IProfile>
  refresh: (ids: address[]) => Promise<void>
  cacheIndices: address[]
}

export const emptyProfiles: IProfilesProvider = {
  retrieve: async () => emptyProfile,
  refresh: async () => {},
  cacheIndices: [],
}

export const ProfilesProvider = ({ children }: { children: React.ReactNode }) => {
  const accountProfile = useAccountProfileProvider()
  const [data, setData] = useState<Record<address, IProfile>>({})

  const cacheIndices = useMemo(() => Object.keys(data) as address[], [JSON.stringify(Object.keys(data))])

  /**
   * Retrieves a single profile data (fetch & cache or get from cache)
   * @param address user id
   * @param options whether to flush cache beforehand
   * @param options whether not to fetch if not in cache in order to hand over fetching to the caller
   */
  const retrieve = async (
    address: address,
    options?: { refetch?: boolean; doNotFetch?: boolean }
  ): Promise<IProfile> => {
    const _address = address.toLowerCase() as address

    if (options?.refetch || !data[_address]) {
      if (options?.doNotFetch) {
        return new Promise(() => {})
      }

      // TODO: migrate to tanstack query and set cache time to 5 min
      const profile = await fetchProfile(_address)

      setData(state => ({
        ...state,
        [_address.toLowerCase()]: profile,
      }))
      return profile
    }

    return data[_address]
  }

  const refresh = async (ids: address[]) => {
    const _ids = ids.map(address => address.toLowerCase() as address)

    const profiles: IProfile[] = await fetchProfiles(_ids)
    const profilesParsed = uniqBy(profiles, element => element.address?.toLowerCase())
      /* keep only entries not fetched yet */
      .filter(profile => !data[profile.address?.toLowerCase()!])
      .reduce((accumulator, profile) => {
        accumulator[profile.address?.toLowerCase()!] = profile
        return accumulator
      }, {})
    const emptyProfiles = _ids.reduce((accumulator, id) => {
      accumulator[id] = emptyProfile
      return accumulator
    }, {})

    setData(state => ({
      ...emptyProfiles,
      ...state,
      ...profilesParsed,
    }))
  }

  /** Auto-init connected wallet profile; this fetches settings, etc. */
  useEffect(() => {
    if (!accountProfile.address || accountProfile.isLoading) {
      return
    }

    setData(state => ({
      ...state,
      [accountProfile.address?.toLowerCase()!]: accountProfile,
    }))
  }, [accountProfile.address, accountProfile.isLoading])

  return (
    <ProfilesContext.Provider
      value={{
        retrieve,
        refresh,
        cacheIndices,
      }}
    >
      {children}
    </ProfilesContext.Provider>
  )
}

const ProfilesContext = React.createContext(emptyProfiles)

export const useProfilesProvider = () => {
  const context = React.useContext(ProfilesContext)

  if (!context) {
    throw new Error('`useProfilesProvider` cannot be used outside of a `ProfilesProvider`!')
  }
  return context
}
