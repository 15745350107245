import { Flex, useToast } from '@chakra-ui/react'
import { UserHeader } from '../components/pages/User/UserHeader/userHeader'
import UserContent from '../components/pages/User/UserContent/UserContent'
import { ProfileProvider } from '../provider/Profile/profileProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useProfile } from '../hooks/useProfile'
import { useEffect, useState } from 'react'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'
import { AddressZero } from '../utils/ethereum'
import { UserNfts } from '../components/pages/User/UserNfts/UserNfts'

export const UserPage = () => {
  const { account, isAuthed } = useEthereumProvider()
  const [isOnboarded, setIsOnboarded] = useState(true)

  const { id } = useParams()
  const profile = useProfile({ address: id as address })
  const toast = useToast()
  const navigate = useNavigate()

  const shouldShowOnboarding =
    account == id?.toLowerCase() && !false && !profile.isLoading && !profile.isOnboarded && !isOnboarded

  const close = async () => {
    setIsOnboarded(true)
    profile.isOnboarded = true
  }

  useEffect(() => {
    if (!id || !profile.address || id?.toLowerCase() !== account?.toLowerCase()) {
      return
    }

    setIsOnboarded(profile.isOnboarded)
  }, [profile.isOnboarded, id, profile.address, account])

  /** Check for empty account */
  useEffect(() => {
    if (isAuthed && id?.toLowerCase() === AddressZero) {
      navigate(`/profile/${account}`)

      return
    }

    if (id !== AddressZero || account || toast.isActive('error_no_account')) {
      return
    }

    toast({ title: 'Log in to see your profile', id: 'error_no_account', duration: null })

    return () => {
      toast.close('error_no_account')
    }
  }, [id, account, isAuthed])

  return (
    <ProfileProvider value={profile}>
      <Flex
        bg="backgroundSecondary"
        justify="center"
        w="100%"
        overflow="visible"
      >
        <Flex
          flexWrap="wrap"
          direction="column"
          gap="24px"
          w="100%"
        >
          <UserHeader />
          <UserNfts />
          <UserContent />
        </Flex>
      </Flex>
    </ProfileProvider>
  )
}
