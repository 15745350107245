export enum ContentFilter {
  ALL,
  TEXT,
  PHOTO,
  VIDEO,
}

export enum TimeAndTarget {
  NEW /* Trending */,
  FOR_YOU,
  FOLLOWING,
  '24H',
  '7D',
  POWERFUL,
}

interface IFilterState {
  type: ContentFilter
  timeAndTarget: TimeAndTarget
}

interface IDispatchAction {
  type: string
  payload: any
}

export const defaultState: IFilterState = { type: ContentFilter.ALL, timeAndTarget: TimeAndTarget.POWERFUL }

export const filterReducer = (state: IFilterState, action: IDispatchAction) => {
  if (action.type === 'FILTER_BY_CONTENT') {
    return { ...state, type: action.payload }
  }
  if (action.type === 'FILTER_BY_TIME_AND_TARGET') {
    return { ...state, timeAndTarget: action.payload }
  }
  return defaultState
}
