import { color } from '@chakra-ui/react'
import { Colors } from './colors'

export const getTheme = () => document.documentElement.getAttribute('data-theme') as 'dark' | 'light' | undefined

/**
 * @param opacity [0-1]
 */
export const getColor = (color: keyof typeof Colors | string, opacity: number = 1) => {
  /* catch 'already parsed' case */
  if (color.startsWith('#') && color.length === 9) {
    return color
  }

  return `${Colors[color!]?.[getTheme() === 'dark' ? '_dark' : 'default'] || color}${Math.floor(opacity * 255)
    .toString(16)
    .padStart(2, '0')}`
}

export const globalStyles = {
  global: {
    'html, body': {
      margin: 0,
      padding: 0,
      background: '_backgroundPrimary',
      //@ts-ignore
      WebkitTapHighlightColor: 'transparent',
      //@ts-ignore
      WebkitFontSmoothing: 'antialiased !important',
      //@ts-ignore
      MozOsxFontSmoothing: 'grayscale !important',
      //@ts-ignore
      fontSmooth: 'never !important',
      fontFamily: 'Nippo-Variable',
      color: '_textPrimary',
      caretColor: '_successPrimary',
    },
    a: {
      borderRadius: '8px',
    },
    'a:focus-visible': {
      textDecoration: 'none',
      color: '_accentPrimary',
      outlineColor: '_accentSecondary !important',
    },
    'input:not([type]), input[type="text"]': {
      borderColor: 'auto',
    },
    button: {
      border: 0,
    },
    'h1, h2, h3, h4, h5, h6': {
      margin: 0,
    },
    ul: {
      padding: 0,
    },
    _focus: {
      outline: 'none !important',
    },
    _focusVisible: {
      boxShadow: 'none !important',
      outlineOffset: '0 !important',
      outline: '2.5px solid _accentSecondary !important',
    },
    '.chakra-input:focus-visible': {
      borderColor: 'transparent !important',
    },
    // '.chakra-toast': {
    // todo: media query for 2xl (get the pixels)
    //   zIndex: '_accentSecondary',
    // },
    img: {
      userSelect: 'none !important',
    },
    'div.wcm-dark': {
      display: 'none !important',
    },
    '.chakra-toast .chakra-spinner': {
      color: 'backgroundMain',
    },
    div: {
      borderColor: 'borderPrimary',
    },
    '.chakra-alert': {
      borderRadius: '16px !important',
    },
  },
}
