import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Text,
  useToast,
} from '@chakra-ui/react'
import { GroupCreatorStep, useGroupCreatorProvider } from '../../../../provider/GroupCreator/groupCreatorProvider'
import merge from 'lodash/merge'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { useEffect } from 'react'

export const GroupNameModal = () => {
  const groupCreator = useGroupCreatorProvider()
  const toast = useToast()
  const wallet = useEthereumProvider()

  const handleCreate = () => {
    if (groupCreator.data.metadata?.name?.length === 0 || !groupCreator.data.metadata?.name) {
      toast({ status: 'error', title: 'Specify a name to create a group.' })
    } else {
      groupCreator.setStep(GroupCreatorStep.CreateToken)
    }
  }

  useEffect(() => {
    if (wallet.account) {
      return
    }

    !toast.isActive('info_connect_wallet') &&
      toast({
        status: 'info',
        title: 'To create a group, you need to connect your wallet first',
        id: 'info_connect_wallet',
      })

    groupCreator.onClose()
  }, [wallet.account])

  return (
    <Modal
      size="lg"
      onClose={groupCreator.onClose}
      isOpen={groupCreator.isOpen}
      isCentered
    >
      <ModalContent
        borderRadius="16px"
        overflow="hidden"
      >
        <ModalBody
          p="0"
          m="0"
        >
          <Flex
            width="100%"
            zIndex="0"
          >
            <Image
              src="/assets/images/CreateGroupHeader.svg"
              filter="blur(25px); scale(2)"
              scale="2.0"
              zIndex="0"
              height="180px"
            />
          </Flex>
          <Flex
            width="84px"
            height="84px"
            position="absolute"
            background="backgroundMain"
            borderRadius="100%"
            alignItems="center"
            justifyContent="space-around"
            top="70px"
            left="30px"
          >
            <Image
              src="/assets/icons/multicolor-people-group.svg"
              width="48px"
              height="48px"
            />
          </Flex>
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="flex-start"
            gap="24px"
            paddingTop="0px"
            pl="48px"
            pb="48px"
            pr="48px"
          >
            <Flex direction="column">
              <Text
                color="textQuaternary"
                fontSize="18px"
                fontWeight="600"
                zIndex="100"
              >
                Create new group
              </Text>
              <Text
                color="textSecondary"
                zIndex="100"
                fontSize="14px"
              >
                Set a group name and start a new initiative
              </Text>
            </Flex>
            <Flex
              width="100%"
              gap="16px"
            >
              <Input
                placeholder="Group name"
                fontSize="14px"
                value={groupCreator.data.metadata?.name || ''}
                onChange={event => {
                  groupCreator.dispatch({ metadata: merge(groupCreator.data.metadata, { name: event.target.value }) })
                }}
              />
              <Button
                variant="dark"
                sx={{ px: '32px', py: '12px' }}
                onClick={handleCreate}
              >
                Create
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderTop="1px solid borderPrimary"
        >
          <Text
            color="textSecondary"
            fontSize="12px"
          >
            Close this if you are not ready
          </Text>
          <Button onClick={groupCreator.onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
