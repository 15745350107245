import React, { useRef } from 'react'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { buildIpfsGateway, getInitials } from '../../../../utils/parser'
import { emptyPostContent, usePost } from './usePost'
import { useDropzone } from 'react-dropzone'
import { Box, Button, Flex, Image, Input, Tooltip, useDisclosure } from '@chakra-ui/react'
import { QuickPostModal } from './modal/QuickPostModal'
import { IPostSchema } from '../../../pages/Home/MiddlePanel/deafult/Default'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'

interface IProps {
  resultHandle?: (post: IPostSchema) => void
  isHome?: boolean
  group?: address
}

export const QuickPost = ({ resultHandle, isHome, group }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const accountProfile = useAccountProfileProvider()
  const wallet = useEthereumProvider()
  const post = usePost()
  const dropzoneRef = useRef(null!)
  const input = useRef<HTMLInputElement>(null!)

  const handlePost = async () => {
    const result = await post.post(post.content)
    if (!result) {
      return
    }

    console.log('resulting with', result.data)
    resultHandle?.(result.data)

    post.setContent(emptyPostContent)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop: post.uploadAssets })

  const handlePicker = () => {
    if (dropzoneRef.current) {
      // @ts-ignore
      dropzoneRef.current.click()
    }
  }

  const handleContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) onOpen()
    if (e.target.value.length === 0) onClose()
    post.setContent(prev => ({ ...prev, text: e.target.value }))
  }

  const removeFile = (idx: number) => {
    const newImages = post.content.images.filter((_, index: number) => index != idx)
    post.setContent(prev => ({ ...prev, images: newImages }))
  }

  const handleInitInput = () => {
    input.current.focus()

    if (!post.isPosting && (post.content.text.length || post.content.images.length)) {
      onOpen()
    }
  }

  // if (!wallet.isLoggedIn) {
  //   return null
  // }

  return (
    <Flex
      {...getRootProps({ onClick: event => event.stopPropagation() })}
      borderRadius={{ base: '0', md: '16px' }}
      border="1px solid"
      borderX={{ base: 'none', md: '1px solid' }}
      borderColor="borderPrimary"
      borderLeftColor="borderPrimary !important"
      borderRightColor="borderPrimary !important"
      w="100%"
      p="16px"
      mb={isHome ? '24px' : '0'}
      justifyContent="space-between"
      gridGap="8px"
      flexDir="row"
      align="center"
      cursor="text"
      onClick={handleInitInput}
      bgColor="backgroundMain"
    >
      <QuickPostModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        post={post}
        resultHandle={resultHandle}
        pickFile={handlePicker}
        removeFile={removeFile}
        group={group}
      />
      <Flex
        alignItems="center"
        gridGap="16px"
        w="100%"
      >
        <Flex>
          <Box
            textTransform="uppercase"
            fontSize="12px"
            color="#6f5a06"
            display="flex"
            borderRadius="50%"
            bgColor="#ECDFD3"
            h="32px"
            w="32px"
            alignItems="center"
            justifyContent="center"
            backgroundImage={buildIpfsGateway(accountProfile.avatar)}
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          >
            {accountProfile.avatar ? '' : getInitials(accountProfile.name)}
          </Box>
        </Flex>
        <Input
          ref={input}
          disabled={post.isPosting}
          value={post.content.text}
          onChange={handleContent}
          color="_textPrimary"
          p="0px"
          h="20px"
          fontSize="14px"
          placeholder={`Co się dzieje${accountProfile.name ? `, ${accountProfile.name.split(/[ ,]+/)[0]}` : ''}?`}
          _placeholder={{ color: 'textTertiary' }}
          border="none"
          sx={{
            borderRadius: '0'
          }}
          _focus={{
            boxShadow: 'none',
            border: 'none !important',
            outline: 'none !important',
          }}
        />
      </Flex>
      <Flex
        gridGap="8px"
        flexShrink="0"
        align="center"
      >
        <Flex
          gap="4px"
          flexShrink="0"
          align="center"
        >
          <Button
            variant="slave"
            p="8px"
            onClick={(e: any) => {
              handlePicker()
              e.stopPropagation()
            }}
          >
            <input
              /* @ts-ignore */
              {...getInputProps({ ref: dropzoneRef })}
              onClick={event => event.stopPropagation()}
            />
            <Image src="/assets/icons/camera-icon.svg" />
          </Button>
        </Flex>
        <Tooltip
          hasArrow
          label={!wallet.isLoggedIn ? 'Nie jesteś zalogowany' : ''}
        >
          <Button
            variant="none"
            height="auto"
            color="#000000"
            bgColor="_accentPrimary"
            fontSize="12px"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            p="8px 16px"
            isLoading={post.isPosting || post.isAssetLoading}
            isDisabled={!wallet.isLoggedIn}
            onClick={(e: any) => {
              handlePost()
              e.stopPropagation()
            }}
            display={{ base: 'none', md: 'flex' }}
          >
            {!!post.content.transfer ? 'Transferuj' : 'Publikuj'}
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  )
}
