import { Flex, Spinner } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { getBlockedHeaders } from '../../../../../utils/auth'
import { fetchApi } from '../../../../../utils/fetcher'
import { loaded } from '../../../../../utils/process'
import { IPostSchema } from '../../../Home/MiddlePanel/deafult/Default'
import { Post } from '../../../Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import { GroupContentSearch } from '../../GroupContentSearch'

export const Offers = () => {
  const [posts, setPosts] = useState<IPostSchema[]>([])
  const [arePostsLoading, setArePostsLoading] = useState(true)
  const { id } = useParams()
  const [filteredPosts, setFilteredPosts] = useState<IPostSchema[]>(posts)

  const account = useAccountProfileProvider()

  const handleSearch = (e: any) => {
    const filteredPosts = posts.filter((post: IPostSchema) => {
      if (post.text !== undefined)
        return (
          post.author?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          post!.text.toLowerCase().includes(e.target.value.toLowerCase())
        )
      else return post.author?.toLowerCase().includes(e.target.value.toLowerCase())
    })
    setFilteredPosts(filteredPosts)
  }

  useAsyncEffect(async () => {
    await loaded(async () => {
      const res = await fetchApi(
        'post/getGroups',
        { groups: [id], filters: ['quest'] },
        {
          headers: getBlockedHeaders(account.blockedUsers),
        }
      )

      setPosts(res)
    }, setArePostsLoading)
  }, [])

  return (
    <Flex sx={{ flexDir: 'column', gap: '16px', alignItems: 'center' }}>
      <GroupContentSearch
        placeholder="Search for offers"
        searchAction={handleSearch}
      />
      {arePostsLoading ? (
        <Spinner />
      ) : (
        filteredPosts?.map((post: IPostSchema) => (
          <Post
            key={post.ipfs}
            data={post}
          />
        ))
      )}
    </Flex>
  )
}
