import { Flex, Image, Text, Select as ChakraSelect, Box, Tooltip, Spinner } from '@chakra-ui/react'
import { useState } from 'react'
import { useSwapProvider } from '../../../../../../lib/dex-overlay/components/SwapProvider'

/* Base tokens. Rest of the tokens supported will be scanned through
the user's address tokens by Moralis (and this is done by the swap
  provider) – TODO: Factor out to the token provider */
type EBaseToken = 'USDC' | 'ETH' | 'WETH' | string

export const Switcher = () => {
  const swap = useSwapProvider()

  /** Token ids (not addresses as these can differ cross-chain).
   *  TODO: Factor out to the token provider as `externalTokens`
   * (i.e. tokens that are available/supported). */
  const [tokens, setTokens] = useState<string[]>(['USDC'])

  const handleSetExternalToken = (id: EBaseToken) => {}

  return (
    <Tooltip
      label={
        <Image
          src="/assets/icons/chevron-down-small.svg"
          onClick={() => null}
          sx={{
            w: '16px',
            h: '20px',
          }}
        />
      }
    >
      <Flex
        align="center"
        justify="center"
        gap="10px"
        sx={{
          position: 'relative',
          gap: '10px',
          boxSize: '40px',
          borderRadius: 'full',
          flexShrink: 0
        }}
      >
        <Image
          src={swap.tokens.external.iconUri}
          fallback={<Spinner />}
          sx={{
            boxSize: '40px',
          }}
        />
        <Box
          sx={{
            w: '100%',
            h: 'calc(100% + 2px)',
            position: 'absolute',
            zIndex: '1',
            borderRadius: '30px',
          }}
          onClick={(e: any) => {
            e.stopPropagation()
          }}
        >
          <ChakraSelect
            fontSize="14px"
            _focus={{ outline: 'none' }}
            sx={{
              outline: 'none !important',
              padding: '0',
              '+.chakra-select__icon-wrapper': {
                display: 'none',
              },
              '&:first-of-type': {
                fontSize: 0,
              },
              border: 'none',
              borderRadius: 'full',
              cursor: 'pointer',
            }}
            onChange={event => handleSetExternalToken(event.target.value as EBaseToken)}
          >
            {tokens.map((token, idx) => (
              <option
                key={`${token}-${idx}`}
                value={token}
              >
                {token}
              </option>
            ))}
          </ChakraSelect>
        </Box>
      </Flex>
    </Tooltip>
  )
}
