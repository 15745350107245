import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import SpinnerText from '../../../tags/SpinnerText'
import { IUsePost } from '../usePost'
import { Select, Type } from '../../../select/Select'
import { getColor } from '../../../../../theme/global'

interface IRecipentModalProps {
  isOpen: boolean
  onClose: () => void
  openParent: () => void
  count?: number
  post: IUsePost
}

export const RecipientsModal: FC<IRecipentModalProps> = ({ isOpen, onClose, openParent, count, post }) => {
  const [sliderValues, setSliderValues] = useState<number[]>([16, 45])
  const [roles, setRoles] = useState<string[]>([])
  const [interests, setInterest] = useState<string[]>([])

  const handleSlider = (values: number[]) => {
    setSliderValues([values[0], values[1]])
  }

  const handleSave = () => {
    openParent()
    onClose()
  }

  useEffect(() => {
    post.setContent(prev => ({ ...prev, target: { roles, interests } }))
  }, [interests, roles])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        openParent()
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        marginBottom={{ base: '10px', md: '64px' }}
        bgColor="backgroundMain"

        borderRadius="16px"
        p="0"
        mx="10px"
      >
        <ModalHeader alignItems="center">
          Recipients
          <ModalCloseButton mt="10px" />
        </ModalHeader>
        <Box
          h="1px"
          w="100%"
          bgColor="_borderPrimary"
        />
        <ModalBody p="16px 24px">
          <Flex
            flexDir="column"
            fontSize="14px"
            fontWeight="500"
            color="_accentSecondary"
            gap="24px"
          >
            <Select
              placehodler="Type role"
              type={Type.ROLES}
              title="Choose roles"
              items={roles}
              setItems={setRoles}
              fetch={() => null as any}
              buttonText="Add role"
            />
            <Select
              placehodler="Type interest"
              type={Type.INTERESTS}
              title="Choose interests"
              items={interests}
              setItems={setInterest}
              fetch={() => null as any}
              buttonText="Add interest"
            />
          </Flex>
        </ModalBody>
        <Box
          h="1px"
          w="100%"
          bgColor="_borderPrimary"
        />
        <ModalFooter>
          <Flex
            justify="space-between"
            align="center"
            w="100%"
          >
            <Flex
              align="center"
              gap="8px"
            >
              <Text color="_accentSecondary">{count ?? <SpinnerText />}</Text>
              <Text color="textQueternary">Recipients</Text>
            </Flex>
            <Flex
              align="center"
              gap="8px"
            >
              <Button
                borderRadius="8px"
                border="1px solid"
                borderColor="_borderPrimary"
                gridGap="8px"
                p="8px 16px"
                alignItems="center"
                bg="none"
                onClick={() => {
                  onClose()
                  openParent()
                }}
              >
                Cancel
              </Button>
              <Button
                borderRadius="8px"
                border="1px solid"
                borderColor="_borderPrimary"
                gridGap="8px"
                p="8px 16px"
                alignItems="center"
                onClick={handleSave}
                variant="dark"
              >
                Save changes
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
