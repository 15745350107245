import { Box, Flex } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'

export const PumpWindow = ({ handleHide }: { handleHide: () => void }) => {
  return (
    <Flex
      direction="column"
      sx={{
        gap: '16px',
        flexGrow: 1,
        h: { base: 'calc(100dvh - 50px - 54px)', sm: 'calc(100dvh - 50px - 54px - 42px)', md: 'unset' },
        scrollSnapAlign: 'center',
      }}
    >
      <Button onClick={handleHide}>&lt;- Wróć</Button>
      <Box
        sx={{
          boxSize: '100%',
          transition: 'filter 3s',
          borderRadius: '16px',
          overflow: 'hidden',
        }}
      >
        <iframe
          id="degen-pump-widget"
          title="Degen Pump widget"
          width="100%"
          height="100%"
          loading="lazy"
          src="https://pump.degen.pl"
        />
      </Box>
    </Flex>
  )
}
