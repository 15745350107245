import { useEffect, useState } from 'react'
import { useAppAccount } from '../../provider/Account/useAppAccount'
import { loaded } from '../../utils/process'
import { getAuthHeaders } from '../../utils/auth'
import { useAccount } from 'wagmi'
import { getNetwork } from '@wagmi/core'
import { ENetwork } from '../../enum/network.enum'
import useNetworks from './useNetworks'
import axios from 'axios'
import { __log } from '../../logger'

export enum EError {
  PENDING,
  NO_SERVER,
  GENERIC,
  USER_REJECTION = 4001,
  USER_REQUEST_PENDING = -32603,
}

export const emptyEthereum: IUseEthereum = {
  isConnecting: false,
  setIsConnecting: () => null,
  connect: async () => undefined,
  disconnect: () => null as unknown as void,
  /** TODO: Deprecate in favor of useAccount().address */
  account: undefined,
  isAuthed: false,
  setIsAuthed: () => null,
  isAuthing: false,
  setIsAuthing: () => null,
  isSigning: false,
  setIsSigning: () => null,
  isNetworkSwitching: false,
  setIsNetworkSwitching: () => null,
  isLoggedIn: false,
  isSignInModalOpen: false,
}

export interface IUseEthereum {
  isConnecting: boolean
  setIsConnecting: any
  isNetworkSwitching: boolean
  setIsNetworkSwitching: any
  isAuthing: boolean
  setIsAuthing: any
  isAuthed: boolean
  setIsAuthed: any
  isSigning: boolean
  setIsSigning: any
  isLoggedIn: boolean
  connect: () => Promise<void | EError>
  disconnect: (account: address) => void
  /** TODO: Deprecate in favor of useAccount().address */
  account?: address
  isSignInModalOpen: boolean
}

const LOG_IN_DEGENPROTOCOL_INTERVAL = 1000 /* ms */ * 60 /* s */ * 60 /* min */ * 24 /* h */ * 6.5 /* d */
const LOG_IN_DEGEN_INTERVAL = 1000 /* ms */ * 60 /* s */ * 1.5 /* min */

export const useEthereum = (): IUseEthereum => {
  const ethereumAccount = useAccount()
  const appAccount = useAppAccount()
  const network = useNetworks()
  const { chain } = getNetwork()

  const [isConnecting, setIsConnecting] = useState(false)
  const [isNetworkSwitching, setIsNetworkSwitching] = useState(false)
  const [isAuthing, setIsAuthing] = useState(false)
  const [isAuthed, setIsAuthed] = useState(false)
  const [isSigning, setIsSigning] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [logInDegenPlatformInterval, setLogInDegenPlatformInterval] = useState<any>()

  /** TODO: Deprecate in favor of useAccount().address */
  const [account, setAccount] = useState<address>()
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)

  const disconnect = async (account: address) => {
    await appAccount.logOut(account)

    clearInterval(logInDegenPlatformInterval)

    setLogInDegenPlatformInterval(null)
  }

  const connect = async () => {
    let result: EError | undefined

    if (isConnecting) {
      return EError.PENDING
    }

    await loaded(
      async () => {
        const address = ethereumAccount.address!

        /** Check whether to launch onboarding */
        if (!address) {
          return
        }

        await loaded(
          async () => {
            await appAccount.logInDegenPlatform(address)

            setLogInDegenPlatformInterval(
              setInterval(async () => {
                await appAccount.logInDegenPlatform(address)
              }, LOG_IN_DEGENPROTOCOL_INTERVAL)
            )

            setIsAuthed(true)
          },
          setIsAuthing,
          (error: any) => (result = error)
        )

        /* TODO: Move to wallet provider */
        const isWrongNetwork = ENetwork.FALLBACK !== chain?.id

        /* Switch network to target (testnet BNB – dev environment) */
        if (!isNetworkSwitching && isWrongNetwork) {
          await network.switchNetwork(ENetwork.FALLBACK)
        }
      },
      setIsConnecting,
      (error: any) => (result = error)
    )

    return result
  }

  /** TODO: Deprecate in favor of useAccount().address */
  /** Sync evm account */
  useEffect(() => setAccount(ethereumAccount.address?.toLowerCase() as address | undefined), [ethereumAccount.address])

  /** Sync variables with wagmi account */
  useEffect(() => {
    if (!ethereumAccount.isDisconnected) {
      return
    }

    setIsConnecting(false)
    setIsAuthing(false)
    setIsAuthed(false)
  }, [ethereumAccount.isDisconnected])

  /* TODO: Fix, this could never be done in time */
  /** Sync auth headers */
  useEffect(() => {
    // TODO: Check for isAuthed / isConnected.
    axios.defaults.headers.common['authorization'] =
      isAuthing || isConnecting ? undefined : getAuthHeaders(account as address).authorization
  }, [account, isAuthing, isConnecting])

  /** Sync log in status */
  useEffect(() => setIsLoggedIn(!!account && isAuthed), [account, isAuthed])

  return {
    disconnect,
    connect,
    isConnecting,
    setIsConnecting,
    /** TODO: Deprecate in favor of useAccount().address */
    account,
    isAuthed,
    setIsAuthed,
    isAuthing,
    setIsAuthing,
    isSigning,
    setIsSigning,
    isNetworkSwitching,
    setIsNetworkSwitching,
    isLoggedIn,
    isSignInModalOpen,
  }
}

const log = (...message: any[]) => __log('Ethereum', ...message)
