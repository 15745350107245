import { Box, Flex, Image } from '@chakra-ui/react'
import SpinnerText from '../../../../shared/tags/SpinnerText'

export const InfoItem = ({
  icon,
  label,
  value,
  day,
  prefix = '',
}: {
  icon: string
  label: string
  value?: string | number
  day?: number
  prefix?: string
}) => {
  return (
    <Flex
      gap="12px"
      ml="12px"
      w="100%"
      justifyContent="space-between"
    >
      <Flex gridGap="16px">
        <Image
          boxSize="24px"
          src={icon}
        />
        <Flex
          flexDirection="column"
          gridGap="4px"
        >
          <Box
            color="#6F6A4F"
            fontSize="12px"
          >
            {label}
          </Box>
          <Box
            fontSize="18px"
            fontWeight="bold"
            color="textPrimary"
          >
            {prefix}
            {value ?? <SpinnerText />}
          </Box>
        </Flex>
      </Flex>
      <Flex
        flexWrap="wrap"
        flexDir="column"
        alignItems="flex-end"
        mr="12px"
        fontSize="12px"
        gridGap="8px"
      >
        <Box color="textTertiary">24h</Box>
        <Box color={(day ?? 0) >= 0 ? 'greenPrimary' : 'redPrimary'}>
          {(day ?? 0) >= 0 ? '+' : '-'}
          {prefix}
          {Math.abs(day ?? 0)}
        </Box>
      </Flex>
    </Flex>
  )
}
