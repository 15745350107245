import { useEffect, useMemo, useState } from 'react'
import { Box, Divider, Flex, Link as ChakraLink, Text, Tooltip } from '@chakra-ui/react'
import { CardContainer } from '../components/shared/containers/CardContainer'
import LeftPanelSingleTransaction from '../components/pages/Transaction/LeftPanel'
import { UserTag } from '../components/shared/tags/UserTag'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import useAsyncEffect from '../hooks/effects/async'
import { loaded } from '../utils/process'
import { fetchApi, fetcher } from '../utils/fetcher'
import { trimAddress } from '../utils/parser'
import SpinnerText from '../components/shared/tags/SpinnerText'
import moment from 'moment/moment'
import { formatUnits, parseUnits } from 'viem'
import { fetchTransaction } from '@wagmi/core'
import { _log } from '../logger'

const tabs = [{ id: 1, label: 'Overview' }]

export const Transaction = () => {
  const location = useLocation()

  const [isActive, setIsActive] = useState(1)
  const [data, setData] = useState<any>()
  const [bnbPrice, setBnbPrice] = useState<{ value: string; time: number }>()
  const [isLoading, setIsLoading] = useState(true)

  const { id: hash } = useParams()
  const { state: routeState } = location
  const navigate = useNavigate()

  const statusIsConfirmed = useMemo<boolean>(
    () => data?.confirmed || routeState?.artificial,
    [data?.confirmed, routeState?.artificial]
  )

  const handlerActive = (id: number) => {
    setIsActive(id)
  }

  useEffect(() => {
    _log('Data:', routeState?.artificial)
  }, routeState?.artificial)

  useAsyncEffect(async () => {
    if (!hash) {
      return
    }

    void loaded(async () => {
      try {
        /* If the transaction has been made up on frontend, fetch its data from blockchain manually */
        if (routeState?.artificial) {
          /* Reset `react-router-dom` state */
          navigate(`/tx/${hash}`, { state: {} })

          const raw = await fetchTransaction({
            hash: hash as txHash,
          })
          setData({
            ...(raw ?? {}),
            sender: raw.from,
            target: raw.to,
          })

          setIsLoading(false)
          return
        }

        const data = await fetchApi(`tx/${hash.toLowerCase()}`)

        /* Retry when server returns none (i.e. wait until the transaction is synced to the server) */
        if (data == '') {
          const interval = setInterval(() => {
            loaded(async () => {
              try {
                const data = await fetchApi(`tx/${hash.toLowerCase()}`)

                if (data) {
                  clearInterval(interval)
                  setIsLoading(false)
                }
                setData(data)
              } catch {
                setData(undefined)
              }
            })
          }, 500)
        } else {
          setIsLoading(false)
          setData(data)
        }
      } catch (e: any) {
        /* Transaction data fetch failed because there was no such transaction. */
        if (e?.name === 'TransactionNotFoundError') {
          setIsLoading(true)
        }

        setData(undefined)
      }
    })

    void loaded(async () => {
      const data = await fetcher('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')

      setBnbPrice({
        value: data?.price,
        time: Date.now(),
      })
    })
  }, [hash])

  return (
    <Flex
      // bg="backgroundMain"
      justify="center"
      w="100%"
      direction="column"
      bg="backgroundSecondary"
    >
      <Text
        pt="32px"
        pb="24px"
        fontSize="24px"
        color="textPrimary"
        fontWeight="600"
      >
        Transaction Details
      </Text>
      <CardContainer width="100%">
        <Flex
          p="8px 12px"
          flexDirection="column"
          gridGap="16px"
          flexWrap="wrap"
          maxW="80vw"
          overflow="hidden"
          overflowWrap="break-word"
          fontSize={{ base: '12px', lg: '14px' }}
        >
          <Flex
            gridGap="32px"
            fontSize="14px"
            w="100%"
          >
            {tabs.map(tab => (
              <Flex
                key={tab.id}
                position="relative"
                flexDirection="column"
                onClick={() => handlerActive(tab.id)}
              >
                <Text
                  cursor="pointer"
                  color={isActive === tab.id ? '_accentPrimary' : 'textSecondary'}
                  fontSize={{ base: '12px', lg: '14px' }}
                >
                  {tab.label}
                </Text>
                <Box
                  bgColor={isActive === tab.id ? '_accentPrimary' : 'transparent'}
                  position="absolute"
                  top="36px"
                  w="62px"
                  h="2px"
                />
              </Flex>
            ))}
          </Flex>
          <Box
            w="100%"
            h="1px"
            bgColor="borderPrimary"
          />
          <Flex
            p="16px 0px"
            flexDirection="column"
            gridGap="20px"
          >
            <Flex
              gridGap={{ base: '10px', lg: '24px' }}
              alignItems={{ base: 'flex-start', lg: 'center' }}
              flexDir={{ base: 'column', lg: 'row' }}
            >
              {/** TODO: copy button */}
              <LeftPanelSingleTransaction label="Transaction Hash:" />
              <Text>{isLoading ? location.pathname.split('/')[2] : data?.hash}</Text>
            </Flex>

            <Flex
              gridGap={{ base: '10px', lg: '24px' }}
              alignItems={{ base: 'flex-start', lg: 'center' }}
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <LeftPanelSingleTransaction label="Status:" />

              <UserTag
                text={statusIsConfirmed ? 'Success' : isLoading ? 'In progress' : 'Failure'}
                compact
                colorBg={statusIsConfirmed ? '#ECFDF3' : '#fdecec'}
                imageUri={`/assets/icons/${
                  statusIsConfirmed
                    ? 'success-result-icon.svg'
                    : isLoading
                    ? 'info-circle.svg'
                    : 'failure-result-icon.svg'
                }`}
              />
            </Flex>

            <Flex
              gridGap={{ base: '10px', lg: '24px' }}
              alignItems={{ base: 'flex-start', lg: 'center' }}
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <LeftPanelSingleTransaction label="Block:" />
              <Text
                color="_accentPrimary"
                fontSize="14px"
              >
                {isLoading ? (
                  <SpinnerText />
                ) : (
                  <ChakraLink
                    href={`https://bscscan.com/block/${data?.blockNumber}`}
                  >{`${data?.blockNumber}`}</ChakraLink>
                )}
              </Text>
            </Flex>
            <Flex
              gridGap={{ base: '10px', lg: '24px' }}
              alignItems={{ base: 'flex-start', lg: 'center' }}
              flexDir={{ base: 'column', lg: 'row' }}
            >
              <LeftPanelSingleTransaction label="Timestamp:" />
              <Text
                color="_accentPrimary"
                fontSize="14px"
              >
                {isLoading ? <SpinnerText /> : moment(data?._created_at).format('h:mm:ss A, MMM Do YYYY').toString()}
              </Text>
            </Flex>
          </Flex>

          {/* <Box w="100%" h="1px" bgColor="borderPrimary" /> */}
          <Divider />

          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="Sponsored:" />
            <Text
              color="_accentPrimary"
              fontSize="14px"
            >
              {isLoading ? <SpinnerText /> : '-'}
            </Text>
          </Flex>

          <Box
            w="100%"
            h="1px"
            bgColor="borderPrimary"
          />

          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="From:" />
            {isLoading ? (
              <SpinnerText />
            ) : (
              <Text
                color="_accentPrimary"
                fontSize="14px"
              >
                <Link
                  style={{ color: '_accentPrimary' }}
                  to={`/profile/${data?.sender}`}
                >
                  {trimAddress(data?.sender)}
                </Link>
              </Text>
            )}
          </Flex>

          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="Interacted With (To):" />
            <Flex align="center">
              {isLoading ? (
                <SpinnerText />
              ) : (
                <Text
                  color="_accentPrimary"
                  fontSize="14px"
                >
                  <Link
                    style={{ color: '_accentPrimary' }}
                    to={`/profile/${data?.target}`}
                  >
                    {trimAddress(data?.target)}
                  </Link>
                </Text>
              )}
            </Flex>
          </Flex>

          <Box
            w="100%"
            h="1px"
            bgColor="borderPrimary"
          />

          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="Value:" />
            <Flex
              gridGap="8px"
              flexDir={{ base: 'column', lg: 'row' }}
            >
              {isLoading ? (
                <SpinnerText />
              ) : (
                <>
                  <Text
                    color="textQuaternary"
                    fontSize="14px"
                  >
                    From:
                  </Text>
                  <Text
                    color="_accentPrimary"
                    fontSize="14px"
                  >
                    <Link
                      style={{ color: '_accentPrimary' }}
                      to={`/profile/${data?.sender}`}
                    >
                      {trimAddress(data?.sender)}
                    </Link>
                  </Text>
                  <Text
                    color="textQuaternary"
                    fontSize="14px"
                  >
                    To:
                  </Text>
                  <Text
                    color="_accentPrimary"
                    fontSize="14px"
                  >
                    <Link
                      style={{ color: '_accentPrimary' }}
                      to={`/profile/${data?.target}`}
                    >
                      {trimAddress(data?.target)}
                    </Link>
                  </Text>
                  <Text
                    color="textQuaternary"
                    fontSize="14px"
                  >
                    For:
                  </Text>
                  <Tooltip hasArrow>
                    <Text
                      color="_accentPrimary"
                      fontSize="14px"
                    >
                      {isLoading ? <SpinnerText /> : <Text>{formatUnits(BigInt(data?.value || '0'), 18)} </Text>}
                    </Text>
                  </Tooltip>
                </>
              )}
            </Flex>
          </Flex>
          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="Fee Value:" />
            {isLoading ? (
              <SpinnerText />
            ) : (
              <Tooltip
                hasArrow
                sx={{ whiteSpace: 'nowrap' }}
                label={`Value from ${bnbPrice ? Number(bnbPrice.value) * 1 : <SpinnerText />} USDT`}
              >
                <Text
                  color="textQuaternary"
                  fontSize="14px"
                >
                  $
                  {!bnbPrice ? (
                    <SpinnerText />
                  ) : (
                    formatUnits(
                      BigInt(data?.gas || '0') *
                        BigInt(data?.gasPrice || '0') *
                        BigInt(parseUnits(`${Number(bnbPrice.value)}`, 8)),
                      18 + 8
                    )
                  )}
                </Text>
              </Tooltip>
            )}
          </Flex>
          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="Transaction Fee:" />
            {isLoading ? (
              <SpinnerText />
            ) : (
              <Text
                color="textQuaternary"
                fontSize="14px"
              >
                {formatUnits(BigInt(data?.gas || '0') * BigInt(data?.gasPrice || '0'), 18)} BNB [{data?.gas} gas]
              </Text>
            )}
          </Flex>

          <Box
            w="100%"
            h="1px"
            bgColor="borderPrimary"
          />

          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="Gas Price:" />
            {isLoading ? (
              <SpinnerText />
            ) : (
              <Text
                color="textQuaternary"
                fontSize="14px"
              >
                {formatUnits(BigInt(data?.gasPrice || '0'), 18)} BNB ({formatUnits(BigInt(data?.gasPrice || '0'), 9)}{' '}
                GWEI)
              </Text>
            )}
          </Flex>

          <Box
            w="100%"
            h="1px"
            bgColor="borderPrimary"
          />

          <Flex
            gridGap={{ base: '10px', lg: '24px' }}
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDir={{ base: 'column', lg: 'row' }}
          >
            <LeftPanelSingleTransaction label="Private Note:" />
            <Flex gridGap="4px">
              {isLoading ? (
                <SpinnerText />
              ) : (
                <Tooltip
                  hasArrow
                  label="Implementation preview."
                >
                  <Text
                    color="textQuaternary"
                    fontSize="14px"
                  >
                    Private Note feature.
                  </Text>
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Flex>
      </CardContainer>
    </Flex>
  )
}

export default Transaction
