import { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { EChatType, emptyState, IState, Reducer } from './reducer'
import { io } from 'socket.io-client'
import { useEthereumProvider } from '../Ethereum/ethereumProvider'
import { getDegenPlatformJwtAuthKey } from '../../utils/auth'
import { emptyThread, emptyUser, Message, Thread, User } from './types'
import { createMessageFromData } from './helpers'
import { _log } from '../../logger'
import { zeroAddress } from 'viem'

const api = process.env.REACT_APP_API as string
export const GENERAL_THREAD_ID = zeroAddress

export const socket = io(api.slice(0, -3), {
  transports: ['websocket'],
  autoConnect: false,
})

interface IContext extends IState {
  setFilter: (filter: EChatType) => void
  openThread: (threadId: any) => void
  openOverlayThread: (threadId: any) => void
  toggleDetails: () => void
  toggleOverlayChat: (open: boolean) => void
  getChatUser: (threadId: string) => void
  getThreadById: (threadId: string) => Thread
  loadThread: (threadId: string) => void
  isOffline: boolean
}

const emptyContext: IContext = {
  ...emptyState,
  isOffline: false,
  getChatUser: (threadId: string) => null,
  openOverlayThread: (threadId: any) => null,
  setFilter: (filter: EChatType) => null,
  openThread: (threadId: any) => null,
  toggleDetails: () => null,
  toggleOverlayChat: (open: boolean) => null,
  getThreadById: (threadId: string) => null as any as Thread,
  loadThread: (threadId: string) => null,
}

const Context = createContext(emptyContext)

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, emptyContext)
  const wallet = useEthereumProvider()

  const setFilter = (filter: EChatType) => {
    dispatch({
      filter: filter,
    })
  }

  const openThread = (threadId: any) => {
    dispatch({
      selectedThreadId: threadId,
    })
  }

  const setIsAtBottom = (isAtBottom: boolean) => dispatch({ isAtBottom })
  const setScrollToBottom = (scrollToBottom: () => void) => dispatch({ handleScrollToBottom: scrollToBottom })

  const openOverlayThread = (threadId: any) => {
    if (getThreadById(threadId)?.messages.length == 0) {
      const key = getDegenPlatformJwtAuthKey(wallet.account as address)
      const secret = localStorage.getItem(key)
      socket.emit('openedChat', { jwt: secret, thread: threadId, address: wallet.account })
      socket.emit('messages', { jwt: secret, thread: threadId, address: wallet.account })
    }
    dispatch({
      selectedOverlayThreadIds: state.selectedOverlayThreadIds.includes(threadId)
        ? state.selectedOverlayThreadIds.filter(item => item != threadId)
        : state.selectedOverlayThreadIds.concat([threadId]),
    })
  }

  const toggleDetails = () => {
    dispatch({ isDetailsOpened: !state.isDetailsOpened })
  }

  const toggleOverlayChat = (open: boolean) => {
    dispatch({ isOverlayChatOpened: open })
  }

  const getThreads = () => {
    const key = getDegenPlatformJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)
    socket.emit('threads', { jwt: secret, address: wallet.account as address })
  }

  const getThreadById = (threadId: string) => {
    return state.threads.find(thread => thread.id == threadId) ?? emptyThread
  }

  const getChatUser = (threadId: string) => {
    return state.users[threadId] ?? emptyUser
  }

  const loadThread = (threadId: string) => {
    const threadPage = getThreadById(threadId)?.page
    const threadLoading = getThreadById(threadId)?.loading

    const firstMessage = getThreadById(threadId)?.messages[0]

    if (threadLoading) return

    if (firstMessage) {
      if (firstMessage.text == '_') return
    }

    if (threadPage != undefined) {
      const key = getDegenPlatformJwtAuthKey(wallet.account as address)
      const secret = localStorage.getItem(key)

      socket.emit('messages', {
        jwt: secret,
        thread: state.selectedThreadId,
        address: wallet.account,
        page: threadPage + 1,
      })

      let newThread = getThreadById(threadId) as Thread

      if (newThread) {
        newThread.page = newThread.page + 1
        newThread.loading = true
        dispatch({
          threads: state.threads.map(thread => (thread.id == threadId ? newThread : thread)),
        })
      }
    }
  }

  const getSettings = () => {
    const key = getDegenPlatformJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)
    socket.emit('account', { jwt: secret, address: wallet.account as address })
  }

  useEffect(() => {
    function onConnect() {
      dispatch({ isConnected: true })
      getThreads()
      const key = getDegenPlatformJwtAuthKey(wallet.account as address)
      const secret = localStorage.getItem(key)

      socket.emit('establishConnection', { jwt: secret, address: wallet.account as address })
      getSettings()
    }

    function onThreads(payload: any) {
      let data = payload

      try {
        let usedIds: any = []

        let newThreads: any = []

        for (const thread of data) {
          if (usedIds.includes(thread.id)) {
          } else {
            if (thread.id != null) {
              if (thread.users[0].length > 1) {
                usedIds = usedIds.concat([thread.id])
                newThreads = newThreads.concat([thread])
              }
            }
          }
        }

        dispatch({
          threads: data
            .filter(item => item.id != null && item.users[0].length > 1)
            .map((d: any) => {
              let users: User[] = []

              for (let u of d.users[0]) {
                users.push({
                  mainAddress: u.address,
                  avatarUrl: u.avatar,
                  name: u.name,
                  description: u.description,
                  nickname: u.nickname,
                  lastActive: new Date(u.chatLastActive ?? u._created_at),
                })
              }

              return {
                messages: [],
                id: d.id,
                lastMessage: createMessageFromData(d.message),
                users: users.filter(user => user.mainAddress != (wallet.account as address)),
                page: 0,
                loading: false,
              }
            }),
          isLoading: false,
        })
      } catch (e) {}
    }

    function onThreadMessages(payload: any) {
      let data = payload.data

      let messages: Message[] = []

      for (let m of data) {
        messages.push(createMessageFromData(m))
      }

      if (messages.length > 0) {
        const thread = state.threads.find(thread => thread.id == messages[0].thread)

        if (thread) {
          /* Filter out duplicate messages that were received upon superflouous 'messages' request */
          const messagesFiltered = messages.filter(
            message => !thread.messages.map(({ uuid }) => uuid).includes(message.uuid)
          )

          thread.messages = messagesFiltered.reverse().concat(thread.messages)
          thread.loading = false
          dispatch({ threads: state.threads.map(t => (t.id == thread.id ? thread : t)), isLoading: false })
        }
      } else {
        const thread = state.threads.find(thread => thread.loading == true)
        if (thread) {
          thread.loading = false
          dispatch({
            threads: state.threads.map(t => (t.id == thread.id ? thread : t)),
            isLoading: false,
          })
        }
      }
    }

    function onData(data: any) {
      _log('data:', data)

      const thread = getThreadById(data.thread)

      if (thread) {
        thread.messages = thread.messages.concat([createMessageFromData(data)])
        thread.lastMessage = createMessageFromData(data)
        thread.scrollTrigger = !thread.scrollTrigger

        const newThreads = state.threads.filter(item => item.id != thread.id)

        newThreads.unshift(thread)

        dispatch({
          threads: newThreads,
          isLoading: false,
        })
      }
    }

    socket.on('connect', onConnect)
    socket.on('threads', onThreads)
    socket.on('messages', onThreadMessages)
    socket.on('data', onData)
    socket.on('account', () => null)

    return () => {
      socket.off('connect', onConnect)
      socket.off('threads', onThreads)
      socket.off('messages', onThreadMessages)
      socket.off('data', onData)
      socket.off('account', () => null)
    }
  }, [wallet.account, state.messages, state.threads])

  useEffect(() => {
    const key = getDegenPlatformJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)
    socket.emit('openedChat', { jwt: secret, thread: state.selectedThreadId, address: wallet.account })
    socket.emit('messages', { jwt: secret, thread: state.selectedThreadId, address: wallet.account })
  }, [state.selectedThreadId])

  useEffect(() => {
    if (!wallet.isAuthed) return
    socket.connect()
  }, [wallet.isAuthed])

  useEffect(() => {
    if (socket.connected && state.isConnected == false) {
      getThreads()
      getSettings()
    }
  }, [socket.connected, wallet.account])

  return (
    <Context.Provider
      value={{
        ...state,
        isOffline: !socket.connected,
        setFilter,
        loadThread,
        openThread,
        getThreadById,
        openOverlayThread,
        getChatUser,
        toggleOverlayChat,
        toggleDetails,
        setIsAtBottom,
        registerScrollToBottom: setScrollToBottom,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useChatProvider = () => useContext(Context)
