import { Box, Divider, Flex, PopoverContent, Tooltip } from '@chakra-ui/react'
import { ListItem } from './ListItem'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { Link as LinkR, useLocation, useNavigate } from 'react-router-dom'
import { AddressZero } from '../../../../../utils/ethereum'
import { isMobile } from 'react-device-detect'
import { adminRoutes, routes } from '../../routes'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { setShouldReconnect } from '../../../../../utils/auth'
import { getColor } from '../../../../../theme/global'

interface IProps {
  controller: { onClose: () => void }
}

export const ProfilePopup = ({ controller }: IProps) => {
  const wallet = useEthereumProvider()
  const navigate = useNavigate()
  const history = useLocation()
  const accountProfile = useAccountProfileProvider()

  const handleDisconnect = async () => {
    /** Set logout flag */
    setShouldReconnect(false)

    wallet.disconnect(wallet.account as address)
  }

  return (
    <PopoverContent
      onClick={controller.onClose}
      position="absolute"
      left="-80px"
      width="auto"
      boxShadow={`0px 4px 8px ${getColor('borderPrimary')}`}
      borderRadius="8px"
      whiteSpace="nowrap"
      zIndex="1000"
      p="8px"
      minW="160px"
      border="none"
      flexDirection="column"
      gap="4px"
    >
      <ListItem
        text="Mój profil"
        icon="/assets/icons/user-icon.svg"
        action={() => navigate(`/profile/${wallet.account ?? AddressZero}`)}
      />
      <Divider
        bgColor="borderPrimary"
        color="borderPrimary"
      />
      <ListItem
        text="Edytuj profil"
        icon="/assets/icons/settings-icon.svg"
        action={() => navigate(`/profile/${wallet.account ?? AddressZero}`, { state: { edit: true } })}
      />
      <Divider
        bgColor="borderPrimary"
        color="borderPrimary"
      />
      <ListItem
        text="Wyloguj się"
        action={handleDisconnect}
      />
    </PopoverContent>
  )
}
