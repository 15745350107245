import { Box, Flex, Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const BackToTop = () => {
  const [isScrollable, setIsScrollable] = useState(false)

  const { id } = useParams()

  useEffect(() => setIsScrollable(true), [])

  return isScrollable ? (
    <Flex
      flexWrap="wrap"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      align="center"
      gap="8px"
      cursor="pointer"
      color="_accentSecondary"
      opacity=".5"
      sx={{
        fontSize: '14px',
      }}
    >
      <Image
        src="/assets/icons/back-to-top.svg"
        sx={{ height: '1em' }}
      />
      <Box userSelect="none">Do góry</Box>
    </Flex>
  ) : (
    <></>
  )
}
