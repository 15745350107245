import { Flex, Image, Text } from '@chakra-ui/react'
import { FC } from 'react'

interface IUserTagProps {
  text: string
  colorBg?: string
  colorFg?: string
  imageUri?: string
  compact?: boolean
  iconWidth?: string
  iconHeight?: string
}

export const UserTag: FC<IUserTagProps> = ({
  colorBg,
  text,
  imageUri,
  colorFg,
  compact = true,
  iconWidth,
  iconHeight,
}) => {
  const iconSize = compact ? '12px' : '16px'
  return (
    <Flex
      p={compact ? '2px 6px' : '7px 12px'}
      borderRadius="4px"
      bgColor="borderPrimary"
      align="center"
      justify="center"
      gap="4px"
      fontSize="12px"
      color="textQuaternary"
      whiteSpace="nowrap"
      //sx={{ maxH: '2em' }}
      maxW="130px"
      maxH="32px"
    >
      {!!imageUri && (
        <Image
          src={imageUri}
          boxSize={iconSize}
          minH={iconSize}
          minW={iconSize}
          maxH={iconSize}
          maxW={iconSize}
        />
      )}
      <Text
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {text}
      </Text>
    </Flex>
  )
}
