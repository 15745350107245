import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import LeftPanel from '../Home/LeftPanel/leftPanel'
import RightPanel from '../Home/RightPanel/rightPanel'
import { useEffect, useState } from 'react'
import { Post } from '../Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import useAsyncEffect from '../../../hooks/effects/async'
import { fetchApi } from '../../../utils/fetcher'
import { emptyPostSchema, IPostSchema } from '../Home/MiddlePanel/deafult/Default'
import { loaded } from '../../../utils/process'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { ICO } from '../../shared/degen/Ico'
import { icoPostData } from '../../shared/icoPost/IcoPost'

export enum Views {
  DEFAULT,
  ROLES,
}

export default function PostPage() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()

  const [activeView, setActiveView] = useState<Views>(Views.DEFAULT)
  const [post, setPost] = useState<IPostSchema>(emptyPostSchema)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // TODO: Extract the second (ghost) referral and save as the code entered.
  const referral = searchParams.get('referral')
  const ghostReferral = searchParams.get('ghostReferral')
  const navigate = useNavigate()
  const wallet = useEthereumProvider()
  const accountProfile = useAccountProfileProvider()

  useAsyncEffect(async () => {
    await loaded(async () => {
      const res = await fetchApi('post', [id])

      setPost(res[0])
    }, setIsLoading)
  }, [id])

  /* Save ref in localstorage for further use in /onboarding (TODO). */
  useEffect(() => {
    if (!referral || !ghostReferral || wallet.isAuthed) {
      return
    }

    localStorage.setItem('referral', referral)
    localStorage.setItem('ghostReferral', ghostReferral)
  }, [referral, ghostReferral, wallet.isAuthed])

  return (
    <Flex
      gridGap="24px"
      bg="backgroundSecondary"
      justify="space-between"
      direction={{ base: 'column', lg: 'row' }}
      w="100%"
    >
      <LeftPanel setView={setActiveView} />
      {post !== undefined ? (
        <Flex
          direction="column"
          width="full"
          gap="24px"
          w={{ base: '100%' }}
          mx="auto"
        >
          <Box
            sx={{
              borderRadius: '16px',
              border: '1px solid',
              borderColor: 'borderPrimary',
              flexDirection: 'column',
              background: 'backgroundMain',
              mx: { base: '16px', md: 'unset' },
            }}
          >
            <Link
              to="/"
              style={{ borderRadius: '16px' }}
            >
              <Flex
                w="100%"
                gap="12px"
                justifyContent="flex-start"
                p="16px"
                alignItems="center"
                borderRadius="16px"
              >
                <Image
                  src="/assets/icons/arrow-left.svg"
                  sx={{ boxSize: '16px' }}
                />
                <Text
                  color="textPrimary"
                  fontWeight="600"
                  fontSize="18px"
                >
                  Post page
                </Text>
              </Flex>
            </Link>
          </Box>
          {isLoading ? (
            <Flex
              align="center"
              justify="center"
              sx={{
                minH: '72px',
                flexGrow: 1,
              }}
            >
              <Spinner size="lg" />
            </Flex>
          ) : (
            <>
              {/* TODO: Makadu backend deprecation fix */}
              {referral && true && (
                <Flex
                  w="100%"
                  p="12px 16px"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor="#8F8CB8"
                  align="center"
                  bg="#8F8CB81f"
                  justify="space-between"
                >
                  <Flex
                    align="center"
                    gap="12px"
                    fontSize="12px"
                    fontWeight="500"
                    color="#34306e"
                  >
                    <Image
                      src="/assets/icons/info-circle.svg"
                      sx={{ boxSize: '16px' }}
                    />
                    <Text>
                      Your referral code{' '}
                      <Text
                        as="span"
                        sx={{ fontWeight: '700' }}
                      >
                        {referral}
                      </Text>{' '}
                      has been saved.
                      <Link
                        style={{ display: 'block', textDecoration: 'underline', color: '_accentPrimary' }}
                        to="/onboarding"
                      >
                        Onboard yourself now
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
              )}

              <Post
                data={post}
                customContent={id === 'root' ? <ICO /> : null}
              />
            </>
          )}
        </Flex>
      ) : (
        <Text
          fontSize="20px"
          textAlign="center"
          width="100%"
          fontWeight="bold"
          mt="100px"
        >
          Post not found
        </Text>
      )}
      <RightPanel />
    </Flex>
  )
}
