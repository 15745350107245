import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DegenPumpAd } from '../components/pages/Home/DegenPumpAd'
import LeftPanel from '../components/pages/Home/LeftPanel/leftPanel'
import { Default } from '../components/pages/Home/MiddlePanel/deafult/Default'
import { RolesPanel } from '../components/pages/Home/MiddlePanel/roles/RolesPanel'
import RightPanel from '../components/pages/Home/RightPanel/rightPanel'
import { useProfile } from '../hooks/useProfile'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'

export enum Views {
  DEFAULT,
  ROLES,
}

const middlePanel: any = {
  [Views.DEFAULT]: <Default />,
  [Views.ROLES]: <RolesPanel />,
}

export const Home = () => {
  const [activeView, setActiveView] = useState<Views>(Views.DEFAULT)

  const { account } = useEthereumProvider()
  const profile = useProfile({ address: account as address })

  const location = useLocation()

  /** Go home upon `/` navigation */
  useEffect(() => setActiveView(Views.DEFAULT), [location])

  return (
    <Flex
      direction="column"
      w="100%"
    >
      <DegenPumpAd />
      <Flex
        gridGap="24px"
        justify="space-between"
        pos="relative"
        direction={{ base: 'column', lg: 'row' }}
        w="100%"
      >
        <Flex
          h="100%"
          flexDir="column"
        >
          <Flex
            pos="sticky"
            top="64px"
          >
            <LeftPanel setView={setActiveView} />
          </Flex>
        </Flex>
        {middlePanel[activeView]}
        <Flex
          h="100%"
          flexDir="column"
        >
          <Flex
            pos="sticky"
            top="64px"
          >
            <RightPanel />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
