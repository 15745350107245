import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { UserTag } from '../../../../../../../../shared/tags/UserTag'
import SpinnerText from '../../../../../../../../shared/tags/SpinnerText'
import { useIconProvider } from '../../../../../../../../../provider/Icons/iconsProvider'
import { getColor } from '../../../../../../../../../theme/global'

interface ITargetingModalProps {
  isOpen: boolean
  onClose: () => void
  target: {
    roles: string[]
    interests: string[]
  }
}

type Target = {
  name: string
  icon: string
}

export const TargetingModal: FC<ITargetingModalProps> = ({ isOpen, onClose, target }) => {
  const { interests, roles } = useIconProvider()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="backgroundMain"

        borderRadius="16px"
        p="0"
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            color="_accentSecondary"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
          >
            Targeting configuration
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          p="24px"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          {!target.interests.length && !target.roles.length && (
            <Text sx={{ textAlign: 'center', fontSize: '12px', color: 'textSecondary' }}>
              No targeting was set for this post.
            </Text>
          )}
          {!!target.interests.length && (
            <ItemsGrid title="Interests">
              {target.interests.map((interest: string, idx: number) =>
                interests !== undefined ? (
                  <Box key={`${interest}-${idx}`}>
                    <UserTag
                      compact
                      text={interest}
                      key={interest}
                      imageUri={interests.get(interest)}
                      colorBg="_backgroundTertiary"
                      colorFg="textQuaternary"
                    />
                  </Box>
                ) : (
                  <SpinnerText />
                )
              )}
            </ItemsGrid>
          )}
          {!!target.roles.length && (
            <ItemsGrid title="Roles">
              {target.roles.map((role: string, idx: number) => {
                return roles !== undefined ? (
                  <Box key={`${role}-${idx}`}>
                    <UserTag
                      compact
                      text={role}
                      key={role}
                      imageUri={roles.get(role)}
                      colorBg="_backgroundTertiary"
                      colorFg="textQuaternary"
                    />
                  </Box>
                ) : (
                  <SpinnerText />
                )
              })}
            </ItemsGrid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface IItemsGridProps {
  title: string
  children: ReactNode[]
}

const ItemsGrid: FC<IItemsGridProps> = ({ title, children }) => {
  return (
    <Flex
      minH="48px"
      gap="2px"
      flexDirection="column"
    >
      <Text
        color="textSecondary"
        fontWeight="500"
        fontSize="12px"
      >
        {title}
      </Text>
      <Box
        display="flex"
        alignItems="start"
        justifyContent="start"
        flexWrap="wrap"
        gap="4px"
      >
        {children}
      </Box>
    </Flex>
  )
}
