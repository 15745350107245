import { INotification } from "../../components/global/navbar/ConnectWallet/notifications/NotificationModal"

export interface IState {
  isLoading: boolean,
  notifications: INotification[],
  unreadCount: number
}

export const emptyState: IState = {
  isLoading: false,
  notifications: [],
  unreadCount: 0
}

export const Reducer = (state: IState, updatedProperties: Partial<IState>) => ({
  ...state,
  ...updatedProperties,
})
