import { useChatProvider } from '../../../../provider/Chat/chatProvider'
import { Box, useTimeout } from '@chakra-ui/react'
import { useEffect, useLayoutEffect, useState } from 'react'
import useIsMobile from '../../../../hooks/chakra/useIsMobile'

/**
 * 1. Token pair [address, address] + chainId [string] + exchange (uniswap/pancake/etc) [string] => pairAddress [address]
 *     ^ This step will be done by 1inch API, providing it with the token pair and the chainId only
 * 2. Query Dextools.io for the pairAddress chart
 */
export const Graph = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isRendered, setIsRendered] = useState(false)

  const chat = useChatProvider()
  const isMobile = useIsMobile('chat')

  useLayoutEffect(() => {
    const iframeNode = document.querySelector('iframe')
    let timeout: any = null

    const handleOnLoad = () => setIsLoading(false)

    const onLoadListener = () => {
      setIsRendered(true)
      timeout = setTimeout(handleOnLoad, 3000)
    }
    iframeNode?.addEventListener('load', onLoadListener)

    return () => {
      iframeNode?.removeEventListener('load', onLoadListener)
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Box
      sx={{
        filter: `grayscale(${isLoading ? 1 : 0})`,
        opacity: isRendered ? '1' : '0',
        boxSize: '100%',
        transition: 'filter 3s',
      }}
    >
      <iframe
        id="dextools-widget"
        title="DEXTools Trading Chart"
        width="100%"
        height="100%"
        loading="lazy"
        src={
          // Spec:
          //https://www.dextools.io/widget-chart/en/
          //<chainId>/pe-light/
          //<pairAddress>?
          //theme=<theme>&
          //chartType=<chartType>&
          //chartResolution=<chartResolution>&
          //drawingToolbars=<drawingToolbars>&
          //tvPlatformColor=<color>&
          //tvPaneColor=<color>&
          //headerColor=<color>&
          //chartInUsd=<chartInUsd>
          'https://www.dextools.io/widget-chart/en/' +
          'bnb' +
          '/pe-light/' +
          '0xd8cc6bfdee087148c220e9141a075d18418abbac' +
          '?theme=' +
          'dark' +
          '&chartType=' +
          '9' +
          '&chartResolution=' +
          (isMobile ? '120' : '1D') +
          '&drawingToolbars=' +
          'false' +
          '&tvPlatformColor=' +
          '050400' +
          '&tvPaneColor=' +
          '130F01' +
          '&headerColor=' +
          '130F01'
        }
      />
    </Box>
  )
}
