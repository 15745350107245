enum ETestNetwork {
  BNB_MAINNET = 56,
}

enum EInvalidNetwork {
  _ /** Null network */,
}

enum ENetwork {
  BNB_MAINNET = 56,
  /** TODO: Multichain */
  FALLBACK = 56,
}

export type { ETestNetwork, EInvalidNetwork }
export { ENetwork }
