import { Colors } from './colors'
import { cssVar, extendTheme } from '@chakra-ui/react'
import { ButtonStyle } from './components/buttonStyle'
import { Checkbox } from './components/checkboxStyle'
import { getColor, globalStyles } from './global'
import { SelectStyle } from './components/selectStyle'

import '../theme/css/nippo.css'

const $arrowBg = cssVar('popper-arrow-bg')

export const theme = extendTheme({
  colors: {},
  semanticTokens: {
    colors: Colors,
  },
  components: {
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: '16px',
          bg: 'backgroundMain',
          boxShadow: `0px 12px 40px ${getColor('_textPrimary', 0.2)}`,
          mx: '8px',
        },
        overlay: {
          bg: getColor('_textPrimary', 0.15),
        },
      },
    },
    Popover: {
      baseStyle: {
        content: {
          bg: 'backgroundTertiary',
        },
      },
    },
    Spinner: {
      baseStyle: {
        color: '_accentSecondary',
      },
    },
    Button: ButtonStyle,
    Link: {
      baseStyle: {
        color: '_accentPrimary',
      },
    },
    Checkbox,
    Select: SelectStyle,
    Alert: {
      variants: {
        solid: {
          container: {
            bg: '_accentSecondary',
            boxShadow: `2px 8px 32px 0px ${getColor('_accentSecondary')}`,
            color: '_textBlack',
          },
        },
        error: {
          container: {
            bg: 'redPrimary',
            boxShadow: `2px 8px 32px 0px ${getColor('redPrimary')}`,
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: '8px',
        bg: '_accentSecondary',
        [$arrowBg.variable]: getColor('_accentSecondary'),
      },
    },
  },
  config: {
    useSystemColorMode: false,
    theme: 'dark',
  },
  fonts: {
    body: `Nippo Variable`,
  },
  styles: {
    ...globalStyles,
  },
  breakpoints: {
    xs: '24em', // 384px
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '62em', // 992px
    degen: '1180px',
    xl: '80em', // 1280px
    '2xl': '96em', // 1536px
  },
})
