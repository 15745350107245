import {
  Divider,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { copyToClipboard } from '../../../../../../../../../utils/os'

import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from 'react-share'
import { getColor } from '../../../../../../../../../theme/global'

export const InviteAsModal = ({ isOpen, onClose, link }: { isOpen: boolean; onClose: () => void; link: string }) => {
  const toast = useToast()
  const linkTextReference = useRef<any>(null!)

  const handleSelectLink = () => {
    linkTextReference.current.select()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="backgroundMain"

        borderRadius="16px"
        p="0"
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            color="_accentSecondary"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
          >
            Invite AS
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          py="16px"
          display="flex"
          flexDir="column"
          gap="24px"
        >
          <Text
            color="_accentSecondary"
            fontSize="14px"
            fontWeight="500"
          >
            Invite a friend to the task and earn a 20% reward
          </Text>
          <Flex
            flexDir="column"
            gap="8px"
          >
            <Text
              color="textSecondary"
              fontSize="14px"
            >
              Copy the link and share it with friends:
            </Text>
            <Flex
              align="center"
              w="100%"
              gap="8px"
              onClick={handleSelectLink}
            >
              <Flex
                bg="backgroundTertiary"
                border="1px solid"
                borderColor="borderPrimary"
                borderRadius="8px"
                p="8px 12px"
                gap="12px"
                w="100%"
                align="center"
                justify="space-between"
              >
                <Input
                  color="textQuaternary"
                  fontSize="14px"
                  ref={linkTextReference}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  value={link}
                  readOnly
                  variant="unstyled"
                  outline="none !important"
                />
                <Image
                  src="/assets/icons/copy.svg"
                  cursor="pointer"
                  onClick={() => {
                    copyToClipboard(link)
                    toast({ title: 'Copied!', status: 'info' })
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            flexDir="column"
            gap="16px"
          >
            <Text
              color="textSecondary"
              fontSize="14px"
            >
              Or share via:
            </Text>
            <Flex
              gap="8px"
              maxW="100%"
              overflow="auto"
            >
              <FacebookShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/fb-icon.svg"
                    minW="64px"
                    minH="64px"
                    h="64px"
                    w="64px"
                    borderRadius="4px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    Facebook
                  </Text>
                </Flex>
              </FacebookShareButton>
              <TwitterShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/x-icon.svg"
                    minW="64px"
                    minH="64px"
                    h="64px"
                    w="64px"
                    borderRadius="4px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    X
                  </Text>
                </Flex>
              </TwitterShareButton>
              <LinkedinShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/linkedin.svg"
                    minW="64px"
                    minH="64px"
                    h="64px"
                    w="64px"
                    borderRadius="8px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    LinkedIn
                  </Text>
                </Flex>
              </LinkedinShareButton>
              <WhatsappShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/whatsapp-icon.svg"
                    minW="64px"
                    minH="64px"
                    h="64px"
                    w="64px"
                    borderRadius="4px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    Whatsapp
                  </Text>
                </Flex>
              </WhatsappShareButton>
              <TelegramShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/telegram.svg"
                    h="64px"
                    w="64px"
                    borderRadius="4px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    Telegram
                  </Text>
                </Flex>
              </TelegramShareButton>
              <RedditShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/reddit-icon.svg"
                    minW="64px"
                    minH="64px"
                    h="64px"
                    w="64px"
                    borderRadius="4px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    Reddit
                  </Text>
                </Flex>
              </RedditShareButton>
              <TumblrShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/tumblr-icon.svg"
                    minW="64px"
                    minH="64px"
                    h="64px"
                    w="64px"
                    borderRadius="4px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    Tumblr
                  </Text>
                </Flex>
              </TumblrShareButton>

              <ViberShareButton url={link}>
                <Flex
                  flexDir="column"
                  gap="2px"
                  align="center"
                >
                  <Image
                    src="/assets/icons/viber-icon.svg"
                    minW="64px"
                    minH="64px"
                    h="64px"
                    w="64px"
                    borderRadius="4px"
                  />
                  <Text
                    fontSize="12px"
                    color="textSecondary"
                  >
                    Viber
                  </Text>
                </Flex>
              </ViberShareButton>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
