import { useState } from 'react'
import Markdown from 'react-markdown'

// @ts-ignore
import licensesData from '../static/cache/licenses'
import useAsyncEffect from '../hooks/effects/async'
import { Box } from '@chakra-ui/react'

interface ILicense {
  id: string
  resource: string
}

export const Licenses = () => {
  const [licenses, setLicenses] = useState<ILicense[]>([])

  useAsyncEffect(async () => {
    const dataText = await (await fetch(licensesData)).text()

    /* Parse array */
    const data = JSON.parse(`[${dataText.replace(/\n/g, ',')}]`).slice(3) as string[]

    const _: any = []
    for (let i = 0; i < data.length; i += 3) {
      _.push({ id: data[i], resource: data[i + 2] } as ILicense)
    }
    setLicenses(_)
  }, [])

  const markdownText = `***Licencje użytego otwartego oprogramowania***
${licenses.map(license => `* [${license.id}](${license.resource || `https://www.npmjs.com/package/@${license.id.split('@')[1]}`})`).join('\n')}`

  return (
    <Box
      sx={{
        ml: { base: '24px', md: '16px' },
        a: {
          _hover: { color: '_accentPrimary' },
          _active: { color: '_accentPrimary' },
          _focus: { color: '_accentPrimary' },
        },
      }}
    >
      <Markdown>{markdownText}</Markdown>
    </Box>
  )
}
