import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { Role } from './Role'

type IRole = {
  name: string
  members: number
  color: string
  fontColor: string
}

const inDemandRoles: IRole[] = [
  {
    name: 'Database Administrator',
    members: 120,
    color: '#DCF2EA',
    fontColor: '_successPrimary',
  },
  {
    color: '#FFEFD2',
    fontColor: '#FFB020',
    name: 'Software Developer',
    members: 94,
  },
  {
    color: '#F9DADA',
    fontColor: '#D14343',
    name: 'Web Developer',
    members: 86,
  },
  {
    color: 'bluePlaceholder',
    fontColor: '_textQuaternary',
    name: 'IT Manager',
    members: 64,
  },
  {
    color: '#E7E4F9',
    fontColor: '#6E62B6',
    name: 'Compliance Officer',
    members: 28,
  },
]

const roles: IRole[] = [
  {
    name: 'Database Administrator',
    members: 120,
    color: '#DCF2EA',
    fontColor: '_successPrimary',
  },
  {
    color: '#FFEFD2',
    fontColor: '#FFB020',
    name: 'Software Developer',
    members: 94,
  },
  {
    color: '#F9DADA',
    fontColor: '#D14343',
    name: 'Web Developer',
    members: 86,
  },
  {
    color: 'bluePlaceholder',
    fontColor: '_textQuaternary',
    name: 'IT Manager',
    members: 64,
  },
  {
    color: '#E7E4F9',
    fontColor: '#6E62B6',
    name: 'Compliance Officer',
    members: 28,
  },
  {
    color: '#FFEFD2',
    fontColor: '#FFB020',
    name: 'Software Developer',
    members: 94,
  },
  {
    color: '#F9DADA',
    fontColor: '#D14343',
    name: 'Web Developer',
    members: 86,
  },
  {
    color: 'bluePlaceholder',
    fontColor: '_textQuaternary',
    name: 'IT Manager',
    members: 64,
  },
  {
    color: '#E7E4F9',
    fontColor: '#6E62B6',
    name: 'Compliance Officer',
    members: 28,
  },
]

export const Roles = () => {
  return (
    <Flex
      flexDir="column"
      gap="16px"
    >
      <Flex
        borderRadius="16px"
        border="1px solid"
        borderColor="borderPrimary"
        w="100%"
        flexDirection="column"
      >
        <Flex
          w="100%"
          gridGap={{ base: '8px', md: '24px' }}
          justifyContent="space-between"
          p="16px"
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Text
            fontSize="14px"
            color="textQuaternary"
            fontWeight="500"
          >
            In-Demand Roles
          </Text>
          <Text
            fontSize="14px"
            color="textSecondary"
          >
            {inDemandRoles?.length} roles
          </Text>
        </Flex>
        <Box
          display="flex"
          w="100%"
          bgColor="borderPrimary"
          h="1px"
        />
        {inDemandRoles.map((role: IRole, index: number) => (
          <React.Fragment key={`${role.name}_${index}`}>
            <Role
              inDemand
              color={role.color}
              fontColor={role.fontColor}
              name={role.name}
              members={role.members}
            />
            {index < inDemandRoles?.length - 1 && (
              <Box
                display="flex"
                w="100%"
                bgColor="borderPrimary"
                h="1px"
              />
            )}
          </React.Fragment>
        ))}
      </Flex>
      <Flex
        borderRadius="16px"
        border="1px solid"
        borderColor="borderPrimary"
        w="100%"
        flexDirection="column"
      >
        <Flex
          w="100%"
          gridGap={{ base: '8px', md: '24px' }}
          justifyContent="space-between"
          p="16px"
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Text
            fontSize="14px"
            color="textQuaternary"
            fontWeight="500"
          >
            Roles
          </Text>
          <Text
            fontSize="14px"
            color="textSecondary"
          >
            {roles?.length} roles
          </Text>
        </Flex>
        <Box
          display="flex"
          w="100%"
          bgColor="borderPrimary"
          h="1px"
        />
        {roles.map((role: IRole, index: number) => (
          <React.Fragment key={`${role.name}_${index}`}>
            <Role
              color={role.color}
              fontColor={role.fontColor}
              name={role.name}
              members={role.members}
            />
            {index < roles?.length - 1 && (
              <Box
                display="flex"
                w="100%"
                bgColor="borderPrimary"
                h="1px"
              />
            )}
          </React.Fragment>
        ))}
      </Flex>
    </Flex>
  )
}
