import { Button, Flex, Image, Tooltip } from '@chakra-ui/react'
import { ButtonFollow } from '../../../shared/actions/ButtonFollow'

enum EAction {
  Friend,
  Follow,
  Message,
  Edit,
}

export default function RolesWithButtonFollow({
  wallet,
  id,
  subActions,
  account,
  friend,
}: {
  wallet: any
  id: string | undefined
  subActions: Array<{ id: EAction; icon: string; name: string; action?: () => void }>
  account: any
  friend: any
}) {
  return (
    <Flex
      gap="8px"
      maxW={{ base: 'unset', lg: '300px' }}
    >
      {wallet.account?.toLowerCase() === id?.toLowerCase() || (
        <ButtonFollow
          target={id?.toLowerCase() as address}
          description={{ placement: 'bottom' }}
          width="100%"
        />
      )}
      {subActions.map(action => {
        if (
          [EAction.Follow, EAction.Friend, EAction.Message].includes(action.id) &&
          wallet.account?.toLowerCase() === id?.toLowerCase()
        ) {
          return null
        }

        return (
          <Tooltip
            key={action.name}
            label={action.name}
          >
            <Button
              width={action.name === 'Message' ? { base: '32px', md: '32px' } : { base: '44px', md: '32px' }}
              p="0"
              onClick={action.action}
              isDisabled={
                /* TODO: Move this out of here */
                (action.icon === 'dots.svg' && id?.toLowerCase() !== account.address) ||
                /* TODO: Move this out of here */
                (action.name === 'Friend' && friend.isDisabled) ||
                !action.action
              }
              isLoading={action.name === 'Friend' && friend.isLoading}
            >
              <Image
                src={`/assets/icons/${action.icon}`}
                boxSize="16px"
              />
            </Button>
          </Tooltip>
        )
      })}
    </Flex>
  )
}
