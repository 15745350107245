import { Connector, useAccount, useConnect, useDisconnect } from 'wagmi'
import { getNetwork } from '@wagmi/core'
import { WalletDetails } from './WalletDetails'
import { Button, Flex, Image, Spinner, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { EError } from '../../../../hooks/utils/useEthereum'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import useNetworks from '../../../../hooks/utils/useNetworks'
import { ENetwork } from '../../../../enum/network.enum'
import React, { useEffect, useMemo, useState } from 'react'
import useWindowFocus from 'use-window-focus'
import { _log } from '../../../../logger'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import useIsMobile from '../../../../hooks/chakra/useIsMobile'
import { setShouldReconnect, shouldReconnect } from '../../../../utils/auth'

enum State {
  CONNECTED,
  CONNECTED_WRONG_NETWORK,
  DISCONNECTED,
  LOADING_WALLET,
  LOADING_AUTH,
  LOADING_NETWORK,
}

export const ConnectWalletCustom = () => {
  const [lastConnectedAccount, setLastConnectedAccount] = useState<address>()
  const [isWalletConnectLoading, setIsWalletConnectLoading] = useState(false)
  const signInModalController = useDisclosure()

  const { open: handleWalletConnectModal } = useWeb3Modal()
  const networks = useNetworks()
  const account = useAccount()
  const wallet = useEthereumProvider()
  const navigate = useNavigate()
  const toast = useToast()
  const { chain } = getNetwork()
  const wagmiDisconnect = useDisconnect()
  const wagmiConnect = useConnect()
  const isWindowFocused = useWindowFocus()
  const location = useLocation()
  const isMobile = useIsMobile()

  /* TODO: Move to wallet provider */
  const isWrongNetwork = ENetwork.FALLBACK !== chain?.id

  const handleLogIn = async () => {
    /** Set login flag */
    setShouldReconnect(true)

    const result = await wallet.connect()

    if (result === EError.NO_SERVER) {
      if (toast.isActive('error_no_server')) {
        throw result
      }

      toast({ title: 'Network error – please, try again', id: 'error_no_server' })
    } else if (result === EError.GENERIC) {
      if (toast.isActive('error_error')) {
        throw result
      }

      toast({ title: 'Please try again', id: 'error_error' })
    } else if ((result as any)?.code === EError.USER_REJECTION) {
      /** User rejected authorization */
      throw result
    } else if ((result as any)?.code === 'ERR_BAD_REQUEST') {
      /** Fetch edge case fix */
      return
    }

    /** Fall through, connection succeeded – return void */
  }

  const handleConnectConnector = async (connector: Connector<any, any>) => {
    // NOTE: Can close WalletConnect modal here

    await loaded(
      async () => {
        // NOTE: Can close WalletConnect modal here

        _log('connector started connecting')
        await wagmiConnect.connectAsync({ connector })
        _log('connector connected')
      },
      undefined,
      () =>
        toast({
          status: 'info',
          title: 'Connection pending',
          description: 'If wallet connection is lost, return to the prompt window to reconnect.',
          id: 'error_pending',
        })
    )
  }

  const handleSwitchNetwork = async () => loaded(async () => await networks.switchNetwork())

  const handleWallet = async () => {
    await wagmiDisconnect.disconnectAsync()
    _log('disconnected before connection')

    if (wallet.isNetworkSwitching || wallet.isAuthing) {
      _log('returned because wallet is switching or being authed')
      return
    }

    /* Skip any W3Modal action when already logged in */
    if (wallet.isLoggedIn) {
      return
    }

    handleWalletConnectModal()
  }

  /** Update authentication status and auth if needed */
  useAsyncEffect(async () => {
    if (!account.isConnected || account.isReconnecting) {
      return
    }

    loaded(
      async () => await handleLogIn(),
      undefined,
      (error: EError | any) => {
        if ([EError.NO_SERVER, EError.USER_REJECTION].includes(error) || error.code === 4001) {
          return
        }

        wallet.disconnect(account.address as address)
      }
    )
  }, [account.isConnected, account.isReconnecting])

  /** Sync last valid account connected */
  useEffect(() => {
    if (!account.address) {
      return
    }

    setLastConnectedAccount(account.address)
  }, [account.address])

  /** Handle sign in modal open sync */
  useAsyncEffect(async () => {
    if (wallet.isSignInModalOpen || wallet.isLoggedIn) {
      return
    }

    if (!shouldReconnect) {
      return
    }

    await handleWallet()
    setShouldReconnect(false)
  }, [wallet.isSignInModalOpen, wallet.isLoggedIn])

  /** Remove auth when disconnecting via WalletConnect */
  useEffect(() => {
    if (account.isConnected || !lastConnectedAccount) {
      return
    }

    wallet.disconnect(lastConnectedAccount)
  }, [account.isConnected])

  /** Handle options modal state */
  useEffect(() => {
    if (signInModalController.isOpen) {
      return
    }

    setIsWalletConnectLoading(false)
  }, [signInModalController.isOpen])

  /* Handle generic connection errors */
  useEffect(() => {
    if (!wagmiConnect.error || wagmiConnect.error.message === 'Connection request reset. Please try again.') {
      return
    }

    !toast.isActive('error_connector') && toast({ title: wagmiConnect.error.message, id: 'error_connector' })
  }, [wagmiConnect.error])

  /* Handle connector loading state */
  useEffect(
    () => setIsWalletConnectLoading(wagmiConnect.isLoading),
    [wagmiConnect.isLoading, wagmiConnect.pendingConnector]
  )

  /* Handle wallet interaction notification
       If in focus + authing, show toast */
  useEffect(() => {
    if (!wallet.isAuthing || !isWindowFocused || toast.isActive('error_pending')) {
      return
    }

    const timer = setTimeout(() => {
      if (toast.isActive('error_pending')) {
        return
      }

      toast({
        title: 'Connection pending',
        description: 'If wallet connection is lost, return to the prompt window to reconnect.',
        id: 'error_pending',
        duration: null,
      })
    }, 333)

    return () => {
      clearTimeout(timer)
      toast.close('error_pending')
    }
  }, [wallet.isAuthing, isWindowFocused])

  // TODO: Factor out button style
  const typeOptions: { [p in State]: () => React.ReactNode } = {
    [State.CONNECTED]: () => <WalletDetails />,
    [State.CONNECTED_WRONG_NETWORK]: () => (
      <Button
        onClick={handleSwitchNetwork}
        type="button"
        bg={{ base: '_accentSecondary', lg: '_accentPrimary' }}
        color="textWhite"
        fontSize="12px"
        cursor="pointer"
        h="32px"
        padding="0px 10px"
        borderRadius="10px"
        alignItems="center"
        variant="dark"
      >
        <Flex
          align="center"
          gap="8px"
          fontWeight="500"
        >
          <Image
            src="/assets/icons/warning.svg"
            boxSize="16px"
          />
          {isMobile ? 'Sieć' : 'Zła sieć'}
        </Flex>
      </Button>
    ),
    [State.DISCONNECTED]: () => (
      <Button
        onClick={handleWallet}
        transition="background 5s cubic-bezier(0, 0, 0.11, 0.93) box-shadow unset"
        bg="_accentPrimary"
        color="textWhite"
        fontSize="12px"
        cursor="pointer"
        h="32px"
        padding="0px 10px"
        borderRadius="10px"
        alignItems="center"
        variant="dark"
        gap="8px"
      >
        <Flex
          align="center"
          gap="8px"
          fontWeight="500"
        >
          <Image src="/assets/icons/wallet.svg" />
          Zaloguj się
        </Flex>
      </Button>
    ),
    [State.LOADING_WALLET]: () => (
      <Tooltip label="Wyloguj się">
        <Button
          onClick={handleWallet}
          transition="background 5s cubic-bezier(0, 0, 0.11, 0.93) box-shadow unset"
          bg={{ base: '_accentSecondary', lg: '_accentPrimary' }}
          color="textWhite"
          fontSize="12px"
          h="32px"
          padding="0px 10px"
          borderRadius="10px"
          alignItems="center"
          variant="dark"
          gap="8px"
          opacity=".5"
          cursor="not-allowed"
        >
          <Spinner
            boxSize="1.33em"
            color="textWhite"
          />
        </Button>
      </Tooltip>
    ),
    [State.LOADING_AUTH]: () => (
      <Tooltip label="Cancel">
        <Button
          onClick={handleWallet}
          transition="background 5s cubic-bezier(0, 0, 0.11, 0.93) box-shadow unset"
          bg={{ base: '_accentSecondary', lg: '_accentPrimary' }}
          color="textWhite"
          fontSize="12px"
          h="32px"
          padding="0px 10px"
          borderRadius="10px"
          alignItems="center"
          variant="dark"
          gap="8px"
          opacity=".5"
          cursor="not-allowed"
        >
          Signing in
          <Spinner
            boxSize="1.33em"
            color="textWhite"
          />
        </Button>
      </Tooltip>
    ),
    [State.LOADING_NETWORK]: () => (
      <Tooltip label="Cancel">
        <Button
          onClick={handleWallet}
          transition="background 5s cubic-bezier(0, 0, 0.11, 0.93) box-shadow unset"
          bg={{ base: '_accentSecondary', lg: '_accentPrimary' }}
          color="textWhite"
          fontSize="12px"
          h="32px"
          padding="0px 10px"
          borderRadius="10px"
          alignItems="center"
          variant="dark"
          gap="8px"
          opacity=".5"
          cursor="not-allowed"
        >
          Reconnecting
          <Spinner
            boxSize="1.33em"
            color="textWhite"
          />
        </Button>
      </Tooltip>
    ),
  }

  const isOnboarding = useMemo(() => location.pathname === '/onboarding', [location.pathname])

  const connectType = React.useMemo(() => {
    if (
      isWalletConnectLoading ||
      (account.isReconnecting && !(!account.connector && account.isConnected)) ||
      account.isConnecting
    ) {
      return State.LOADING_WALLET
    } else if (wallet.isAuthing) {
      return State.LOADING_AUTH
    } else if (!account.isConnected || (!account.isConnected && !wallet.isAuthed && !isOnboarding)) {
      return State.DISCONNECTED
    } else if (wallet.isNetworkSwitching) {
      return State.LOADING_NETWORK
    } else if (account.isConnected && (wallet.isAuthed || isOnboarding) && isWrongNetwork) {
      return State.CONNECTED_WRONG_NETWORK
    } else if (account.isConnected && (wallet.isAuthed || isOnboarding)) {
      return State.CONNECTED
    }

    return State.DISCONNECTED
  }, [
    account.isConnected,
    wallet.isAuthed,
    wallet.isAuthing,
    account.isConnecting,
    account.isReconnecting,
    wallet.isNetworkSwitching,
    isWalletConnectLoading,
    isWrongNetwork,
  ])

  return <>{typeOptions[connectType]()}</>
}
