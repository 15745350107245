import { useState } from 'react'
import { useEthereumProvider } from '../../provider/Ethereum/ethereumProvider'
import { useAccount, useNetwork, useWalletClient } from 'wagmi'
import { Address, erc20ABI, getContract } from '@wagmi/core'
import data from '../../lib/dex-overlay/lib/contract-abi/router-contract'

/**
 * Returns own ERC20 balance
 * @param token ERC20
 * @param account optional user address of which the balance to query for
 */
export const useErc20Balance = (token: address, account?: address) => {
  const [balance, setBalance] = useState<bigint>()

  const wagmiWalletClient = useWalletClient()
  const wagmiAccount = useAccount()
  const { chain: wagmiChain } = useNetwork()

  if (!wagmiWalletClient?.data || (!wagmiAccount?.address && !account) || !wagmiChain) {
    return
  }

  const contract = getContract({
    abi: erc20ABI,
    address: token,
    walletClient: wagmiWalletClient.data!,
  })

  const retrieve = async () => {
    const balance = await contract.read.balanceOf([wagmiAccount.address! || account])
    setBalance(balance)

    return balance
  }

  return {
    balanceCache: balance,
    retrieve,
  }
}
