import React from 'react'
import { Flex } from '@chakra-ui/react'
import UserFollowers from './UserFollowers/userFollowers'
import UserFriends from './UserFriends/userFriends'
import UserFollowing from './UserFollowing/userFollowing'
import UserTransactions from './UserTransactions/userTransactions'
import { Switcher } from '../userContentSwitcher'
import UserPosts from './UserPosts/userPosts'
import { UserGroups } from './UserGroups/userGroups'

const pages = [
  { name: 'Followers', component: <UserFollowers />, id: Switcher.Followers },
  { name: 'Following', component: <UserFollowing />, id: Switcher.Following },
  { name: 'Friends', component: <UserFriends />, id: Switcher.Friends },
  { name: 'Transactions', component: <UserTransactions />, id: Switcher.Transactions },
  { name: 'Posts', component: <UserPosts />, id: Switcher.Posts },
  { name: 'Groups', component: <UserGroups />, id: Switcher.Groups },
]

export const UserWall = ({ value }: { value: any }) => {
  return (
    <Flex
      flexDirection="column"
      gridGap="16px"
      flexGrow={1}
    >
      {pages.map(item => {
        return <React.Fragment key={item.id}> {item.id === value && <>{item.component}</>}</React.Fragment>
      })}
    </Flex>
  )
}

export default UserWall
