import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

export const About = () => {
  const [description, setDescription] = useState<string>()

  return (
    <Flex
      borderRadius="16px"
      border="1px solid"
      borderColor="borderPrimary"
      w="100%"
      flexDirection="column"
    >
      <Flex p="16px">
        <Text
          fontSize="14px"
          fontWeight="500"
          color="textQuaternary"
        >
          About this group
        </Text>
      </Flex>
      <Box
        display="flex"
        w="100%"
        bgColor="borderPrimary"
        h="1px"
      />
      <Flex p="16px">
        <Text
          fontSize="14px"
          color={description ? '_accentSecondary' : '#969cb6'}
        >
          This group has no description yet.
        </Text>
      </Flex>
    </Flex>
  )
}
