import { ArrowRightIcon } from '@chakra-ui/icons'
import { getColor } from '../../../../../../theme/global'
import { useChatProvider } from '../../../../../../provider/Chat/chatProvider'
import { Button, keyframes } from '@chakra-ui/react'

export const bobbingAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(13.3%);
  }
`

export const ChatEndIndicator = () => {
  const chat = useChatProvider()

  return (
    <Button
      variant="slave"
      sx={{
        animation: `${bobbingAnimation} ease-in-out .9s infinite`,
        transition: 'opacity .25s',
        opacity: chat.isAtBottom ? 0 : 1,
      }}
      onClick={chat.handleScrollToBottom}
    >
      <ArrowRightIcon
        sx={{
          transform: 'rotate(90deg)',
          filter: `drop-shadow(0 0 5px ${getColor('_accentPrimary')}) drop-shadow(0 0 20px ${getColor('_accentPrimary')})`,
          color: '_accentPrimary',
          boxSize: '32px',
        }}
      />
    </Button>
  )
}
