import { Box, Button, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { ButtonFollow } from '../actions/ButtonFollow'
import { UserTag } from '../tags/UserTag'
import { Avatar } from '../avatars/Avatar'
import { IUseProfile, useProfile } from '../../../hooks/useProfile'
import { useFriend } from '../../../hooks/useFriend'
import { IIdHelper } from '../../../provider/Profile/profileProvider'
import { buildIpfsGateway } from '../../../utils/parser'
import { useIconProvider } from '../../../provider/Icons/iconsProvider'
import { getColor } from '../../../theme/global'

interface IProps extends IIdHelper {
  customData?: IUseProfile
}

enum EVariant {
  Fetching,
  Fetched,
}

// todo make this a popover
// todo make this global for all user avatars / nicknames / avatar-nickname pairs
export const UserPopup = ({ address, customData }: IProps) => {
  const [showProfileDetails, setShowProfileDetails] = useState(true)

  const data_ = useProfile({ address })
  const data = customData ?? data_

  const friend = useFriend({ address })

  const { interests, roles } = useIconProvider()

  const variant = {
    [EVariant.Fetching]: () => (
      <Spinner
        color="text"
        size="lg"
        thickness="4px"
        speed=".66s"
      />
    ),
    [EVariant.Fetched]: () => (
      <>
        <Flex
          transition="background 1s ease-in-out"
          background={data.background ? buildIpfsGateway(data.background) : 'none'}
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          w="100%"
          h={data.background ? '80px' : 0}
          borderRadius="16px 16px 0 0"
          cursor="auto"
        />
        <Flex
          w="100%"
          p="16px"
          position="relative"
          borderRadius="0 0 16px 16px"
          flexDir="column"
          gap="16px"
          cursor="auto"
        >
          <Flex
            position="absolute"
            transform="translate(0, -30%)"
            top="0"
          >
            <Avatar
              bgColor="bluePlaceholder"
              size="54px"
              image={data.avatar}
              name={data.name ? data.name : 'N N'}
              textSize={{ long: '14px', short: '24px' }}
            />
          </Flex>
          <Flex
            w="100%"
            justify="flex-end"
            gap="8px"
          >
            <Button
              w="32px"
              p="0"
              onClick={friend.friend}
              isLoading={friend.isLoading}
              isDisabled={friend.isDisabled}
            >
              <Image src="/assets/icons/userAdd.svg" />
            </Button>
            <ButtonFollow target={data.address} />
          </Flex>
          <Flex flexDir="column">
            <Flex
              align="center"
              gap="5px"
            >
              <Text
                fontSize="14px"
                color={data.name ? 'textPrimary' : 'textTertiary'}
              >
                {data.name ? data.name : 'Bez nazwy'}
              </Text>
            </Flex>

            {data.nickname && (
              <Text
                color="textSecondary"
                fontSize="12px"
              >
                @{data.nickname}
              </Text>
            )}
          </Flex>
          <Text
            fontSize="14px"
            color="textQuaternary"
          >
            {data.description}
          </Text>
          <Flex
            gap="8px"
            align="center"
            fontSize="12px"
          >
            <Flex gap="4px">
              <Text color="textQuaternary">{data.followers?.length ?? 0}</Text>
              <Text color="textSecondary">Followers</Text>
            </Flex>
            <Image src="/assets/icons/dot.svg" />
            <Flex gap="4px">
              <Text color="textQuaternary">{data.friends?.length ?? 0}</Text>
              <Text color="textSecondary">Friends</Text>
            </Flex>
          </Flex>
        </Flex>
      </>
    ),
  }

  return (
    <Flex
      flexDirection="column"
      top="48px"
      position="absolute"
      left="12px"
      borderRadius="16px"
      transition="background 1s ease-in-out"
      boxShadow={`0px 4px 8px ${getColor('borderPrimary')}`}
      w="260px"
      align="center"
      justify="center"
      zIndex="modal"
      bg="backgroundMain"
      minH="80px"
      onClick={e => e.preventDefault()}
      display={{ base: 'none', md: 'flex' }}
    >
      <Box
        position="absolute"
        top="-8px"
        height="34px"
        bg="transparent"
        w="260px"
      />
      {variant[data.isLoading ? EVariant.Fetching : EVariant.Fetched]()}
    </Flex>
  )
}
