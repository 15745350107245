import { useState } from 'react'
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  color,
  useToast,
} from '@chakra-ui/react'
import { CardContainer } from '../components/shared/containers/CardContainer'
import ProfilesInfo from '../components/pages/Pages/profilesInfo'
import useAsyncEffect from '../hooks/effects/async'
import moment from 'moment/moment'
import { buildIpfsGateway, getInitials, trimAddress } from '../utils/parser'
import { Link } from 'react-router-dom'
import { IProfileBatch } from '../provider/Profile/profileProvider'
import { fetchApi } from '../utils/fetcher'
import { IRate } from '../components/pages/Dashboard/LatestProfiles/dailyProfiles'
import { loaded } from '../utils/process'
import { copyToClipboard } from '../utils/os'
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from '@ajna/pagination'
import { getColor } from '../theme/global'

export const Dao = () => {
  const [data, setData] = useState<IProfileBatch>([])
  const [numOfPages, setNumOfPages] = useState(1)

  const toast = useToast()
  const infoProfiles = [
    { title: 'Degen Holders', subTitle: 'Holders', number: 2137 /* dummy */, percent: 0, subInfo: 'Procent' },
    {
      title: 'Degenów do wydania',
      number: 4988,
      percent: 0,
      subTitle: 'Liczba',
      subInfo: 'Ostatnie 24h',
    },
  ] as any

  const outerLimit = 2
  const innerLimit = 2
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: numOfPages * 10,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })

  const handlePageChange = async (page: number) =>
    loaded(async () => {
      setCurrentPage(page)
      const data = await fetchApi(`profile/page/${page}`)

      data.profiles.forEach((datum: any) => (datum.address = datum.address_?.[0]))
      setData(data.profiles)
      setNumOfPages(data.totalPages)
    })

  const handleCopyLink = async (address: address) => {
    await copyToClipboard(`${document.location.host}/profile/${address}`)
    toast({ title: `Copied!` })
  }

  const handleClick = () => {
    toast({ title: 'Już wkrótce!', status: 'info' })
  }

  useAsyncEffect(
    () =>
      loaded(async () => {
        const data = await fetchApi(`profile/page/1`)

        data.profiles.forEach((datum: any) => (datum.address = datum.address_?.[0]))
        setData(data.profiles)
        setNumOfPages(data.totalPages)
      }),
    []
  )

  return (
    <Flex
      justify="center"
      w="100%"
      direction="column"
    >
      <Flex
        sx={{
          gap: '0',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Text
          pt="16px"
          fontSize="24px"
          color="textPrimary"
          fontWeight="600"
          mx={{ base: '16px', degen: 'unset' }}
        >
          Skarbiec
        </Text>
        <Text
          pt="32px"
          pb="24px"
          fontSize={{base: '48px', sm: '64px', md: '96px', lg: "128px"}}
          color="textPrimary"
          textAlign="center"
          fontWeight="600"
          mx={{ base: '16px', degen: 'unset' }}
          sx={{
            textShadow: `${getColor('_accentPrimary')} 0 0 25px`,
          }}
        >
          $1.254.832,53{/* dummy */}
        </Text>
      </Flex>
      <Flex
        w="100%"
        justifyContent="space-between"
        pb="32px"
        gridGap="24px"
        direction={{ base: 'column', lg: 'row' }}
      >
        {infoProfiles.map((item: any) => (
          <ProfilesInfo
            key={item.title}
            title={item.title}
            numbers={item.number}
            percent={item.percent}
            subTitle={item.subTitle}
            subInfo={item.subInfo}
          />
        ))}
        <Tooltip label="Wkrótce">
          <Button
            p="16px"
            variant="slave"
            borderRadius="16px"
            w={{ base: '100%', lg: '33%' }}
            gridGap="8px"
            sx={{
              display: 'flex',
              bg: '_foregroundSecondary',
              color: 'white',
              fontWeight: '600',
              fontSize: '24px',
              border: '1px solid',
              borderColor: 'borderPrimary',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              minH: {
                base: '130px',
                lg: 'unset',
              },
              _focusVisible: {
                borderColor: '_foregroundPrimary',
              },
              _hover: {
                borderColor: '_foregroundPrimary',
              },
              _active: {
                borderColor: '_foregroundPrimary',
              },
            }}
            onClick={handleClick}
          >
            Zaproponuj coś, co zwiększy adopcję w Polsce
          </Button>
        </Tooltip>
      </Flex>
      <Text
        pb="8px"
        fontSize="18px"
        color="textPrimary"
        fontWeight="500"
        fontStyle="italic"
        mx={{ base: '16px', degen: 'unset' }}
      >
        Poprzednie głosowania
      </Text>
      <CardContainer props={{ overflow: 'scroll' }}>
        {/* <TableContainer w="100%"  maxW="100vw"> */}
        <Table variant="simple">
          <Thead
            bgColor="backgroundMain"
            borderRadius="20px"
          >
            <Tr borderRadius="8px">
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Profile
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Username
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Followers
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Join&nbsp;date
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Address
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              />
            </Tr>
          </Thead>
          <Tbody alignItems="center">
            {data.map(datum => (
              <Tr
                key={datum.address}
                fontSize="14px"
                maxW="100%"
              >
                <Td
                  color="_accentPrimary"
                  border="0px"
                  position="relative"
                  minW="220px"
                >
                  <Link
                    to={`/profile/${datum.address}`}
                    style={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                      w="40px"
                      h="40px"
                      bgColor="bluePlaceholder"
                      color="_textQuaternary"
                      borderRadius="50%"
                      backgroundImage={buildIpfsGateway(datum.avatar)}
                      backgroundPosition="center center"
                      backgroundRepeat="no-repeat"
                      backgroundSize="cover"
                      userSelect="none"
                      position="relative"
                    >
                      {(!datum.avatar || datum.avatar.length < 35) && (
                        <Text textTransform="uppercase">{getInitials(datum.name)}</Text>
                      )}
                    </Box>
                    <Text color={datum.name ? 'textPrimary' : 'textTertiary'}>{datum.name || 'Bez nazwy'}</Text>
                  </Link>
                </Td>
                <Td
                  color="_accentSecondary"
                  border="0px"
                >
                  {/* TODO: Make this use `datum.isOnboarded` */}
                  {datum.nickname ? <Text>@{datum.nickname}</Text> : <Text color="textTertiary">Inactive</Text>}
                </Td>
                <Td
                  color="_accentSecondary"
                  border="0px"
                >
                  {!!datum.followers?.length && <Text>{datum.followers.length}</Text>}
                  {!!datum.followers?.length || <Text opacity=".33">-</Text>}
                </Td>
                <Td
                  color="textSecondary"
                  border="0px"
                  minW="150px"
                >
                  {moment(datum._created_at).fromNow()}
                </Td>
                <Td
                  color="_accentPrimary"
                  border="0px"
                >
                  <Tooltip
                    hasArrow
                    label="Copy profile link"
                  >
                    <Flex
                      onClick={() => handleCopyLink(datum.address!)}
                      cursor="pointer"
                      w="auto"
                      borderRadius="8px"
                      border="1px solid"
                      borderColor="borderPrimary"
                      p="6px 8px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        color="_accentPrimary"
                        mx="4px"
                      >
                        {trimAddress(datum.address)}
                      </Text>
                      <Box
                        bgColor="borderPrimary"
                        borderRadius="4px"
                        w="20px"
                        h="20px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Image src="/assets/icons/link-icon.svg" />
                      </Box>
                    </Flex>
                  </Tooltip>
                </Td>
                <Td
                  color="textSecondary"
                  border="0px"
                  p="0px"
                  m="0px"
                  justifyContent="flex-end"
                  w="32px"
                  alignItems="center"
                >
                  <Link to={`/profile/${datum.address}`}>
                    <Box
                      border="1px solid"
                      borderColor="_borderPrimary"
                      borderRadius="8px"
                      w="32px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        src="/assets/icons/eye-icon2.svg"
                        boxSize="16px"
                      />
                    </Box>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex
          w="100%"
          justify="center"
          gap="8px"
        >
          {/* @ts-ignore */}
          <Pagination
            pagesCount={numOfPages}
            currentPage={currentPage}
            // normalStyles={normalStyles}
            // activeStyles={activeStyles}
            // separatorStyles={baseStyles}
            onPageChange={(p: number) => handlePageChange(p)}
          >
            <PaginationContainer
              align="center"
              justify="space-between"
              w="auto"
              p={4}
              gap="4px"
            >
              <PaginationPrevious
                border="none"
                maxH="32px"
                maxW="32px"
                minH="32px"
                minW="32px"
                p="0"
              >
                <Image
                  src="/assets/icons/chevron-right.svg"
                  transform="rotate(180deg)"
                />
              </PaginationPrevious>
              <PaginationPageGroup
                align="center"
                separator={
                  <PaginationSeparator
                    isDisabled
                    bg="_accentSecondaryLight"
                    border="none"
                    fontSize="sm"
                    w="32px"
                    h="32px"
                    jumpSize={11}
                  />
                }
              >
                {pages.map((page: number) => (
                  <PaginationPage
                    w="32px"
                    h="32px"
                    border="none"
                    bg="none"
                    key={`pagination_page_${page}`}
                    page={page}
                    fontSize="sm"
                    color="_textSecondary"
                    _hover={{
                      bg: '_accentSecondaryLight',
                    }}
                    _current={{
                      bg: '_accentSecondaryLight',
                      fontSize: 'sm',
                      color: '_accentPrimary',
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                border="none"
                maxH="32px"
                maxW="32px"
                minH="32px"
                minW="32px"
                p="0"
              >
                <Image src="/assets/icons/chevron-right.svg" />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Flex>
        {/* </TableContainer> */}
      </CardContainer>
    </Flex>
  )
}

export default Dao
