import {
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  UseDisclosureReturn,
  useToast,
} from '@chakra-ui/react'

import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import useAsyncEffect from '../../../hooks/effects/async'
import { loaded } from '../../../utils/process'
import { fetchApi } from '../../../utils/fetcher'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { useGroupProvider } from '../../../provider/Group/groupProvider'
import { RegexUtils } from '../../../utils/regex'
import { useIconProvider } from '../../../provider/Icons/iconsProvider'
import { IRoles } from '../../../types/api'

type JoinAsModalProps = {
  disclosure: UseDisclosureReturn
}

type Role = {
  name: string
  icon: string
}

export const JoinAsModal: FC<JoinAsModalProps> = ({ disclosure }) => {
  // TODO: Make this use endpoint looking through group user's roles by user names and their roles.
  const [isJoiningGroup, setIsJoiningGroup] = useState(false)

  const { isOpen, onClose } = disclosure
  const [roles, setRoles] = useState<Role[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [searchedRoles, setSearchedRoles] = useState<Role[]>([])
  const [areRolesLoading, setAreRolesLoading] = useState(true)
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const group = useGroupProvider()

  const accountProfile = useAccountProfileProvider()
  const wallet = useEthereumProvider()

  const toast = useToast()

  const { roles: rolesIcons } = useIconProvider()

  useAsyncEffect(async () => {
    await loaded(async () => {
      const data = []
      const roles: Role[] = Object.values(data).flat()
      if (roles.length >= 30) {
        setRoles(roles)
        setSearchedRoles(roles)
      }
    }, setAreRolesLoading)
  }, [])

  useEffect(() => {
    if (searchValue === '') {
      setSearchedRoles(roles)
    } else {
      setSearchedRoles(roles.filter(r => r.name.toLowerCase().includes(searchValue.toLowerCase().trim())))
    }
  }, [searchValue])

  const handleJoin = async () =>
    await loaded(
      async () => {
        if (selectedRoles.length === 0) {
          return
        }
        const res = await fetchApi('group/join', { group: group.address, asRoles: selectedRoles })

        toast({ status: 'info', title: 'Joined to the group' })
      },
      setIsJoiningGroup,
      e => {
        toast({ status: 'error', title: e?.response?.data?.message })
      }
    )

  const userRoles = useMemo(
    () =>
      accountProfile.roles
        ?.filter(value => RegexUtils.insensitiveInexact(searchValue).test(value))
        .map(role => (
          <GroupElement
            key={role}
            icon={rolesIcons.get(role) ?? ''}
            name={role}
            setSelectedRoles={setSelectedRoles}
            selectedRoles={selectedRoles}
          />
        )),
    [searchValue, accountProfile, selectedRoles]
  )

  const groupRoles = useMemo(
    (): React.ReactNode[] =>
      Array.from(new Set(group.members?.reduce((acc, current) => [...acc, ...current.roles], [] as string[])))
        .filter(value => RegexUtils.insensitiveInexact(searchValue).test(value))
        .map(role => (
          <GroupElement
            key={role}
            icon={rolesIcons.get(role) ?? ''}
            name={role}
            setSelectedRoles={setSelectedRoles}
            selectedRoles={selectedRoles}
          />
        )),
    [searchValue, accountProfile, selectedRoles]
  )

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px solid borderPrimary">
          <Text
            color="texTeritary"
            fontSize="18px"
            fontWeight="600"
          >
            Join the group
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p="0px"
          m="0px"
        >
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="flex-start"
            gap="24px"
            p="24px"
          >
            <Flex
              gap="8px"
              flexDirection="column"
              alignItems="start"
              justifyContent="flex-start"
              width="100%"
            >
              <Text
                color="_accentSecondary"
                fontSize="14px"
                fontWeight="600"
              >
                Enter the role name you are looking for
              </Text>
              <Input
                color="textSecondary"
                width="100%"
                autoFocus
                placeholder="Search by user name or role"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                fontSize="14px"
                sx={{ px: '12px', py: '10px' }}
              />
              <Text
                color="textSecondary"
                fontSize="14px"
              >
                You can select more than one role
              </Text>
            </Flex>
            {areRolesLoading ? (
              <Center
                height="200px"
                width="100%"
              >
                <Spinner />
              </Center>
            ) : (
              <Flex
                css={{
                  '&::-webkit-scrollbar': {
                    width: '6px',
                    // background: "_backgroundTertiary",
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '_accentPrimary',
                    borderRadius: '4px',
                    height: '200px',
                  },
                }}
                flexDirection="column"
                maxHeight="300px"
                overflowY="scroll"
                px="2px"
              >
                <Flex
                  flexDirection="column"
                  alignItems="start"
                  justifyContent="flex-start"
                  gap="4px"
                  mb="5px"
                >
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="_accentSecondary"
                  >
                    Current roles in the group
                  </Text>
                  <Text
                    color="textSecondary"
                    fontSize="14px"
                  >
                    It is recommended to join a role that exists in the group to have access to thematic feeds
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  flexDirection="column"
                  gap="6px"
                  width="100%"
                >
                  {/* 1. Field disabled if all slots (of that type) already taken */}

                  {groupRoles}
                </Flex>
                <Flex
                  flexDirection="column"
                  alignItems="start"
                  justifyContent="flex-start"
                  gap="4px"
                  mt="19px"
                  mb="5px"
                >
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="_accentSecondary"
                  >
                    Your roles
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  flexDirection="column"
                  gap="6px"
                  width="100%"
                >
                  {userRoles}
                </Flex>
              </Flex>
            )}
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="column"
              gap="6px"
              width="100%"
            ></Flex>
          </Flex>
        </ModalBody>
        <ModalFooter borderTop="1px solid borderPrimary">
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            background="_accentSecondary"
            textColor="#000000"
            sx={{ px: '16px', py: '8px' }}
            isDisabled={selectedRoles.length === 0 || !wallet.isLoggedIn}
            onClick={handleJoin}
            isLoading={isJoiningGroup}
          >
            Join the group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type GroupElementProps = Role & {
  isDisabled?: boolean

  selectedRoles: string[]
  setSelectedRoles: Dispatch<SetStateAction<string[]>>
}

const GroupElement: FC<GroupElementProps> = ({ isDisabled = false, name, icon, selectedRoles, setSelectedRoles }) => {
  const handleSelect = () => {
    if (!selectedRoles.some(sr => sr.toLowerCase() === name.toLowerCase())) {
      setSelectedRoles(prev => [...prev, name])
    } else {
      setSelectedRoles(prev => prev.filter(sr => sr.toLowerCase() !== name.toLowerCase()))
    }
  }
  return (
    <Flex
      opacity={isDisabled ? '0.4' : '1'}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Flex
        gap="12px"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Checkbox
          iconColor="textQuaternary"
          width="16px"
          height="16px"
          isChecked={selectedRoles.some(sr => sr.toLowerCase() === name.toLowerCase())}
          onChange={handleSelect}
          isDisabled={isDisabled}
        />
        <Grid
          border="1px solid"
          borderColor="_backgroundTertiary"
          sx={{ boxSize: '28px' }}
          borderRadius="100%"
          placeItems="center"
        >
          <Image
            src={icon}
            width="14px"
            height="14px"
          />
        </Grid>
        <Text
          color="textQuaternary"
          fontSize="14px"
        >
          {name}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        gap="8px"
      >
        {/* TODO: Map users having posted an offer post with the given role available in its slots */}
        {/* <Flex
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
        >
          <Image
            src="/assets/icons/coin-dollar.svg"
            width="14px"
            height="14px"
          />
          <Text
            color="textQuaternary"
            fontSize="10px"
          >
            2 offers
          </Text>
        </Flex> */}
        {/* TODO: Map users with shared roles that have joined in the group */}
        {/* <Flex
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
        >
          <Image
            src="/assets/icons/user-icon.svg"
            width="14px"
            height="14px"
          />
          <Text
            color="textQuaternary"
            fontSize="10px"
          >
            2 users
          </Text>
        </Flex> */}
      </Flex>
    </Flex>
  )
}
