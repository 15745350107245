import { createContext, useContext, useEffect, useReducer } from 'react'
import { io } from 'socket.io-client'
import { getDegenPlatformJwtAuthKey } from '../../utils/auth'
import { fetchApi } from '../../utils/fetcher'
import { useEthereumProvider } from '../Ethereum/ethereumProvider'
import { IState, Reducer, emptyState } from './reducer'

const api = process.env.REACT_APP_API as string

export const socket = io(api.slice(0, -3) + '/no', {
  transports: ['websocket'],
  autoConnect: true,
})

interface IContext extends IState {
  handleOpenModal: () => void
}

const emptyContext: IContext = {
  ...emptyState,
  isLoading: false,
  handleOpenModal: () => {},
}

const Context = createContext(emptyContext)

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, emptyContext)
  const wallet = useEthereumProvider()

  useEffect(() => {
    function onConnect() {
      const key = getDegenPlatformJwtAuthKey(wallet.account as address)
      const secret = localStorage.getItem(key)

      socket.emit('establishConnection', { jwt: secret, address: wallet.account as address })
    }

    function onNotification(data: any) {
      let newNotifications = [data].concat(state.notifications)

      dispatch({
        notifications: newNotifications,
        unreadCount: state.unreadCount + 1,
      })
    }

    function onInit(data: any) {
      data.reverse()
      let amountUnread = data.filter(item => item.isRead == false).length

      dispatch({
        notifications: data,
        unreadCount: amountUnread,
      })
    }

    socket.connect()

    socket.on('init', onInit)
    socket.on('connect', onConnect)
    socket.on('notification', onNotification)

    return () => {
      socket.off('connect', onConnect)
      socket.off('init', onInit)
      socket.off('notification', onNotification)
    }
  }, [wallet.account, state])

  const handleOpenModal = async () => {
    fetchApi('notification/flagRead', { ids: [] }).then(() => {
      dispatch({
        unreadCount: 0,
      })
    })
  }

  return (
    <Context.Provider
      value={{
        ...state,
        handleOpenModal,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useNotificationProvider = () => useContext(Context)
