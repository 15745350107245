import React, { useEffect } from 'react'
import { useAccountProfileProvider } from '../AccountProfile/context'
import { useLocation } from 'react-router-dom'
import { useEthereumProvider } from '../Ethereum/ethereumProvider'

export interface IOnboarding {
  shouldElevateTopBar: boolean
  setShouldElevateTopBar: React.Dispatch<React.SetStateAction<boolean>>
}

export const emptyOnboarding: IOnboarding = {
  shouldElevateTopBar: false,
  setShouldElevateTopBar: () => null,
}

export const OnboardingProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldElevateTopBar, setShouldElevateTopBar] = React.useState(false)

  const location = useLocation()
  const account = useAccountProfileProvider()
  const { account: ethereumAccount } = useEthereumProvider()
  const wallet = useEthereumProvider()

  useEffect(
    () =>
      setShouldElevateTopBar(
        (!true && new RegExp(`^/profile/${account.address}`, 'i').test(location.pathname)) ||
          ((!wallet.isAuthed || !ethereumAccount) && !wallet.isConnecting && false)
      ),
    [location.pathname, account.isOnboarded]
  )

  return (
    <OnboardingContext.Provider value={{ shouldElevateTopBar, setShouldElevateTopBar }}>
      {children}
    </OnboardingContext.Provider>
  )
}

const OnboardingContext = React.createContext(emptyOnboarding)

export const useOnboardingProvider = () => {
  const context = React.useContext(OnboardingContext)

  if (!context) {
    throw new Error('`useOnboardingProvider` cannot be used outside of a `OnboardingProvider`!')
  }
  return context
}
