import { Box, Flex } from '@chakra-ui/layout'
import { InputGroup, Input, InputRightAddon, Button, Image, Spinner, Tooltip } from '@chakra-ui/react'
import { Switcher } from './Switcher'
import { IToken as IToken_, useSwapProvider } from '../../../../../lib/dex-overlay/components/SwapProvider'
import { trimDecimals } from '../../../../../utils/ts'
import { formatUnits, parseUnits } from 'viem'
import { useErc20Balance } from '../../../../../hooks/web3/useErc20Balance'

enum EToken {
  Default,
  Dummy,
}

interface IToken<T = EToken.Default> {
  token: IToken_
  isThreadToken?: boolean
}

export const Token = <T,>({ token, isThreadToken }: IToken<T>) => {
  const swap = useSwapProvider()
  /** external token balance (thread token does not use/init this functionality, ever) */
  const erc20Balance = useErc20Balance(token.address)

  const handleSetValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      swap.setValue(undefined)
      return
    }

    const reducedDecimalsValue = trimDecimals(event.target.value, token.decimals!)
    const unitsParsedValue = parseUnits(reducedDecimalsValue, token.decimals!)

    const parsedValue = BigInt(unitsParsedValue)
    swap.setValue(parsedValue)
  }

  const handleMax = async () => {
    swap.setValue(await erc20Balance?.retrieve())
  }

  const handleHalve = async () => {
    if (!token.value) {
      const max = await erc20Balance?.retrieve()

      swap.setValue((max || BigInt(0)) / BigInt(2))
      return
    }

    swap.setValue(token.value / BigInt(2))
  }

  // Token loading state: 1. tokenValue: undefined 2. _placeholder: <SpinnerText /> 3. readonly: true

  return (
    <Flex direction="column">
      <InputGroup>
        <Flex
          sx={{
            gap: '8px',
            flexGrow: 1,
          }}
          align="center"
        >
          {isThreadToken ? (
            <Flex
              sx={{
                boxSize: '40px',
                flexShrink: 0,
              }}
              align="center"
              justify="center"
            >
              <Image
                boxSize="40px"
                src={token.iconUri}
                fallback={<Spinner />}
              />
            </Flex>
          ) : (
            <Switcher />
          )}
          <Flex sx={{ position: 'relative', w: '100%' }}>
            <Input
              type="number"
              placeholder="0"
              value={
                (token.value !== undefined ? formatUnits(token.value, token.decimals!) : undefined)?.toString() || ''
              }
              onChange={handleSetValue}
              {...(isThreadToken ? { readOnly: true } : {})}
              sx={{
                borderRight: 'none',
                borderRightRadius: 0,
                borderColor: '_foregroundPrimary',
                borderLeft: 'none',
                borderLeftRadius: 0,
                clipPath: 'polygon(66% 1%, 100% 0, 100% 100%, 40% 99%, 3% 97%, 3% 3%)',
                _placeholder: {
                  color: '_textPrimary',
                },
              }}
            />
          </Flex>
        </Flex>
        <InputRightAddon
          sx={{
            p: '0',
            border: 'none',
            overflow: 'hidden',
            borderRightRadius: '8px',
          }}
          children={
            <Flex
              direction="column"
              sx={{
                button: {
                  boxSize: '100%',
                  borderRadius: 0,
                  p: '0',
                },
                boxSize: '100%',
              }}
            >
              <Tooltip
                label="This halves current amount"
                hasArrow
                openDelay={1000}
                placement="top-end"
              >
                <Button
                  variant="light"
                  onClick={handleHalve}
                  isDisabled={isThreadToken}
                >
                  ½
                </Button>
              </Tooltip>
              <Button
                variant="light"
                onClick={handleMax}
                isDisabled={isThreadToken}
              >
                MAX
              </Button>
            </Flex>
          }
        />
      </InputGroup>
    </Flex>
  )
}
