import { Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useGroupProvider } from '../../../../../provider/Group/groupProvider'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import { useState } from 'react'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import { loaded } from '../../../../../utils/process'
import { _log } from '../../../../../logger'
import { fetchApi } from '../../../../../utils/fetcher'
import { MemberItem } from './MemberItem'
import { GroupContentSearch } from '../../GroupContentSearch'

export type Member = {
  address: `0x${string}`
  roles: string[]
}

export const Members = () => {
  const group = useGroupProvider()
  const [isUserPowerLoading, setIsUserPowerLoading] = useState(true)
  const [userPower, setUserPower] = useState(0)
  const account = useAccountProfileProvider()
  const [members, setMembers] = useState(group.members)

  const handleSearch = (e: any) => {
    const filteredMembers = group.members?.filter((member: Member) =>
      member.address.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setMembers(filteredMembers)
  }

  useAsyncEffect(async () => {
    if (account.address) {
      await loaded(
        async () => {
          const power: number = await fetchApi('group/getUserPower', { group: group.address })
          setUserPower(Number(power.toFixed(4)) * 1)
        },
        setIsUserPowerLoading,
        e => {
          _log({ e })
        }
      )
    }
  }, [account])

  return (
    <Flex
      sx={{
        width: '100%',
        flexDir: 'column',
        gap: '8px',
      }}
    >
      <GroupContentSearch
        placeholder="Search for members"
        searchAction={handleSearch}
      />
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Flex sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
          <Flex
            sx={{
              border: '1px solid',
              borderColor: 'borderPrimary',
              width: '36px',
              height: '36px',
              borderRadius: '100%',
              background: 'backgroundMain',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <Image
              src="/assets/icons/data-users.svg"
              width={19}
              height={19}
            />
          </Flex>
          <Text sx={{ fontSize: '14px', color: '_accentSecondary' }}>
            All members{' '}
            <Text
              as="span"
              sx={{ fontWeight: 500 }}
            >
              {group.members?.length}
            </Text>
          </Text>
        </Flex>
        <Flex sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: '5px' }}>
          <Image
            src="/assets/icons/coin-dollar.svg"
            width="16px"
            height="16px"
          />
          <Flex>
            <Text sx={{ color: 'textSecondary', fontSize: '12px', lineHeight: '16px' }}>
              My voting power:{' '}
              <Text
                as="span"
                sx={{ color: '_accentPrimary' }}
              >
                {isUserPowerLoading ? <SpinnerText /> : userPower} {group.symbol}
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {members?.length ? (
        <Flex
          sx={{
            borderRadius: '16px',
            border: '1px solid',
            borderColor: 'borderPrimary',
            bg: 'backgroundMain',
            flexDir: 'column',
          }}
        >
          {members?.map((member: Member, index: number) => (
            <>
              <MemberItem
                member={member}
                key={member?.address}
              />
              {index !== group?.members!.length - 1 && <Divider />}
            </>
          ))}
        </Flex>
      ) : (
        <Text sx={{ fontSize: '14px', w: '100%', textAlign: 'center', color: 'textTertiary', mt: '16px' }}>
          There are no members yet.
        </Text>
      )}
    </Flex>
  )
}
