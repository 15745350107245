import { Box, Flex, Image, Input, Text } from '@chakra-ui/react'
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import { Category, Item } from './utils'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useIconProvider } from '../../../provider/Icons/iconsProvider'

export enum Type {
  INTERESTS,
  ROLES,
}

export interface ISelect {
  title: string
  placehodler: string
  buttonText: string
  items: string[]
  setItems: Dispatch<SetStateAction<string[]>>
  fetch: () => Promise<Category[]>
  type: Type

  showInterestedAmount?: boolean
  showIcon?: boolean
  showWarning?: boolean
}

export const Select: FC<ISelect> = ({
  title,
  placehodler,
  buttonText,
  items,
  setItems,
  fetch,
  type,
  showInterestedAmount,
  showIcon = true,
  showWarning = false,
}) => {
  const [exampleCategories, setExampleCategories] = useState<Category[]>()
  const [initialCategories, setInitialCategories] = useState<Category[]>()
  const [input, setInput] = useState<string>('')
  const [areItemsVisible, setAreItemsVisible] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<Item[]>([])

  const interestedAmount = 0

  const selectRef = useRef(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef(null)
  const pickerRef = useRef(null)
  const inputRef = useRef(null)
  const { interests, roles } = useIconProvider()

  useEffect(() => {
    fetch().then(res => {
      setExampleCategories(res)
      setInitialCategories(res)
    })
  }, [])

  const handleSearch = (e: any) => {
    const inputValue = e.target.value
    setInput(inputValue)

    const results: Item[] = []

    exampleCategories?.forEach((category: Category) => {
      const filteredItems = category.items.filter((item: Item) => {
        return item.name.toLowerCase().includes(inputValue.toLowerCase())
      })
      results.push(...filteredItems)
    })

    setSearchResults(results)
  }

  const handleSubmit = (itemName?: string) => {
    let name = input
    if (itemName !== undefined) {
      name = itemName
      setInput('')
    }
    if (name === '') {
      return
    }
    // const exampleItem = exampleItems.filter((exampleItem: Item) => exampleItem.value === name)
    // if (exampleItem.length == 0) {
    //   return
    // }

    if (items.filter((item: string) => item === name)?.length > 0) {
      return
    }

    setItems((prevState: any) => [...prevState, name])

    setInput('')
  }

  const removeItem = (name: string) => {
    setItems((prevItems: string[]) => prevItems.filter((item: string) => item !== name))
  }

  const handleDrop = (droppedItem: any) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return
    var updatedList = [...items]
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    // Update State
    setItems(updatedList)
  }

  useEffect(() => {
    const background = (((backgroundRef.current as any).parentNode as any).parentNode as any).parentNode

    const handleClick = event => {
      if ((selectRef.current as any)?.contains(event.target) || (pickerRef.current as any)?.contains(event.target)) {
      } else {
        setAreItemsVisible(false)
        ;(inputRef.current as any)?.blur()
      }
    }

    background.addEventListener('click', handleClick)

    return () => background.removeEventListener('click', handleClick)
  }, [])

  useEffect(() => {
    const notSelectedItems: Category[] =
      initialCategories?.map((category: Category) => {
        return {
          ...category,
          items: category.items.filter((item: Item) => !items.includes(item.name)),
        }
      }) ?? []

    setExampleCategories(notSelectedItems)
  }, [items])

  return (
    <Flex
      width="full"
      gap="8px"
      ref={backgroundRef}
      direction="column"
      height="full"
    >
      <Text fontSize="16px">{title}</Text>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          {provided => (
            <Box ref={parentRef}>
              <Flex
                justify="flex-start"
                direction="column"
                mt="8px"
                gap="16px"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items?.map((item: string, index: number) => {
                  return (
                    <Draggable
                      key={`${item}_${index}}`}
                      draggableId={item}
                      index={index}
                    >
                      {provided => (
                        // @ts-ignore
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box
                            bg="backgroundMain"
                            key={item}
                            display="flex"
                            flexDir="row"
                            alignItems="center"
                            justifyContent="space-between"
                            borderWidth="1px"
                            padding="8px 10px"
                            borderRadius="8px"
                            borderColor="_borderPrimary"
                          >
                            <Box
                              display="flex"
                              flexDir="row"
                              alignItems="center"
                              w="full"
                              justifyContent="space-between"
                              gap="8px"
                              px="8px"
                            >
                              <Flex
                                gap="8px"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                {showIcon && !!(type === Type.INTERESTS ? interests.get(item) : roles.get(item)) && (
                                  <Image
                                    src={type === Type.INTERESTS ? interests.get(item) : roles.get(item)}
                                    width="18px"
                                    height="18px"
                                  />
                                )}
                                <Text color="textQuaternary">{item}</Text>
                              </Flex>
                              <Flex gap="12px">
                                {showInterestedAmount && !!interestedAmount && (
                                  <Text color="textSecondary">{interestedAmount} Interested</Text>
                                )}
                                <Image
                                  src="/assets/icons/trash.svg"
                                  onClick={() => removeItem(item)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Flex>
                            </Box>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              </Flex>
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <Box
        position="relative"
        ref={selectRef}
        onFocus={() => setAreItemsVisible(true)}
      >
        <Input
          border="1px solid"
          borderColor="#9DB5FF"
          px="12px"
          py="10px"
          borderRadius="8px"
          outline="none"
          onChange={e => handleSearch(e)}
          ref={inputRef}
          placeholder={`${placehodler}`}
          _placeholder={{
            color: 'textTertiary',
          }}
        />
        <Box
          ref={pickerRef}
          css={{
            '&::-webkit-scrollbar': {
              width: '6px',
              // background: "_backgroundTertiary",
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '_accentPrimary',
              borderRadius: '4px',
              height: '200px',
            },
          }}
          overflowY="hidden"
          sx={{
            boxShadow: '0px 4px 8px 0px rgba(16, 24, 64, 0.08)',
            display: areItemsVisible ? 'block' : 'none',
          }}
          position="absolute"
          width="full"
          maxH="370px"
          p="20px"
          bg="backgroundMain"
          top="50px"
          borderRadius="4px"
          overflow="scroll"
          zIndex="1401"
        >
          <Box>
            <Text
              color="textSecondary"
              fontSize="14px"
              fontWeight="500"
            >
              {input.length > 0 ? `Results: ${searchResults.length}` : ''}
            </Text>
          </Box>
          {input?.length > 0 ? (
            <Flex
              width="full"
              direction="column"
              mt="21px"
            >
              {searchResults.map((result: Item, index: number) => (
                <CategoryElement
                  key={`${result.id}-${result.name}-${result.category}_${index}`}
                  item={result}
                  addItem={handleSubmit}
                />
              ))}
            </Flex>
          ) : (
            exampleCategories?.map((category: Category, index: number) => {
              return (
                <Box key={`${category.name}-${category.id}_${index}`}>
                  {index !== 0 && (
                    <Box
                      width="full"
                      height="1px"
                      background="#DFE3E8"
                      my="22px"
                    />
                  )}
                  <Box>
                    <Text
                      color="textSecondary"
                      fontSize="14px"
                      fontWeight="500"
                    >
                      {category.name}: {category.items?.length}
                    </Text>
                  </Box>
                  <CategoryBox
                    category={category}
                    addItem={handleSubmit}
                  />
                </Box>
              )
            })
          )}
        </Box>
      </Box>
      {showWarning && items?.length <= 2 && <Warning type={type} />}
    </Flex>
  )
}

const CategoryBox = ({ category, addItem }: { category: Category; addItem: any }) => {
  const [doShowMore, setDoShowMore] = useState<boolean>(false)
  return (
    <Flex
      width="full"
      direction="column"
      mt="21px"
    >
      {doShowMore
        ? category.items.map((item: Item, index: number) => {
            return (
              <CategoryElement
                item={item}
                key={`${item.id}-${item.name}-${item.category}_${index}}`}
                addItem={addItem}
              />
            )
          })
        : category.items?.slice(0, 5).map((item: Item, index: number) => {
            return (
              <CategoryElement
                item={item}
                key={`${item.id}-${item.name}-${item.category}_${index}`}
                addItem={addItem}
              />
            )
          })}
      {category.items?.length > 5 &&
        (doShowMore ? (
          <Text
            color="_accentPrimary"
            fontWeight="500"
            cursor="pointer"
            onClick={() => setDoShowMore(false)}
          >
            Show less
          </Text>
        ) : (
          <Text
            color="_accentPrimary"
            cursor="pointer"
            fontWeight="500"
            onClick={() => setDoShowMore(true)}
          >
            Show more: {category.items?.length - 5}
          </Text>
        ))}
    </Flex>
  )
}

const CategoryElement = ({ item, addItem }: { item: Item; addItem: any }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <Box
      display="flex"
      width="full"
      alignItems="center"
      py="10px"
      cursor="pointer"
      justifyItems="start"
      gap="14px"
      key={`${item.id}-${item.name}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={() => addItem(item.name)}
    >
      {item.icon && (
        <Image
          src={item.icon}
          width="12px"
          height="12px"
        />
      )}
      <Text
        color="textQuaternary"
        fontSize="14px"
        fontWeight="400"
      >
        {item.name}
      </Text>
      <Image
        src="/assets/icons/plus-circle.svg"
        width="16px"
        height="16px"
        display={isHovered ? 'block' : 'none'}
      />
    </Box>
  )
}

export const Warning: FC<{ type: Type }> = ({ type }) => {
  return (
    <Flex
      width="full"
      px="16px"
      py="12px"
      border="1px solid"
      borderColor="#FFB020"
      background="#FFFAF1"
      borderRadius="8px"
      flexDirection="row"
      alignItems="start"
      justifyContent="space-between"
      gap="16px"
      mt="24px"
    >
      <Image
        src="/assets/icons/warning-yellow.svg"
        mt="4px"
      />
      <Box
        p="0px"
        width="full"
      >
        <Text
          color="#996A13"
          fontWeight="500"
          fontSize="14px"
          width="full"
        >
          Select more {type === Type.INTERESTS ? 'interests' : 'roles'}
        </Text>
        <Text
          color="textSecondary"
          fontSize="14px"
          lineHeight="20px"
        >
          Select at least 3 {type === Type.INTERESTS ? 'interests' : 'roles'}, you can choose as many as you wish.
        </Text>
      </Box>
      <Image
        src="/assets/icons/close-dark-yellow.svg"
        mt="4px"
        cursor="pointer"
      />
    </Flex>
  )
}
