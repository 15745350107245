import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import EmojiPicker from 'emoji-picker-react'
import { useEffect, useMemo, useState } from 'react'
import useIsMobile from '../../../../../hooks/chakra/useIsMobile'
import { useFocus } from '../../../../../hooks/utils/useFocus'
import { socket, useChatProvider } from '../../../../../provider/Chat/chatProvider'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { getDegenPlatformJwtAuthKey } from '../../../../../utils/auth'
import { AddressZero } from '../../../../../utils/ethereum'
import { fetchApi } from '../../../../../utils/fetcher'
import { trimAddress } from '../../../../../utils/parser'
import { Avatar } from '../../../../shared/avatars/Avatar'
import { useLocation, useNavigate } from 'react-router'

interface IMentionUser {
  name: string
  avatarUrl: string
  address: string
}

function replaceLastWord(baseStr, replacementWord) {
  // Split the base string into words
  let words = baseStr.split(' ')

  // Check if the last word contains a special character and replace it accordingly
  let lastWord = words[words.length - 1]
  if (lastWord.startsWith('@')) {
    // If the last word starts with "@", append the replacement word without its first character
    words[words.length - 1] = '@' + replacementWord.slice(1)
  } else {
    // If no special character, replace the last word directly
    words[words.length - 1] = replacementWord
  }

  // Join the words back into a string
  return words.join(' ')
}

export const InputModule = ({ threadId }: { threadId: string | null }) => {
  const [newMessage, setNewMessage] = useState('')
  const [assets, setAssets] = useState<any[]>([])

  const chat = useChatProvider()
  const wallet = useEthereumProvider()
  const isMobile = useIsMobile('chat')
  const mentions = useDisclosure()
  const isMessageEmpty = !newMessage && assets?.length === 0
  const { state: intent } = useLocation()
  const navigate = useNavigate()

  const handleEmoji = (emoji: any) => {
    setNewMessage(prev => (prev || '') + emoji.emoji)
  }

  const onFileChange = event => {
    if (event) {
      const file = event.target.files[0]

      const reader = new FileReader()
      reader.onload = async function (evt: any) {
        const contents = evt.target.result

        const blob = await (await fetch(reader.result as any)).blob()
        const fileParsed = new File([blob], file.name)

        const formData = new FormData()
        formData.append('file', fileParsed)

        fetchApi('post/uploadAsset', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          setAssets([{ type: 'image', path: res.path, name: file.name }])
        })
      }
      reader.readAsDataURL(file)
    }
  }

  /** Handle mention parsing if `@${string}` appears */
  useEffect(() => {
    // if (newMessage.split(' ').pop()) {
    //   if (newMessage.split(' ').pop()![0] == '@') {
    //     if (newMessage[newMessage.length - 1] == " ") {
    //       mentions.onClose()
    //     } else
    //     mentions.onOpen()
    //   } else {
    //     mentions.onClose()
    //   }
    // }
    // if (newMessage == '') {
    //   mentions.onClose()
    // }
  }, [newMessage])

  /* Handle message-user intent */
  useEffect(() => {
    if (intent?.mentionUser) {
      setNewMessage(`@${intent?.mentionUser} `)
    }

    window.history.replaceState({}, '')
  }, [])

  const [foundUsers, setFoundUsers] = useState<IMentionUser[]>([])

  useEffect(() => {
    if (mentions.isOpen) {
      let nick = newMessage.split(' ').pop()?.replace('@', '')

      fetchApi(`profile/searchByName/${nick}`).then(res => {
        if (res.length > 0)
          setFoundUsers(
            res.map((item): IMentionUser => {
              return {
                address: item.address_[0],
                name: item.nickname,
                avatarUrl: item.avatar,
              }
            })
          )
      })
    }
  }, [mentions.isOpen, newMessage])

  const handleFetchAsset = async () => {
    await (window as any).document.getElementById('file_input').click()
  }

  const handleSubmit = () => {
    if (threadId == null || isMessageEmpty) {
      return
    }

    const key = getDegenPlatformJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)

    socket.emit('data', {
      content: newMessage,
      thread: threadId,
      jwt: secret,
      address: chat.getThreadById(chat.selectedThreadId!)?.users[0].mainAddress ?? AddressZero,
      assets: assets,
    })

    setAssets([])
    setNewMessage('')
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (mentions.isOpen && foundUsers.length > 0) {
        handleMentionClick(foundUsers[0].name)
      } else {
        handleSubmit()
      }
    }
  }

  const [inputRef, setInputFocus] = useFocus()

  const handleMentionClick = (username: string) => {
    let autocompletedMessage = replaceLastWord(newMessage, `@${username} `)
    setNewMessage(autocompletedMessage)
    setInputFocus()
    mentions.onClose()
  }

  const handleMessageChange = (e: any) => {
    setNewMessage(e.target.value)
  }

  const emojiPickerDom = useMemo(
    () => (
      // {/* TODO: This throws errors, replace this picker */}
      <EmojiPicker
        previewConfig={{ showPreview: false }}
        onEmojiClick={handleEmoji}
        lazyLoadEmojis
        searchDisabled
      />
    ),
    []
  )

  return (
    <Grid
      w="100%"
      bg={isMobile ? 'backgroundMain' : '_borderPrimary'}
      borderTop="1px solid #19170f"
      padding={isMobile ? '0px' : '16px'}
      pos="absolute"
      bottom="0"
    >
      {mentions.isOpen && (
        <Flex
          flexDir="column"
          w="100%"
          maxH="400px"
          overflowY="scroll"
          h="auto"
          bottom="75px"
          bg="#121212"
          pos="absolute"
        >
          <Flex
            flexDir="column"
            bg="#121212"
          >
            {foundUsers?.map(user => {
              return (
                <Flex
                  onClick={() => handleMentionClick(user.name)}
                  p="10px"
                  px="20px"
                  align="center"
                  gap="16px"
                  cursor="pointer"
                  _hover={{ opacity: '0.7' }}
                >
                  <Avatar
                    textSize={{ short: '14px', long: '10px' }}
                    name={user.name}
                    image={user.avatarUrl}
                    size="40px"
                    bgColor="blue"
                  />
                  <Flex
                    justify="center"
                    flexDir="column"
                  >
                    <Box>{user.name}</Box>
                    <Box
                      fontSize="12px"
                      fontWeight="normal"
                    >
                      {trimAddress(user.address)}
                    </Box>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
        </Flex>
      )}
      <Grid
        templateColumns={isMobile ? 'auto' : 'auto 40px'}
        gap="8px"
        padding={isMobile ? '2px' : '0px'}
      >
        <Grid
          bg="backgroundMain"
          w="100%"
          templateColumns="auto 1fr auto"
          borderRadius={isMobile ? '0px' : '8px'}
          border={isMobile ? '0px' : '1px solid'}
          borderColor="_borderPrimary"
        >
          <Flex mx="12px">
            <Image
              cursor="pointer"
              src="/assets/icons/paperclip.svg"
              onClick={handleFetchAsset}
            />

            <Input
              display="none"
              type="file"
              accept="image/*"
              id="file_input"
              onChange={onFileChange}
            />
          </Flex>
          <Input
            fontSize="14px"
            _placeholder={{ color: '_textSecondary' }}
            paddingX="0px"
            ref={inputRef}
            value={newMessage}
            onChange={handleMessageChange}
            placeholder="Napisz wiadomość…"
            onKeyDown={e => {
              handleKeyDown(e)
            }}
            border="none"
            boxShadow="none"
            outline="none"
            _focus={{ outline: 'none !important', boxShadow: 'none !important', border: 'none !important' }}
            autoFocus={!isMobile}
          />
          <Flex>
            <Menu
              closeOnSelect={false}
              flip={true}
            >
              <MenuButton>
                <Image
                  cursor="pointer"
                  mx="12px"
                  src="/assets/icons/mood-smile.svg"
                />
              </MenuButton>
              <MenuList
                border="none"
                padding="0px"
                margin="none"
                bg="none"
              >
                <Flex
                  bg="none"
                  padding="0px"
                  _hover={{ bg: 'none' }}
                  border="none !important"
                >
                  {emojiPickerDom}
                </Flex>
              </MenuList>
            </Menu>
          </Flex>
        </Grid>
        {!isMobile && (
          <Tooltip label={isMessageEmpty ? 'Your message is empty' : null}>
            <Button
              onClick={handleSubmit}
              _hover={{ boxShadow: 'none', opacity: '0.8' }}
              boxSize="40px"
              padding="0px"
              bg="_accentPrimary"
              cursor={isMessageEmpty ? 'not-allowed' : 'pointer'}
            >
              <Image src="/assets/icons/send.svg" />
            </Button>
          </Tooltip>
        )}
      </Grid>
      {assets.length > 0 && (
        <Flex
          flexDir="column"
          gap="8px"
          borderTop={isMobile ? '1px' : '0px'}
          borderColor="borderPrimary"
          mt={isMobile ? '4px' : '20px'}
          padding={isMobile ? '4px' : '0px'}
          maxH="100px"
          overflowX="scroll"
        >
          {assets.map(asset => {
            return (
              <Grid
                h="32px"
                templateColumns="32px 1fr auto"
                alignItems="center"
                gap="12px"
              >
                <Flex
                  borderRadius="4px"
                  bg="backgroundMain"
                  boxSize="32px"
                  border="1px solid"
                  align="center"
                  justify="center"
                  borderColor="borderPrimary"
                >
                  <Image src="/assets/icons/file.svg" />
                </Flex>
                <Flex
                  fontSize="12px"
                  color="textQuaternary"
                  overflow="hidden"
                >
                  {asset.name.slice(0, 10)} ... {asset.name.slice(-10)}
                </Flex>
                <Image
                  w="20px"
                  onClick={() => {
                    setAssets(assets => assets.filter(newAsset => newAsset != asset))
                  }}
                  cursor="pointer"
                  mr="6px"
                  src="/assets/icons/trash.svg"
                />
              </Grid>
            )
          })}
        </Flex>
      )}
    </Grid>
  )
}
