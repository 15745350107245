import { Box, Button, Flex, Grid, Image, Menu, MenuButton, MenuItem, MenuList, Spinner } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { GENERAL_THREAD_ID, useChatProvider } from '../../../../provider/Chat/chatProvider'
import { Avatar } from '../../../shared/avatars/Avatar'
import { InputModule } from './InputModule/inputModule'
import { trimAddress } from '../../../../utils/parser'
import { useNavigate } from 'react-router'
import { emptyThread, Thread } from '../../../../provider/Chat/types'
import { CopyAddress } from './CopyAddress/copyAddress'
import { MessagesList } from './MessagesList/messagesList'
import { ArrowLeft } from '../../../icons/ArrowLeft'
import moment from 'moment'
import { ChatEndIndicator } from './MessagesList/ChatEndIndicator'

export const ChatWindow = () => {
  const chat = useChatProvider()
  const navigate = useNavigate()

  const thread: Thread | null = useMemo(() => {
    return chat.selectedThreadId ? chat.getThreadById(chat.selectedThreadId) : null
  }, [chat.selectedThreadId])

  const isGeneral = thread?.id === GENERAL_THREAD_ID || chat.isLoading

  const handleBackButton = () => {
    navigate(`/chat`)
  }

  const threadImage = () => (isGeneral ? 'assets/icons/degen-filter-icon.svg' : thread?.users[0].avatarUrl ?? undefined)

  return (
    <Grid
      templateRows="auto 1fr"
      border={{
        base: 'none',
        md: '1px solid',
      }}
      borderColor={{
        base: 'none',
        md: '_borderPrimary',
      }}
      sx={{
        h: {
          base: 'calc(100dvh - 50px - 54px)',
          sm: 'calc(100dvh - 40px - 50px - 54px)',
          md: 'calc(100dvh - 62px - 32px - 24px - 50px)',
          lg: 'calc(100dvh - 62px - 32px - 24px)',
        },
        scrollSnapAlign: 'center',
      }}
      pos="relative"
      borderRadius="16px"
      bg="backgroundMain"
      overflow="hidden"
      w={{
        base: '100%',
        md: '66%',
      }}
    >
      <Flex
        align="center"
        justify="space-between"
        px="16px"
        h="56px"
        borderBottom="1px solid"
        borderColor="_borderPrimary"
        sx={{
          position: 'relative',
          ...(chat.isOffline ? { mb: '23px' } : {}),
        }}
      >
        <Flex
          align="center"
          gap="8px"
        >
          {!isGeneral && !!thread?.id && (
            <Button
              variant="slave"
              sx={{
                display: 'flex',
              }}
              boxSize="30px"
              alignItems="center"
              justifyContent="center"
              onClick={handleBackButton}
              cursor="pointer"
              padding="7px"
              borderRadius="50%"
            >
              <ArrowLeft />
            </Button>
          )}
          <Grid
            templateColumns="40px auto"
            gap="12px"
            alignItems="center"
          >
            {thread ? (
              <Avatar
                bgColor="_foregroundPrimary"
                size="40px"
                image={threadImage()}
                name={thread ? thread.users[0].name ?? thread.users[0].mainAddress : 'X'}
                textSize={{ short: '14px', long: '14px' }}
                sx={isGeneral ? { backgroundSize: '24px' } : {}}
              />
            ) : (
              <Flex
                align="center"
                boxSize="40px"
                justify="center"
              >
                <Spinner />
              </Flex>
            )}
            <Flex
              flexDir="column"
              gap="4px"
            >
              <Box
                lineHeight="100%"
                fontSize="12px"
                color="textQuaternary"
                fontWeight="500"
              >
                {thread
                  ? isGeneral
                    ? 'Wszystkie Tokeny'
                    : thread.users[0].nickname || trimAddress(thread.users[0].mainAddress)
                  : 'Loading'}
              </Box>
              {!!thread?.messages.length && (
                <Box
                  lineHeight="100%"
                  fontSize="12px"
                  color="#6F6A4F"
                  fontWeight="400"
                >
                  {/* time of the last message sent; TODO: set this to last group query date (last search -> open chat action.). */}
                  {/* TODO: Translate moment.js (time ago… string) */}
                  Aktywny {!!thread?.messages.length && moment(thread.messages?.at(-1)?.date).fromNow()}
                </Box>
              )}
            </Flex>
          </Grid>
        </Flex>
        <Flex
          align="center"
          gap="2px"
        >
          {thread?.id && <CopyAddress address={thread.id} />}
        </Flex>
        {chat.isOffline && (
          <Flex
            sx={{
              bg: '_failPrimary',
              position: 'absolute',
              w: '100%',
              justifyContent: 'center',
              bottom: '0',
              left: '0',
              transform: 'translateY(100%)',
            }}
          >
            Jesteś offline
          </Flex>
        )}
      </Flex>
      <MessagesList />
      <Flex
        sx={{
          position: 'absolute',
          bottom: { base: '64px', md: '112px' },
          left: '50%',
          transform: 'translateX(-50%)',
          pointerEvents: chat.isAtBottom ? 'none' : 'auto',
        }}
      >
        <ChatEndIndicator />
      </Flex>
      <InputModule threadId={chat.selectedThreadId} />
    </Grid>
  )
}
