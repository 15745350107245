import { Flex, Select, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

interface ISelectSectionProps {
  text: string
  subheading?: string
  placeholder: string
}

export const SelectSection: FC<ISelectSectionProps> = ({ text, subheading, placeholder }) => {
  return (
    <Flex
      flexDir="column"
      gap="8px"
    >
      <Text>{text}</Text>
      {subheading && (
        <Text
          fontWeight="400"
          color="textSecondary"
        >
          {subheading}
        </Text>
      )}
      <Select
        placeholder={placeholder}
        _placeholder={{
          color: '#6F6A4F',
          fontSize: '14px',
        }}
        _focus={{
          boxShadow: 'none',
          border: '1px solid',
          borderColor: '_borderPrimary !important',
          outline: 'none !important',
        }}
      />
    </Flex>
  )
}
