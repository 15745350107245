import { Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

export default function MaxWidthWrapper({
  children,
  disabledPages,
  maxW,
}: {
  children: React.ReactNode
  disabledPages?: string[]
  maxW: string
}) {
  const location = useLocation()
  const isDisabled = disabledPages?.includes(location.pathname)

  const isPaddingDisabled = ['/profile/*']
  const isPagePreviewn = location.pathname.startsWith('/dao')

  if (isDisabled) {
    return <>{children}</>
  } else {
    return (
      <Flex
        maxW={maxW}
        mx="auto"
        w="100%"
        /* TODO: Fix this up so that borders overflow on the X axis instead of being in the relative layout – so as this `0` would become `12px` or so. */
        px={{ base: isPaddingDisabled.includes(location.pathname) ? '16px' : '0', md: '16px' }}
        {...(isPagePreviewn ? { bgImage: "url('/assets/backgrounds/preview.svg')" } : {})}
      >
        {children}
      </Flex>
    )
  }
}
