import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { CheckCircleIcon, CopyIcon } from '@chakra-ui/icons'
import { trimAddress } from '../../../../../utils/parser'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import { useCopy } from '../../../../../hooks/utils/useCopy'

export const CopyAddress = ({ address }: { address?: string }) => {
  const copy = useCopy()

  return (
    <Flex
      border="1px solid"
      display={{ base: 'none', md: 'flex' }}
      gap="12px"
      align="center"
      borderColor="borderPrimary"
      borderRadius="4px"
      padding="1px 6px"
      fontSize="12px"
      color="textSecondary"
    >
      <Box>{!address ? <SpinnerText /> : trimAddress(address)}</Box>
      {copy.isCopied ? (
        <CheckCircleIcon />
      ) : (
        <CopyIcon
          cursor="pointer"
          onClick={() => {
            if (!address) {
              return
            }

            copy.handleCopy(address)
          }}
        />
      )}
    </Flex>
  )
}
