import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

import { WagmiConfig } from 'wagmi'
import { bsc, mainnet } from 'viem/chains'
import { getColor } from '../theme/global'

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!

const metadata = {
  name: 'Degen.pl',
  description: 'Degen platform',
  url: 'https://test.degen.pl',
  icons: ['/favicon.ico'],
}

const chains = [bsc, mainnet]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  enableAnalytics: false,
  themeMode: 'light',
  themeVariables: {
    /* @ts-ignore */
    '--w3m-background-color': '#101840',
    '--w3m-accent-color': '#101840',
    /* @ts-ignore */
    '--w3m-color-bg-1': '#000000',
    /* @ts-ignore */
    '--w3m-color-bg-2': '#000000',
    /* @ts-ignore */
    '--w3m-color-bg-3': '#000000',
    /* @ts-ignore */
    '--w3m-color-fg-1': '#101828',
    /* @ts-ignore */
    '--w3m-color-fg-2': '#6F6A4F',
    /* @ts-ignore */
    '--w3m-color-fg-3': '#101828',
    /* @ts-ignore */
    '--w3m-z-index': 1400 /* ChakraUI Modal */,
    '--w3m-overlay-backdrop-filter': 'blur(10px)',
    /* @ts-ignore */
    '--w3m-overlay-background-color': '#0000000f',
    '--w3m-background-border-radius': '16px',

    '--w3m-border-radius-master': '16px',
    '--w3m-accent': getColor('_foregroundSecondary'),
    '--w3m-font-family': 'Nippo-Variable',
    '--w3m-color-mix': getColor('_foregroundSecondary'),
    '--w3m-color-mix-strength': 25
  },
})

export function Web3Modal({ children }: { children: React.ReactNode }) {
  return <WagmiConfig config={wagmiConfig as any}>{children}</WagmiConfig>
}
