import React, { useEffect } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { SearchModal } from '../../components/global/search-modal'

export interface ISearch {
  openModal: () => void
  closeModal: () => void
  toggleModal: () => void
}

export const emptySearch: ISearch = {
  openModal: () => {},
  closeModal: () => {},
  toggleModal: () => {},
}

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const modalController = useDisclosure()

  const handleOpenModal = modalController.onOpen
  const handleCloseModal = modalController.onClose
  const handleToggleModal = modalController.onToggle

  return (
    <SearchContext.Provider
      value={{
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
        toggleModal: handleToggleModal,
      }}
    >
      <SearchModal controller={modalController} />
      {children}
    </SearchContext.Provider>
  )
}

const SearchContext = React.createContext(emptySearch)

export const useSearchProvider = () => {
  const context = React.useContext(SearchContext)

  if (!context) {
    throw new Error('`useSearchProvider` cannot be used outside of a `SearchProvider`!')
  }
  return context
}
