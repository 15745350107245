import { Box, Flex, Image, Text } from '@chakra-ui/react'
import Marquee from 'react-fast-marquee'
import useAsyncEffect from '../../../hooks/effects/async'
import { loaded } from '../../../utils/process'
import { useState } from 'react'
import { fetchApi } from '../../../utils/fetcher'
import { useOnboardingProvider } from '../../../provider/Onboarding/onboardingProvider'
import { AdvertTrigger } from './AdvertTrigger'
import useIsMobile from '../../../hooks/chakra/useIsMobile'
import { getColor } from '../../../theme/global'

type Coin = {
  name: string
  price: number
  iconUrl: string
  change: string
}

export default function CryptoTopbar() {
  const [data, setData] = useState([
    {
      name: '',
      price: 0,
      iconUrl: '',
      change: '',
    },
  ])

  const onboarding = useOnboardingProvider()
  const isMobile = useIsMobile()

  useAsyncEffect(async () => {
    await loaded(async () => {
      const data = await fetchApi(`tokens-prices`)

      if (data.tokenPrices) {
        setData(data.tokenPrices)
      }
    })
  }, [])

  return (
    <Flex
      h="32px"
      bg="backgroundMain"
      maxWidth="100vw"
      borderBottom="1px solid borderPrimary"
      sx={{
        zIndex: { base: 0, sm: onboarding.shouldElevateTopBar ? 'popover' : 'sticky' },
      }}
    >
      <Marquee
        speed={20}
        style={{ background: getColor('_backgroundPrimary') }}
      >
        {data.map((item: Coin, index) => [
          index % (isMobile ? 2 : 6) === 0 && <AdvertTrigger />,
          <Flex
            alignItems="center"
            gap="0.25rem"
            padding="0 8px"
            key={index}
            _after={{
              content: '""',
              width: '1px',
              height: '12px',
              bg: 'borderPrimary',
              transform: 'translateX(6px)',
            }}
          >
            <Image
              src={item.iconUrl}
              alt={item.name}
              h="14px"
            />
            <Text
              fontSize="12px"
              color="textSecondary"
            >
              {item.name}
            </Text>
            <Text fontSize="12px">{Number(item.price).toFixed(2)}$</Text>
            <Text
              color={item.change.includes('-') ? 'redPrimary' : 'greenPrimary'}
              fontSize="12px"
              fontWeight="600"
            >
              {item.change}%
            </Text>
          </Flex>,
        ])}
      </Marquee>
    </Flex>
  )
}
