import { Box, Flex, Image, Link } from '@chakra-ui/react'
import { BackToTop } from './BackToTop/backToTop'
import { FooterLinks } from './FooterLinks/footerLinks'
import { useLocation } from 'react-router'
import moment from 'moment'
import SpinnerText from '../../shared/tags/SpinnerText'
import { useEffect } from 'react'
import { _log } from '../../../logger'
import { isDev } from '../../../utils/config'

export const Footer = () => {
  const isChatPage = useLocation().pathname.startsWith('/chat')
  const commitId = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA

  return (
    <Flex
      display={isChatPage ? 'none' : 'flex'}
      justifyContent="center"
      marginTop="auto"
    >
      <Flex
        flexWrap="wrap"
        bg="backgroundSecondary"
        pt="20px"
        sx={{
          a: {
            color: '_textSecondary',
          },
        }}
        maxW="1180px"
        w="100%"
        px="16px"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          w="100%"
          padding="24px 0px"
        >
          <Box opacity=".5"></Box>
          <BackToTop />
        </Flex>
        <Flex
          flexWrap="wrap"
          h="1px"
          bg="#F2F4F7"
        />
        <Flex
          flexWrap="wrap"
          padding="24px 0px"
          gap="70px"
        >
          <Flex
            flexWrap="wrap"
            flexDir="column"
            gap="16px"
            justify="space-between"
          >
            <Flex
              flexWrap="wrap"
              flexDir="column"
              gap="16px"
            >
              <Flex
                flexWrap="wrap"
                color="textTertiary"
                align="center"
                fontSize="12px"
                gap="8px"
              >
                <Image
                  src="/logo.svg"
                  boxSize="24px"
                />
              </Flex>
              <Box
                color="textSecondary"
                fontSize="14px"
                maxW="400px"
              >
                <i>Degen</i> to zdecentralizowany protokół tworzony dla{' '}
                <Link
                  href="https://www.miejski.pl/slowo-degen#:~:text=Cz%C5%82owiek%20zdegenerowany"
                  target="_blank"
                  sx={{ fontStyle: 'italic', color: '_accentPrimary !important' }}
                >
                  zdegenerowanych
                </Link>{' '}
                ludzi. Dowiedz się więcej na&nbsp;
                <Link
                  href="https://docs.degen.pl"
                  sx={{ color: '_accentPrimary !important' }}
                >
                  docs.degen.pl
                </Link>
                .
              </Box>
            </Flex>

            <Box
              color="textSecondary"
              fontSize="12px"
              position="relative"
            >
              Degen © {moment().format('YYYY').toString()}
              {!isDev() && (
                <Link
                  href={`https://github.com/hashup-it/degen/commit/${commitId}`}
                  target="_blank"
                  sx={{
                    fontSize: '8px',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    transform: 'translateY(-100%)',
                    color: '_accentPrimary !important',
                    opacity: '.5',
                  }}
                >
                  Build {commitId?.slice(0, 7)}
                </Link>
              )}
            </Box>
          </Flex>
          <Flex
            gap={{ base: '8px', sm: '80px' }}
            rowGap="24px"
            flexWrap="wrap"
            justifyContent={{ base: 'space-between', sm: 'unset' }}
            w={{ base: '100%', sm: 'unset' }}
          >
            <FooterLinks />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
